.wrapper-calendarEvents {
    position: relative;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
}

.wrapper-calendarEvents::before{
    position: absolute;
    top:0;
    left:0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("../../assets/homepage/hmmm.jpg");
  
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
  }
  


.wrapper-calendarEvents .container-calendarEvents-header{
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 3vmax;

}

.wrapper-calendarEvents .container-calendarEvents-header .calendarEvents-header-line {
    outline: 0;
    border: 0;
    color: black;
    height: 0.1vmin;
    min-height: 0.08em;
    opacity: .8;
    background: linear-gradient(to right, transparent, rgba(109, 109, 109, 0.719), transparent);
    margin: 0;

}

.wrapper-calendarEvents .container-calendarEvents-header .calendarEvents-header-text {
    text-align: justify;
    font-family: "Montserrat", Arial, sans-serif;
    background-color: #efe9cc4d;
    padding-top: 1vmax;


}

.wrapper-calendarEvents .calendarEvents-header-text>span {
    font-size: 2.5vmax;
    font-weight: bold;
    display: block;
    padding-left: 5%;
    color: rgb(36, 35, 35);
    text-transform: uppercase;
    padding-left: 8%;

}



.wrapper-calendarEvents .container-calendarEvents-tabs-header {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: #efe9cc46;

}



.wrapper-calendarEvents .container-calendarEvents-tabs-body  {
    position: relative;
    width: 90%;
    margin: 0 auto;
}

