.wrapper-sidenav{
    position: fixed;
    height: 100vh;
    background-color: black;
    opacity: 0.85;
    color: white;
    z-index: 1100;
    display: var(--sideNav-display);
    left: -100%;
    top:0;
    animation: slide-open 0.2s forwards;
}

@keyframes slide-open{
    100% { left: 0}
}

.wrapper-sidenav .sidenav-button{
    color:white;
    background-color: transparent;

    outline: none;
    border: none;
    padding:0.5vmax;
    cursor:pointer;
}

.wrapper-sidenav .sidenav-button .menu-icon{
    background-image: url("../../../assets/figma/menu-white.svg");
    background-size: 1.6vmax;
    background-position: center;
    background-repeat: no-repeat;
  
    height: 1.6vmax;
    width: 1.6vmax;
  }
  
  


.wrapper-sidenav .menu-container{
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 1.5vmax;


}

.wrapper-sidenav .menu-container .menu-option{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-items: center;

    width: 13vmax;
    height: 2.5vmax;

    color:white;
    text-decoration: none;
    padding: 0.5vmax 1vmax;
}

.wrapper-sidenav .menu-container .menu-option > *{
    position: relative;
    width: 12%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 1vmax;
    background-position: center;
}

.wrapper-sidenav .menu-container .menu-option .home-icon{
    background-image: url("../../../assets/figma/sidenav/home.svg");
    background-size: 1.2vmax;

}

.wrapper-sidenav .menu-container .menu-option .calendar-icon{
    background-image: url("../../../assets/figma/sidenav/calendar.svg");
}

.wrapper-sidenav .menu-container .menu-option .flag-icon{
    background-image: url("../../../assets/figma/sidenav/flag.svg");
}

.wrapper-sidenav .menu-container .menu-option .explore-icon{
    background-image: url("../../../assets/figma/sidenav/explore.svg");
    background-size: 1.1vmax;

}

.wrapper-sidenav .menu-container .menu-option .info-icon{
    background-image: url("../../../assets/figma/sidenav/info.svg");
    background-size: 1.1vmax;

}

.wrapper-sidenav .menu-container .menu-option .consortium-icon{
    background-image: url("../../../assets/figma/sidenav/consortium.svg");
    background-size: 1.1vmax;

}

.wrapper-sidenav .menu-container .menu-option .document-icon{
    background-image: url("../../../assets/figma/sidenav/document.svg");
    background-size: 0.9vmax;

}

.wrapper-sidenav .menu-container .menu-option .lightbulb-icon{
    background-image: url("../../../assets/figma/sidenav/lightbulb.svg");
}

.wrapper-sidenav .menu-container .menu-option .links-icon{
    background-image: url("../../../assets/figma/sidenav/links.svg");
    background-size: 1.1vmax;
}

.wrapper-sidenav .menu-container .menu-option .admin-icon{
    background-image: url("../../../assets/figma/sidenav/admin.svg");
    background-size: 1.2vmax;
}

.wrapper-sidenav .menu-container .menu-option .game-icon{
    background-image: url("../../../assets/figma/sidenav/game.svg");
    background-size: 1.2vmax;
}

.wrapper-sidenav .menu-container .menu-option .videos-icon{
    background-image: url("../../../assets/figma/sidenav/video.svg");
    background-size: 1.2vmax;
}

.wrapper-sidenav .menu-container .menu-option .models-icon{
    background-image: url("../../../assets/figma/sidenav/models.svg");
    background-size: 1.2vmax;
}



.wrapper-sidenav .menu-container .menu-option .text{
    position: relative;
    margin-left:5%;
    width: 83%;
    font-size: 0.8vmax;
}

.wrapper-sidenav .menu-container .menu-option:hover{
    background-color: rgba(250, 235, 215, 0.15);
}


.wrapper-sidenav .sub-menu-container{
    border-top: 1px solid white;
}







