
.wrapper-gamepage{
    width: 100%;
    perspective: 1000px;
    background-image: url("../../assets/images/map3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.wrapper-gamepage .container-menu{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    min-height: 100vh;

}


.wrapper-gamepage .container-menu .menu-option{
    position: relative;
    width: calc(100%/3);

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}

.wrapper-gamepage .container-menu .menu-option:first-child{
}
.wrapper-gamepage .container-menu .menu-option:last-child{
}

.wrapper-gamepage .container-menu .menu-option:nth-child(2){
}



.wrapper-gamepage .container-menu .menu-option .card{
    position: relative;
    width: 60%;
    height: 25vmax;
    background-color: #232323c5;
    border-radius: 20px;
    transform-style: preserve-3d;
    cursor: pointer;

    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
}


/*
.wrapper-gamepage .container-menu .menu-option:first-child .card::before{
    content: var(--gamepage-search-lang);

}

.wrapper-gamepage .container-menu .menu-option:last-child .card::before{
    content: var(--gamepage-map-lang);
}

.wrapper-gamepage .container-menu .menu-option:first-child .card::before,
.wrapper-gamepage .container-menu .menu-option:last-child .card::before{
    position: absolute;
    color:white;
    font-size: 5vmax;
    left: 50%;
    transform: translate(-50%, 0);
    top: 10%;
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: 0.4s;

}
*/

.wrapper-gamepage .container-menu .menu-option .card .title{
    position: absolute;
    color:white;
    font-size: 5vmax;
    left: 50%;
    transform: translate(-50%, 0);
    top: 10%;
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: 0.4s;
    font-size: 3vmax;
    width: 100%;
    text-align: center;

}


.wrapper-gamepage .container-menu .menu-option .card .game-search,
.wrapper-gamepage .container-menu .menu-option .card .game-map,
.wrapper-gamepage .container-menu .menu-option .card .game-play{
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0,0);
    transition: 0.5s;
    overflow: hidden;
    width: 12vmax;
    height: 12vmax;
    top:35%;
}

.wrapper-gamepage .container-menu .menu-option .card .game-search img{
    position: absolute;

    width: 70%;
    height: 70%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.wrapper-gamepage .container-menu .menu-option .card .game-map img{
    position: absolute;

    width: 70%;
    height: 70%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

}

.wrapper-gamepage .container-menu .menu-option .card .game-play img{
    position: absolute;


    width: 70%;
    height: 70%;
    top:50%;
    left:50%;

    transform: translate(-50%,-50%);
}


.wrapper-gamepage .container-menu .menu-option:first-child:hover .card .game-search{
    transform: translate3d(-55%, 0,120px);
    transition: 0.5s;
}


.wrapper-gamepage .container-menu .menu-option:last-child:hover .card .game-map{
    transform: translate3d(-45%, 0,120px);
    transition: 0.5s;
}

.wrapper-gamepage .container-menu .menu-option:nth-child(2):hover .card .game-play{
    transform: translate3d(-50%, 0,80px);
    transition: 0.5s;
}


.wrapper-gamepage .container-list{
    position: relative;
    width: 100%;
    min-height: 100vh;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
    
}




.wrapper-gamepage .container-list .backToLife{
    position: relative;
    width: 100%;
    margin: 4vmax auto 1vmax auto;

}



.wrapper-gamepage .container-list .backToLife .return{
    height: 2.5vmax;
    width: 15%;
    padding: 0;
    outline: none;
    border:none;
    margin-left:70%;
    border-radius: 1px;
    
    -webkit-box-shadow: rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    background-color: rgba(255, 255, 255, 0.863);

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}
.wrapper-gamepage .container-list .backToLife .return:hover{
    transform: scale(1.01);

}

.wrapper-gamepage .container-list .backToLife .return .icon{
    position: relative;
    background-image: url("../../assets/figma/game/return.svg");
    background-size: 1.2vmax;
    background-position: center;
    background-repeat: no-repeat;
    width: 10%;
    height: 100%;



}

.wrapper-gamepage .container-list .backToLife .return .text{
    position: relative;
    width: 30%;
    height: 100%;
}
.wrapper-gamepage .container-list .backToLife .return .text span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    font-size: 1vmax;
    text-transform: uppercase;

}








.wrapper-gamepage .container-list .list-mines{
    position: relative;
    width: 70%;
    margin: 1vmax auto 5vmax auto;


    -webkit-box-shadow: rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    background-color: rgba(255, 255, 255, 0.712);
    padding: 1vmax;;

    user-select: none;
}

.wrapper-gamepage .container-list .list-mines .row-mines{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    /*
    border-left: 5px solid rgba(184, 184, 184, 0.205);
    border-right: 5px solid rgba(184, 184, 184, 0.205);
    */
    font-size: 1vmax;
    border-top: 2.5px solid rgba(49, 49, 49, 0.205);
    padding: 1vmax 0 ;
    cursor: pointer;
}


.wrapper-gamepage .container-list .list-mines .row-mines:not(:first-child):hover{
    background-color: rgba(236, 238, 150, 0.178);

}



.wrapper-gamepage .container-list .list-mines .row-mines:last-child{
    border-bottom: 2.5px solid rgba(49, 49, 49, 0.205);
}


.wrapper-gamepage .container-list .list-mines .row-mines .row-mines-name{
    position: relative;
    width: 60%;
    text-align: left;
    padding: 0 2%;

}

.wrapper-gamepage .container-list .list-mines .row-mines .row-mines-country{
    position: relative;
    width: 20%;
    text-align: center;
    padding: 0 2%;
}

.wrapper-gamepage .container-list .list-mines .row-mines .row-mines-quizz{
    position: relative;
    width: 20%;
    text-align: center;
    padding: 0 2%;
}

.wrapper-gamepage .container-list .list-mines .row-mines .mines-quizz-true{
    background-image: url("../../assets/figma/game/quizzT.svg");
    background-size: 1.4vmax;
    background-position: center;
    background-repeat: no-repeat;
}

.wrapper-gamepage .container-list .list-mines .row-mines .mines-quizz-false{
    background-image: url("../../assets/figma/game/quizzF.svg");
    background-size: 1.4vmax;
    background-position: center;
    background-repeat: no-repeat;
}


.wrapper-gamepage .container-list .list-mines .row-mines-header{
    padding-bottom: 1vmax;;
    border: none;
    font-size: 1.2vmax;
    text-transform: uppercase;
    font-weight: bold;
    transform: none !important;
    cursor:default;

}


.wrapper-gamepage .container-list .container-list-footer{
    position: relative;
    width:70%;
    margin-bottom: 3vmax;
    min-height: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    

    background-color: rgba(195, 197, 168, 0.74);
}

.wrapper-gamepage .container-list .container-list-footer .container-list-footer-info{
    width: 50%;
    height: 100%;
}

.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options {
    width: 50%;
    height: 100%;
    user-select: none;

}


.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options > * {
    font-size: 1.4vmax;
    cursor: pointer;
    display: inline-block;

}

.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options .go-left{
    margin-left: 45%;
    margin-right: 0.5%;

}

.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options .go-right{
    margin-left: 0.5%;

}

.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options .go-pages {
    margin: 1.5vmax 0.5% 0 0.5%;
    font-size: 1vmax;

}

.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options .go-pages:hover{
    text-decoration: underline;
    color: blue;
    
}


.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options .selected-go-pages{
    font-weight: bold;
    color: black;
    text-decoration: underline;

}
.wrapper-gamepage .container-list .container-list-footer .container-list-footer-options .selected-go-pages:hover{
    text-decoration: underline;
    color: black;

}
