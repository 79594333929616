.wrapper-aux-country{
    position: relative;
    width: 100%;
}


.wrapper-aux-country .container-auxCountry{
    position: relative;
    width: 100%;
    margin-bottom: 2vmax;

}

.wrapper-aux-country .container-auxCountry .auxCountry-menu{
    position: relative;
    width: 100%;
    box-shadow: 0 0 1.2px #e4ce8e94;
    border: 0.5px solid rgba(0, 0, 0, 0.123);
    background-color: #e0be5736;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}



.wrapper-aux-country .container-auxCountry .auxCountry-menu .auxCountry-menu-title{
    position: relative;
    width: 80%;
    font-size: 2vmax;
    padding-left: 1vmax;
}

.wrapper-aux-country .container-auxCountry .auxCountry-menu .auxCountry-menu-options{
    position: relative;
    width: 20%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}


.wrapper-aux-country .container-auxCountry .auxCountry-menu .auxCountry-menu-options .auxCountry-opt-add{
    position: relative;
    width: 1000%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    font-size: 1vmax;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.wrapper-aux-country .container-auxCountry .auxCountry-menu .auxCountry-menu-options .auxCountry-opt-add-icon{
    background-image: url("../../../../assets/figma/add.svg");
    position: relative;
    background-size: 1.2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:1.2vmax;
    width:100%;
}

.wrapper-aux-country .container-auxCountry .auxCountry-menu .auxCountry-menu-options span{
    display: block;
    width:100%;
    font-size: 1vmax;
    ;
}


.wrapper-aux-country .container-auxCountry .auxCountry-list{
    position: relative;
    width: 100%;
    margin-top: 1vmax;;
}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    min-height: 2vmax;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    box-shadow: 0 0 1.2px #e4ce8e94;
    border: 0.5px solid rgba(0, 0, 0, 0.123);
}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-id{
    position: relative;
    width: 5%;
    background-color: rgba(211, 211, 228, 0.473);
    font-size: 1vmax;

}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-id span{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-title{
    position: relative;
    width: 75%;
    font-size: 1vmax;
    word-wrap: break-word;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    min-height: 2vmax;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    align-items: center;

    padding:0.5vmax;

}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-title span{
    position: relative;
    display: block;


}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    
    padding:0.5vmax;

}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options .auxCountry-obj-edit{
    position: relative;
    width: calc(100%/3);
    cursor: pointer;
}


.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options .auxCountry-obj-edit .auxCountry-obj-edit-icon{
    background-image: url("../../../../assets/figma/edit.svg");
    position: relative;
    background-size: 1.6vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}


.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options .auxCountry-obj-rmv{
    position: relative;
    width: calc(100%/3);
    cursor: pointer;


}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options .auxCountry-obj-rmv .auxCountry-obj-rmv-icon{
    background-image: url("../../../../assets/figma/remove.svg");
    position: relative;
    background-size: 1.6vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}



.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options .auxCountry-obj-show{
    position: relative;
    width: calc(100%/3);
    cursor: pointer;


}

.wrapper-aux-country .container-auxCountry .auxCountry-list .auxCountry-list-obj .auxCountry-options .auxCountry-obj-show .auxCountry-obj-show-icon{
    background-image: url("../../../../assets/figma/visibility.svg");
    position: relative;
    background-size: 1.6vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}



.wrapper-aux-country .container-auxCountry-footer{
    position: relative;
    width:100%;
    margin-top: 1vmax;
    min-height: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;


    background-color: rgba(107, 109, 81, 0.192);
}

.wrapper-aux-country .container-auxCountry-footer .footer-info{
    width: 60%;
    height: 100%;
}

.wrapper-aux-country .container-auxCountry-footer .footer-pagination {
    width: 40%;
    height: 100%;
    user-select: none;

}


.wrapper-aux-country .container-auxCountry-footer .footer-pagination > * {
    font-size: 1.4vmax;
    cursor: pointer;
    display: inline-block;

}

.wrapper-aux-country .container-auxCountry-footer .footer-pagination .go-left{
    margin-left: 45%;
    margin-right: 0.5%;

}

.wrapper-aux-country .container-auxCountry-footer .footer-pagination .go-right{
    margin-left: 0.5%;

}

.wrapper-aux-country .container-auxCountry-footer .footer-pagination .go-pages {
    margin: 1.5vmax 0.5% 0 0.5%;
    font-size: 1vmax;

}

.wrapper-aux-country .container-auxCountry-footer .footer-pagination .go-pages:hover{
    text-decoration: underline;
    color: blue;
    
}


.wrapper-aux-country .container-auxCountry-footer .footer-pagination .selected-go-pages{
    font-weight: bold;
    color: black;
    text-decoration: underline;

}
.wrapper-aux-country .container-auxCountry-footer .footer-pagination .selected-go-pages:hover{
    text-decoration: underline;
    color: black;
}