.wrapper-add-consortium{
    position: relative;
    width: 100%;
}


.wrapper-add-consortium .container-add-consortium-main{
    position: relative;
    width: 100%;
    height: 100%;
}




.wrapper-add-consortium .container-add-consortium-main .container-title,
.wrapper-add-consortium .container-add-consortium-content .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-consortium .container-add-consortium-main .container-title span,
.wrapper-add-consortium .container-add-consortium-content .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-add-consortium .container-add-consortium-main .main-row{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}




.wrapper-add-consortium .container-add-consortium-main .input-title,
.wrapper-add-consortium .container-add-consortium-main .input-website{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-add-consortium .container-add-consortium-main .input-title .input-title-text,
.wrapper-add-consortium .container-add-consortium-main .input-website .input-website-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    font-size: 0.9vmax;;
    height: 1.8vmax;
}




.wrapper-add-consortium .container-add-consortium-main .input-title  .label,
.wrapper-add-consortium .container-add-consortium-main .input-website .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}


.wrapper-add-consortium .container-add-consortium-content .add-consortium-image{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

    margin-bottom: 2vmax;;;;;
    margin-top: 1vmax;;;;;

}


.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview {
    position: relative;
    width: 32%;
    height: 16vmax;
}
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image {
    width: 100%;
    height: 100%;
}
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options{
    position: absolute;
    right:0;
    top: 0;
    height: 100%;
    padding:0.5vmax;
}


.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-change-fab,
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-delete-fab {
    position: relative;
    display: block;
    border-radius: 50%;
    outline: none;
    user-select: none;
    border: none;
    padding: 0;
    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);
    background-color: white;
    width: 2.2vmax;
    height: 2.2vmax;
  }

  .wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-delete-fab{
    margin-top: 0.5vmax;
  }

  .wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-hidden-fab{
    display: none;
}

  
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-change-fab .change {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/camera.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }

  .wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-delete-fab .delete {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/delete.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }
  
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-change-fab:hover .change,
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-preview .image-options .image-delete-fab:hover .delete {
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
  }
  

.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-content {
    position: relative;
    width: 68%;
    text-align: left;
}
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-content span {
    display: block;
    font-size: 1.5vmax;
    margin-left: 5%;
}
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-content .text-one {
    font-size: 1.5vmax;
}
.wrapper-add-consortium .container-add-consortium-content .add-consortium-image .add-consortium-image-content .text-two {
    font-size: 0.9vmax;
}






.wrapper-add-consortium .container-add-consortium-content{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
    margin-top: 2vmax;

}



.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-text,
.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-label{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    width: 90%;
    height: 100%;
}

.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-text .add-consortium-content-text-input,
.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-label .side-label{
    position: relative;
    width: 50%;
}

.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-label .side-label span{
    font-size: 1vmax;;
    background-color: rgb(0, 0, 0);
    color:white;
    display: block;
    width: 100%;
    padding-left: 1vmax;
    text-transform: uppercase;
}


.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-text .add-consortium-content-text-input .content-text{
    position: relative;
    width: 100%;
    outline: none;
    border: none;
    border-left: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 100%;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
    font-size: 0.9vmax;;


}


.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-text .add-consortium-content-text-preview{
    position: relative;
    width: 50%;
    height: 100%;

}


.wrapper-add-consortium .container-add-consortium-content .add-consortium-content-text .add-consortium-content-text-preview .content-preview{
    position: relative;
    display: block;
    box-shadow: 0 0 20px #00000048;
    width: 100%;
    height: 100%;
    word-break: break-all;
    border-right: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 10vmax;

    padding: 0.2vmax;
    padding-left: 0.5vmax;
    font-size: 0.9vmax;;


}




.wrapper-add-consortium .container-add-consortium-main .main-row .input-calendar .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}




.wrapper-add-consortium .container-add-consortium-content .container-toolbar{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    margin-right: 5%;

}


.wrapper-add-consortium .container-add-consortium-content .container-toolbar .title{
    position: relative;
    margin-right: 3%;
    color:black;
    font-size: 0.9vmax;;

}

.wrapper-add-consortium .container-add-consortium-content .container-toolbar .title span{
    display: block;
}

.wrapper-add-consortium .container-add-consortium-content .container-toolbar .options{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    align-items: center;

}


.wrapper-add-consortium .container-add-consortium-content .container-toolbar .options .opt-info{
    position: relative;
    width: 5%;
    height: 1.2vmax;
    min-width: 1.2vmax;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;

    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);


}

.wrapper-add-consortium .container-add-consortium-content .container-toolbar .options div{
    margin-right:10%;
}



.wrapper-add-consortium .container-add-consortium-content .container-toolbar .options .opt-info .icon{
    background-image: url("../../../../assets/figma/info.svg");
    background-size: 1.5vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}

.wrapper-add-consortium .container-add-consortium-content .container-toolbar .options .opt-info:hover .icon{
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
}












.wrapper-add-consortium .container-form-bottom{
    position: relative;
    width: 100%;
    height: 100%;

    margin-top: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

}
.wrapper-add-consortium .container-form-bottom .submit{
    position: relative;
    width: 50%;
    border: none;

}

.wrapper-add-consortium .container-form-bottom .submit .submit-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    -moz-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    box-shadow:0 0 2px rgba(0, 0, 0, 0.411);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-add-consortium .container-form-bottom .submit .submit-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}



.wrapper-add-consortium .container-form-bottom .submit .submit-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}

.wrapper-add-consortium .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-consortium .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-consortium .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


