.wrapper-calendar-main {
    position: relative;
    width: 100%;
    text-align: right;
    border-right: 1px solid #e4ce8e;
    border-bottom: 1px solid #e4ce8e;

    user-select: none;
}

.wrapper-calendar-main .container-calendar-main-table-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

}

.wrapper-calendar-main .container-calendar-main-table-column {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
}

.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day {
    height: 100%;
    width: 100%;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
}
.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day .day-events {
    width: 72%;
    height: 100%;
    text-align: left;
}


.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day .day-events .event-badge{
    position: relative;
    height: 1.5vmax;
    width: 1.5vmax;;
    margin-top: 0.7vmax;
    margin-left: 0.5vmax;

}



.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day .day-events .event-badge .event-badge-icon{
    position: relative;
    background-image: url("./../../../../assets/figma/event.svg");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
}


.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day .day-number{
    width: 18%;
    height: 100%;
    margin: 0.5vmax 10% 0 0%;

}
.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day .day-number span{
    display: block;
    text-align: center;
    font-size:1vmax;
    width: 100%;
}


.wrapper-calendar-main .container-calendar-main-table-column .container-flex-day .day-number .ayy-event{
    display: block;
    text-align: center;
    border: 2px solid #d77a4800;
    border-radius:50%;
    width: 100%;
    min-width: 2vmax;
    min-height: 2vmax;

}


.kekax-cell,
.kekax-paint {
    position: relative;
    height: 6vmax;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid rgba(214, 214, 214, 0.616);

}

.kekax-cell {
    background: rgba(255, 255, 255, 0.7);
}

.kekax-cell-selected {
    background-color: rgba(216, 226, 26, 0.4);
}

.kekax-cell-today {
    background-color: rgba(49, 110, 180, 0.3);
}

.kekax-cell:hover {
    background-color: rgba(216, 226, 26, 0.2);
}

.kekax-cell:hover .event-badge{
    transform: scale(1.1);
}

.kekax-paint{
    background: rgba(206, 206, 206, 0.384);
}


.wrapper-calendar-main .event-day-popper{
    position: absolute;
    display:none;
    z-index:1000;
  }
  .wrapper-calendar-main .event-day-popper-open{
    display: blocK;
  }



.wrapper-calendar-main .event-day-popper .event-paper-wrapper{
    position: relative;
    width: 25vmax;
    text-align: left;
    user-select: none;
    padding: 0.3vmax 1vmax 1vmax 0.5vmax;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 2px 10px #00000048;


}

.wrapper-calendar-main .event-day-popper .event-paper-wrapper .container-event-paper-header{
    width: 100%;
    font-size: 1.2vmax;
    padding: 0.5vmax 0 0.5vmax 0.8vmax;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.493);
}

.wrapper-calendar-main .event-day-popper .event-paper-wrapper .container-event-paper-body{
    margin-top:1vmax;
    width: 100%;
    
}

.wrapper-calendar-main .event-day-popper .event-paper-wrapper .container-event-paper-body .event-day-object{
    padding: 0.3vmax 0 0.3vmax 0.8vmax;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    font-size: 0.9vmax;

}

.wrapper-calendar-main .event-day-popper .event-paper-wrapper .container-event-paper-body .event-day-object:hover span{
    /*text-decoration: underline;*/
    color: #d77948;
}