.wrapper-infoapp{
    position: relative;
    margin-top: 2vmin;
    min-height:95vmin;
    width:100%;
    padding: 2vmax;
    background-image: url("../../../assets/homepage/hmmm.jpg");

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-items: center;


}

.wrapper-infoapp .container-info{
    position: relative;
    width: 90%;
    /*margin: 0 auto;*/
    margin-left: 8%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

}

.wrapper-infoapp .container-info .info-img{
    width: 30%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../assets/homepage/orange.jpg");
    box-shadow: 8px 0 8px 4px rgba(255, 255, 255, 0.39) inset;
    animation: imageInfo 1s;
    /*animation: name duration timing-function delay iteration-count direction fill-mode play-state;*/
}

@keyframes imageInfo{
    from{
        transform: scale(0.8) translate(-100px,0px);
        opacity: 0;
    }
    to{
        transform: scale(1) translate(0px,0px);
        opacity: 1;
    }
}






.wrapper-infoapp .container-info .info-content{
    width: 70%;
    padding: 2vmax;

    background-color: rgba(255, 255, 255, 0.747);
    box-shadow: inset 0 0 100px rgb(255, 255, 255);
    animation: contentInfo 2s;

}


@keyframes contentInfo{
    from{
        transform: translate(200px,0px);
        opacity: 0;
    }
    to{
        transform: translate(0px,0px);
        box-shadow: '-8px 0 8px 4px rgba(255, 255, 255, 0.39) inset';
        opacity: 1;
    }
}



.wrapper-infoapp .container-info .info-content> *{
    width:100%;
    display: block;
    font-family: "Montserrat";


}

.wrapper-infoapp .container-info .info-content .text-title{
    font-size: 2vmax;
    text-align: justify;
}

.wrapper-infoapp .container-info .info-content .text-description{
    font-size: 1vmax;
    text-align: justify;
    margin-top: 1.2vmax;
}

.wrapper-infoapp .container-info .info-content .text-author{
    font-style: italic;
    margin-top: 0.8vmax;
    font-size: 1vmax;
}





@media only screen and (max-width: 700px) {

    .wrapper-infoapp{
        padding: 8vmax 0;
    }

    .wrapper-infoapp .container-info{
        position: relative;
        width: 80%;
        margin: 0 auto;
        display: block;
    }
    


    .wrapper-infoapp .container-info .info-content{
        width: 100%;
        padding: 2vmax;
    
        background-color: rgba(255, 255, 255, 0.747);
        box-shadow: inset 0 0 100px rgb(255, 255, 255);
    }
    
    .wrapper-infoapp .container-info .info-img{
        width: 100%;
        height: 32vmax;
    
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../../assets/homepage/orange.jpg");
        box-shadow: 0 0 8px 8px rgba(255, 255, 255, 0.39) inset;
    }

  }
 