.wrapper-kekax-snackbar  {
    position: fixed;
    display: none;
    z-index: 10000;

    cursor: default;
    user-select: none;
    word-wrap:break-word;


    background-color: rgba(51, 51, 51, 0.473);
    color: rgb(255, 255, 255);
    text-align: center;
    width: 20%;
    padding: 1.2vmax;
    bottom: 2vmax;
    left: 40%;

    font-size: 0.9vmax;

    font-family: Montserrat, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;

  }


  
  .wrapper-kekax-snackbar.show {
    display: block;
   -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
   animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 2vmax; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 2vmax; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 2vmax; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 2vmax; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }