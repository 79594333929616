.wrapper-add-mine{
    position: relative;
    width: 100%;
}


.wrapper-add-mine .container-add-mine-inventory{
    position: relative;
    width: 100%;
    height: 100%;
}




.wrapper-add-mine .container-add-mine-inventory .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-mine .container-add-mine-inventory .container-title span{
    display: block;
    padding-left: 1vmax;
}



.wrapper-add-mine .container-add-mine-inventory .map-wrapper{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 2vmax;
}


.wrapper-add-mine .container-add-mine-inventory .map-wrapper .map-container {
    position: absolute; 
    top: 0;
    left:0;

    border-radius: 10px;
    height: 50vmax;
    -webkit-box-shadow: rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;

    /*
    padding: 3vmax 0 1vmax 0;
    margin: 2.5vmax auto 2.5vmax auto;
    */

}

.wrapper-add-mine .container-add-mine-inventory .map-wrapper .info-container{
    position: relative;
    width: 40%;;
} 

.wrapper-add-mine .container-add-mine-inventory .map-wrapper .info-container span{
    display: block;
    font-size: 1vmax;
    padding-left: 5%;
}


.wrapper-add-mine .container-add-mine-inventory .map-wrapper .info-container span:first-child{
    font-weight: bold;
    font-size: 1.2vmax;
}
.wrapper-add-mine .container-add-mine-inventory .map-wrapper .info-container span:not(:first-child){
    padding-top: 5%;
}

.wrapper-add-mine .marker {
    background-image: url('../../../../assets//markers/marker4.svg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0;
  }
  .wrapper-add-mine .container-add-mine-inventory .map-wrapper .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #ffffff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
    font-size: 0.8vmax;
    width: 45%;;
    }

    .wrapper-add-mine .container-add-mine-inventory .main-row{
        position: relative;
        width: 90%;
        height: 100%;
        min-height: 3.5vmax;;
    
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        
        -ms-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
    
    }
    
    
    .wrapper-add-mine .container-add-mine-inventory .main-row .input-normal{
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0.5vmax 0;
        
    }

    .wrapper-add-mine .container-add-mine-inventory .main-row .input-medium{
        position: relative;
        width: 55%;
        height: 100%;
        margin: 0.5vmax 0;
        
    }



.wrapper-add-mine .container-add-mine-inventory .main-row-split{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: space-between;
}


.wrapper-add-mine .container-add-mine-inventory .main-row-split .input-normal{
    position: relative;
    width: 48%;
    height: 100%;
}


.wrapper-add-mine .container-add-mine-inventory .main-row-triple{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: space-between;
}


.wrapper-add-mine .container-add-mine-inventory .main-row-triple .input-normal{
    position: relative;
    width: 30%;
    height: 100%;
}


.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-normal-text,
.wrapper-add-mine .container-add-mine-inventory .main-row .input-medium .input-normal-text,
.wrapper-add-mine .container-add-mine-inventory .main-row-split .input-normal .input-normal-text,
.wrapper-add-mine .container-add-mine-inventory .main-row-triple .input-normal .input-normal-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    height: 1.8vmax;
    font-size: 0.9vmax;;

}

.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-area-text,
.wrapper-add-mine .container-add-mine-inventory .main-row .input-medium .input-area-text,
.wrapper-add-mine .container-add-mine-inventory .main-row-split .input-normal .input-area-text,
.wrapper-add-mine .container-add-mine-inventory .main-row-triple .input-normal .input-area-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    height: 3vmax;
    font-size: 0.9vmax;;

}


.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .label,
.wrapper-add-mine .container-add-mine-inventory .main-row .input-medium .label,
.wrapper-add-mine .container-add-mine-inventory .main-row-split .input-normal .label,
.wrapper-add-mine .container-add-mine-inventory .main-row-triple .input-normal .label{

    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}

  select::after {
    position: absolute;

    content: "";
    top:0;
    left:0;
    width: 0.2vmax;
    height:0.2vmax;
    background-color: red;
    z-index: 100;

  }



.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country{
    position: relative;
    width: 42%;
    height: 1.8vmax;
    font-size: 0.9vmax;;

    border: none;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    height: 1.8vmax;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-content: center;
    justify-content: flex-start;
    cursor: default;
    background-color: white;

}


.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country .opt-flag{
    position: relative;
    width: 5%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    height: 100%;

}

.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country .text-country{
    position: relative;
    width: 86%;
    text-align: left;
    height: 100%;


}

.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country .text-country span{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    padding-left:2%;

}

.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country .text-ph{
    position: relative;
    width: 91%;
    text-align: left;
    height: 100%;

}

.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country .text-ph span{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    padding-left:1%;

}

.wrapper-add-mine .container-add-mine-inventory .main-row .input-normal .input-country .arrow-down{
    position: relative;
    width: 8%;
    background: url("../../../../assets/figma/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    height: 100%;
}



.wrapper-add-mine .container-add-mine-quiz{
    position: relative;
    width: 100%;
}


.wrapper-add-mine .container-add-mine-quiz .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-mine .container-add-mine-quiz .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    user-select: none;
    font-family: Montserrat, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.wrapper-add-mine .container-add-mine-quiz .quiz-header .start-quiz{
    font-size: 1.2vmax;
}

.wrapper-add-mine .container-add-mine-quiz .quiz-header .add-round{
    position: relative;
    width: 15%;
    height: 100%;
    background-color: rgba(171, 194, 153, 0.418);

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    align-items: center;
    justify-content: center;

    cursor: pointer;
    margin-top: 1vmax;

}

.wrapper-add-mine .container-add-mine-quiz .quiz-header .add-round span{
    display: block;
    font-size: 1vmax;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-header .add-round:hover span{
    font-size: 1.05vmax;

}


.wrapper-add-mine .container-add-mine-quiz .quiz-round{
    position: relative;
    width: 100%;
    background-color: rgba(233, 233, 233, 0.096);

    -webkit-box-shadow: rgb(78, 78, 78) 0px 0px 0.5px 0.5px, inset rgba(0, 0, 0, 0.438) 0px 0px 0.5px 0.5px;
    -moz-box-shadow:rgb(78, 78, 78) 0px 0px 0.5px 0.5px, inset rgba(0, 0, 0, 0.438) 0px 0px 0.5px 0.5px;
    -o-box-shadow:rgb(78, 78, 78) 0px 0px 0.5px 0.5px, inset rgba(0, 0, 0, 0.438) 0px 0px 0.5px 0.5px;
    box-shadow:rgb(78, 78, 78) 0px 0px 0.5px 0.5px, inset rgba(0, 0, 0, 0.438) 0px 0px 0.5px 0.5px;
    padding: 1vmax;
}




.wrapper-add-mine .container-add-mine-quiz .quiz-round .main-question{
    position: relative;
    width: 50%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    font-size: 1vmax;

}

.wrapper-add-mine .container-add-mine-quiz .quiz-round .main-question .input-question{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .main-question .input-question .input-question-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    
}



.wrapper-add-mine .container-add-mine-quiz .quiz-round .main-question .input-question .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 10vmax;
    font-size: 1vmax;

}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header{
    position: relative;
    width: 100%;
    height: 2vmax;
    margin-bottom: 1vmax;;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header .add-answer,
.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header .rmv-answer{
    position: relative;
    width: 5%;
    height: 100%;
    border: 1px solid black;
}

.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header .add-answer .add-icon{
    cursor: pointer;
    background-image: url("../../../../assets/figma/add.svg");
    position: relative;
    background-size: 1.2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header .rmv-answer .rmv-icon{
    cursor: pointer;
    background-image: url("../../../../assets/figma/delete.svg");
    position: relative;
    background-size: 1.2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
    filter: invert(20%) sepia(93%) saturate(7404%) hue-rotate(360deg) brightness(105%) contrast(117%);

}



.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header .add-answer .add-icon:hover,
.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .answers-header .rmv-answer .rmv-icon:hover{
    background-size: 1.3vmax;
}




.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .main-answer{
    position: relative;
    width: 50%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}

.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .main-answer .input-answer{
    position: relative;
    width: 93%;
    height: 100%;

}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .main-answer .input-answer .input-answer-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    padding-left:0.5vmax;
    height: 2vmax;

}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .main-answer .input-check{
    position: relative;
    width: 5%;
    margin-left: 2%;
    cursor: pointer;

}
.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .main-answer .input-check .check-icon{
    position: relative;
    width: 100%;
    height: 2vmax;
    background-color: rgb(185, 185, 185);
}

.wrapper-add-mine .container-add-mine-quiz .quiz-round .container-answers .main-answer .input-check-active .check-icon{

    background-color: rgb(88, 190, 84);
}





.wrapper-add-mine .container-add-mine-quiz .quiz-round:not(:last-child){
    margin-bottom: 2vmax;
}



.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header{
    position: relative;
    width: 100%;
    height: 2vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .add-up,
.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .add-down,
.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .rmv{
    position: relative;
    width: 5%;
    height: 100%;
    border: 1px solid black;
    
}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .add-up .add-up-icon
{
    cursor: pointer;
    background-image: url("../../../../assets/figma/addUp.svg");
    position: relative;
    background-size: 1.2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}

.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .add-down .add-down-icon
{
    cursor: pointer;
    background-image: url("../../../../assets/figma/addDown.svg");
    position: relative;
    background-size: 1.2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}


.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .rmv .rmv-icon
{
    cursor: pointer;
    background-image: url("../../../../assets/figma/remove.svg");
    position: relative;
    background-size: 1.2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
    filter: invert(20%) sepia(93%) saturate(7404%) hue-rotate(360deg) brightness(105%) contrast(117%);


}



.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .add-up .add-up-icon:hover,
.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .add-down .add-down-icon:hover,
.wrapper-add-mine .container-add-mine-quiz .quiz-round .round-header .rmv .rmv-icon:hover{
    background-size: 1.3vmax;
}




.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}


.wrapper-add-mine .container-add-mine-siteInfo{
    position: relative;
    width: 100%;
}


.wrapper-add-mine .container-add-mine-siteInfo .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-mine .container-add-mine-siteInfo .container-title span{
    display: block;
    padding-left: 1vmax;
}




.wrapper-add-mine .container-form-bottom{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 5vmax;
    margin-bottom: 5vmax;
    padding: 0.5vmax;

}



.wrapper-add-mine .container-form-bottom .next{
    position: relative;
    width: 30%;

}

.wrapper-add-mine .container-form-bottom .submit{
    position: relative;
    width: 30%;

}



.wrapper-add-mine .container-form-bottom .previous{
    position: relative;
    width: 30%;

}




.wrapper-add-mine .container-form-bottom .next .next-btn,
.wrapper-add-mine .container-form-bottom .submit .submit-btn,
.wrapper-add-mine .container-form-bottom .previous .previous-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
    font-size: 0.9vmax;

}


.wrapper-add-mine .container-form-bottom .next .next-btn:hover,
.wrapper-add-mine .container-form-bottom .submit .submit-btn:hover,
.wrapper-add-mine .container-form-bottom .previous .previous-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);

}




.wrapper-add-mine .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-mine .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-mine .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}



/*DROPDOWN*/


.wrapper-add-mine .flags-menu-drop{
    position: absolute;
    cursor: pointer;
    font-size: 0.9vmax;
    display: none;
    -webkit-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -moz-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -o-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    width: 36.2%;
    z-index: 2000;
    height: 10vmax;

}

.wrapper-add-mine .flags-menu-drop-open{
    display: block;
}

.wrapper-add-mine .flags-menu-drop .wrapper-flags-menu-dropdown{
    position: relative;
    background-color: rgb(255, 255, 255);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
}

.wrapper-add-mine .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option{
    height: 2vmax;
    width: 100%;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center; 
    text-transform: uppercase;

    font-size: 0.9vmax;

}

.wrapper-add-mine .flags-menu-drop .wrapper-flags-menu-dropdown .opt-active{
    background-color: rgba(226, 226, 226, 0.322);
}


.wrapper-add-mine .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option:hover{
    background-color: rgba(226, 226, 226, 0.322);
}

.wrapper-add-mine .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option .text{
    padding-left: 1.5%;
}

.wrapper-add-mine .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option .icon{

    position: relative;
    width: 2vmax;
    height: 2vmax;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
}
