.wrapper-wannaPlay-modal{
    display: none;
    z-index: 5000;
    user-select: none;

}

.wrapper-wannaPlay-modal .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.582);
    z-index: 5001;
}

.wannaPlay-modal-open{
    display: block;
}

.wrapper-wannaPlay-modal.wannaPlay-modal-open .overlay{
    
}


@keyframes wannaPlay-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}


.wrapper-wannaPlay-modal .content{
    position: absolute;
    left:27.5%;
    /*top: calc( (-1*var(--navMain-real-height)) + var(--wannaPlayModal-topOffset));*/
    top:calc(var(--wannaPlayModal-topOffset) + 25%);
    background-color: #fff;
    z-index: 5002;
    padding: 0.5vmax 1.5vmax;
    box-sizing: border-box;
    width: 45%;
    display: none;
    border-radius: 0.5%;

}
.wannaPlay-modal-open .content{
    word-wrap: break-word;
    animation: wannaPlay-modal-fadein 0.2s ease-in-out;
    display: block;
    
}


.wrapper-wannaPlay-modal .content .header{
    position: relative;
    width: 100%;
}

.wrapper-wannaPlay-modal .content .header hr{
    margin: 0;
    padding: 0;
}

.wrapper-wannaPlay-modal .content .header .header-content{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    padding: 1vmax 0;

}

.wrapper-wannaPlay-modal .content .header .header-content .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
    padding: 0 0.5vmax;

}

.wrapper-wannaPlay-modal .content .header .header-content .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-wannaPlay-modal .content .header .header-content .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
}

.wrapper-wannaPlay-modal .content .header .header-content .close .close-btn .img{
    position: relative;
    background-image: url("../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;
}

.wrapper-wannaPlay-modal .content .header .close .close-btn:hover .img{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-wannaPlay-modal .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    margin: 1.5vmax 0 1.5vmax 0;
    text-align: center;
    min-height: 10vmax;

}

.wrapper-wannaPlay-modal .content .body .body-info{
    position: relative;
    width: 100%;
}


.wrapper-wannaPlay-modal .content .body .body-info .info-name{
    position: relative;
    width: 100%;
    font-size: 1.5vmax;
    margin: 1.5vmax 0;
}

.wrapper-wannaPlay-modal .content .body .body-info .info-name > span,
.wrapper-wannaPlay-modal .content .body .body-info .info-country > span{
    display: block;

}

.wrapper-wannaPlay-modal .content .body .body-info .info-name span:first-child{
    font-weight: bold;
    font-size: 1.2vmax;

}

.wrapper-wannaPlay-modal .content .body .body-info .info-country{
    position: relative;
    width: 100%;
    font-size: 1.5vmax;
    margin: 1.5vmax 0;

}


.wrapper-wannaPlay-modal .content .body .body-info .info-country span:first-child{
    font-weight: bold;
    font-size: 1.2vmax;
}

.wrapper-wannaPlay-modal .content .body .body-menu{
    position: relative;
    width: 100%;
    margin: 2vmax 0 0 0;

}


.wrapper-wannaPlay-modal .content .body .body-menu > span{
    display: block;
    font-size: 1.5vmax;
    font-family: 'Montserrat', sans-serif;


}


.wrapper-wannaPlay-modal .content .body .body-menu .menu-options{
    position: relative;
    width: 50%;
    margin: 0.5vmax auto 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.wrapper-wannaPlay-modal .content .body .body-menu .menu-options> div{
    position: relative;
    width: 45%;
    cursor: pointer;
    padding: 0.2vmax 1vmax;
}

.wrapper-wannaPlay-modal .content .body .body-menu .menu-options> div:first-child{
    background-color: rgba(143, 189, 113, 0.548)
}
.wrapper-wannaPlay-modal .content .body .body-menu .menu-options> div:first-child:hover{
    background-color: rgba(143, 189, 113, 0.801)

}

.wrapper-wannaPlay-modal .content .body .body-menu .menu-options> div:last-child{
    background-color: rgba(189, 113, 113, 0.548)
}
.wrapper-wannaPlay-modal .content .body .body-menu .menu-options> div:last-child:hover{
    background-color: rgba(189, 113, 113, 0.801)

}


.wrapper-wannaPlay-modal .content .footer{
    position: relative;
    width: 100%;

}


.wrapper-wannaPlay-modal .content .footer .footer-content{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: flex-end;

}
