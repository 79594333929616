.wrapper-register-modal{
    display: none;
    z-index: 5000;

}

.wrapper-register-modal .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 5001;
}

.register-modal-open{
    display: block;
}

.wrapper-register-modal.register-modal-open .overlay{
    
}


@keyframes register-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-register-modal .content{
    position: absolute;
    left:27.5%;
    top: calc( (-1*var(--navMain-real-height)) + var(--registerModal-topOffset));
    margin-top: -5.1vmax;
    background-color: #fff;
    z-index: 5002;
    padding: 1.5vmax;
    box-sizing: border-box;
    width: 45%;
    display: none;
    border-radius: 0.5%;

}

.register-modal-open .content{
    word-wrap: break-word;
    animation: register-modal-fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-register-modal .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    border-bottom: 0.5px solid rgba(126, 126, 126, 0.527);
    padding: 0.5vmax 1vmax;

}

.wrapper-register-modal .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
}

.wrapper-register-modal .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-register-modal .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
}

.wrapper-register-modal .content .header .close .close-btn .img{
    position: relative;
    background-image: url("../../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;
}

.wrapper-register-modal .content .header .close .close-btn:hover .img{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-register-modal .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    margin: 2vmax 0 1vmax 0;
    text-align: center;
    font-size:0.9vmax;
    min-height: 10vmax;
}

.wrapper-register-modal .content .body .body-form{
    position: relative;
    margin: 0 auto;
}

.wrapper-register-modal .content .body .body-form .main-row,
.wrapper-register-modal .content .body .body-form .main-row-split{
    position: relative;
    width: 100%;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 0.5vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.wrapper-register-modal .content .body .body-form .main-row-split .input{
    position: relative;
    width: 50%;

}
.wrapper-register-modal .content .body .body-form .main-row .input{
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.wrapper-register-modal .content .body .body-form .main-row .input .label{
    display: block;
    text-align: left;
    padding-left: 7.5%;
    margin: 0;
    margin-bottom: 0.5vmax;
}


.wrapper-register-modal .content .body .body-form .main-row .input .field{
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0.9vmax;
}


.wrapper-register-modal .content .body .body-form .main-row-split .input:first-child .field{
    position: relative;
    width: 82.5%;
    font-size: 0.9vmax;
    text-align: right;
    margin-left: 15%;


}

.wrapper-register-modal .content .body .body-form .main-row-split .input:first-child .label{
    padding-left: 15%;
}

.wrapper-register-modal .content .body .body-form .main-row-split .input:last-child .field{
    position: relative;
    width: 82.5%;
    font-size: 0.9vmax;
    text-align: left;
    margin-left: 2.5%;
}
.wrapper-register-modal .content .body .body-form .main-row-split .input:last-child .label{
    padding-left: 2.5%;
}

.wrapper-register-modal .content .body .body-form .main-row-split .input:last-child{
}

.wrapper-register-modal .content .body .body-form .main-row .input .field-flex{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1vmax;;


}

.wrapper-register-modal .content .body .body-form .main-row .input .field-flex .input-text{
    position: relative;
    width: 85%;
    border: none;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    height: 1.8vmax;
    padding-left:0.5vmax;
    padding-right:12%;


}

.wrapper-register-modal .content .body .body-form .main-row .input .field-flex .input-text:focus,
.wrapper-register-modal .content .body .body-form .main-row .input .field .input-text:focus,
.wrapper-register-modal .content .body .body-form .main-row-split .input .field .input-text:focus{
    outline: rgba(0, 0, 0, 0.473) solid 0.2px;
}

.wrapper-register-modal .content .body .body-form .main-row .input .field-flex .input-icon{
    position: absolute;
    border: none;
    cursor: pointer;
    background-size: 1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 10%;
    height: 2vmax;
    border: 0.5px solid rgba(0, 0, 0, 0.432);
    top:0;
    left:80%;
    border:none;

}

.wrapper-register-modal .content .body .body-form .main-row .input .field .input-text{
    position: relative;
    width: 85%;
    padding-left:0.5vmax;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    height: 1.8vmax;
}

.wrapper-register-modal .content .body .body-form .main-row-split .field .input-text{
    position: relative;
    width: 100%;
    padding-left:0.5vmax;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    height: 1.8vmax;
}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country{
    position: relative;
    width: 85%;
    margin: 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-content: center;
    justify-content: flex-start;
    cursor: default;
    background-color: white;
}


.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country .opt-flag{
    position: relative;
    width: 5%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    height: 100%;

}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country .text-country{
    position: relative;
    width: 86%;
    text-align: left;
    height: 100%;

}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country .text-country span{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    padding-left:2%;

}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country .text-ph{
    position: relative;
    width: 91%;
    text-align: left;
    height: 100%;
}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country .text-ph span{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);


}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-country .arrow-down{
    position: relative;
    width: 8%;
    background: url("../../../../assets/figma/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    height: 100%;
}

.wrapper-register-modal .content .body .body-form .main-row  .input .field .input-role{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../../../../assets/figma/arrowDown.svg");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    background-position-x: 95.5%;
    
}

.wrapper-register-modal .content .footer{
    position: relative;
    width: 85%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1.5vmax;
}



.wrapper-register-modal .content .footer .submit{
    position: relative;
    width: 70%;
    border: 0px solid black;
}

.wrapper-register-modal .content .footer .cancel{
    position: relative;
    width: 27%;
    margin-left: 3%;

}



.wrapper-register-modal .content .footer .submit .submit-btn,
.wrapper-register-modal .content .footer .cancel .cancel-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-register-modal .content .footer .submit .submit-btn .info,
.wrapper-register-modal .content .footer .cancel .cancel-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}

.wrapper-register-modal .content .footer .submit .submit-btn{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0 2px rgb(0, 0, 0);
}

.wrapper-register-modal .content .footer .submit .submit-btn:hover,
.wrapper-register-modal .content .footer .cancel .cancel-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
    background-color: rgba(186, 201, 179, 0.123);
}



.wrapper-register-modal .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;
    margin: 0.3vmax;

}

.wrapper-register-modal .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;
    margin: 0.3vmax;
}

.wrapper-register-modal .feedback-block .input-info-feedback-kekax{
    display: block;
    color:rgb(94, 94, 94);
    margin: 0.3vmax;
}

.wrapper-register-modal .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.1vmax;
    width: 85%;
    margin: 0 auto;
}

.wrapper-register-modal .feedback-block-left{
    margin-left: 15%;
}

.wrapper-register-modal .feedback-block-right{
    margin-left: 2.5%;
}

/*DROPDOWN*/


.wrapper-register-modal .flags-menu-drop{
    position: absolute;
    cursor: pointer;
    font-size: 0.9vmax;
    display: none;
    -webkit-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -moz-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -o-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    width: 36.2%;
    z-index: 2000;
    height: 10vmax;

}

.wrapper-register-modal .flags-menu-drop-open{
    display: block;
}

.wrapper-register-modal .flags-menu-drop .wrapper-flags-menu-dropdown{
    position: relative;
    background-color: rgb(255, 255, 255);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
}

.wrapper-register-modal .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option{
    height: 2vmax;
    width: 100%;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center; 
    text-transform: uppercase;

    font-size: 0.9vmax;

}

.wrapper-register-modal .flags-menu-drop .wrapper-flags-menu-dropdown .opt-active{
    background-color: rgba(226, 226, 226, 0.322);
}


.wrapper-register-modal .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option:hover{
    background-color: rgba(226, 226, 226, 0.322);
}

.wrapper-register-modal .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option .text{
    padding-left: 1.5%;
}

.wrapper-register-modal .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option .icon{

    position: relative;
    width: 2vmax;
    height: 2vmax;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
}


