.wrapper-delete-publication{
    display: none;
    z-index: 5000;

}

.wrapper-delete-publication .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 5001;
}

.deletePublication-modal-open{
    display: block;
}

.deletePublication-modal-open .overlay{
    
}



@keyframes fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-delete-publication .content{
    position: absolute;
    left:25%;
    width: 40%;    
    
    top: var(--deleteModalPublication-topOffset);
    margin-top: 5%;

    background-color: #fff;
    z-index: 5002;
    padding: 1vmax;
    box-sizing: border-box;
    display: none;

}

.wrapper-delete-publication.deletePublication-modal-open .content{
    word-wrap: break-word;
    animation: fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-delete-publication .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;


}

.wrapper-delete-publication .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
}

.wrapper-delete-publication .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-delete-publication .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
}

.wrapper-delete-publication .content .header .close .close-btn .img{
    position: relative;
    background-image: url("../../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;
}

.wrapper-delete-publication .content .header .close .close-btn:hover .img{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-delete-publication .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    margin: 2vmax 0;
    text-align: center;
    font-size:1.1vmax;

}

.wrapper-delete-publication .content .body > span{
    display: block;
}
.wrapper-delete-publication .content .body .publication-title{
    font-weight: bold;
    font-size: 1.3vmax;
}

.wrapper-delete-publication .content .footer{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

}



.wrapper-delete-publication .content .footer .cancel{
    position: relative;
    width: 62%;
}

.wrapper-delete-publication .content .footer .submit{
    position: relative;
    width: 33%;
    margin-left: 3%;

}



.wrapper-delete-publication .content .footer .cancel .cancel-btn,
.wrapper-delete-publication .content .footer .submit .submit-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-delete-publication .content .footer .cancel .cancel-btn .info,
.wrapper-delete-publication .content .footer .submit .submit-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}

.wrapper-delete-publication .content .footer .cancel .cancel-btn{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0 2px rgb(0, 0, 0);
}

.wrapper-delete-publication .content .footer .cancel .cancel-btn:hover,
.wrapper-delete-publication .content .footer .submit .submit-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
    background-color: rgba(186, 201, 179, 0.123);
}

