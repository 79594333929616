:root {
    --info-tooltip-text-color: rgba(255, 255, 255, 0.842);
    --info-tooltip-background-color: rgba(83, 82, 82, 0.753);
    --info-tooltip-margin: -13vmax;
    --info-tooltip-arrow-size: 6px;
}
  
  /* Wrapping */
.wrapper-tooltip-info {
    position: relative;
}
  
  /* Absolute positioning */
.wrapper-tooltip-info .tooltip-object {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    color: var(--info-tooltip-text-color);
    background: var(--info-tooltip-background-color);
    font-size: 1vmax;
    font-family: Roboto, Montserrat, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height:normal;
    z-index: 2000;
    white-space: nowrap;
  
}
  
  /* CSS border triangles */
.wrapper-tooltip-info .tooltip-object::before {
    content: " ";
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--info-tooltip-arrow-size);
    margin-left: calc(var(--info-tooltip-arrow-size) * -1);
}


.wrapper-tooltip-info .tooltip-object span{
  display: block;
  word-wrap: break-word;


}
  
  /* Absolute positioning */
.wrapper-tooltip-info .tooltip-object.top {
    top: calc(var(--info-tooltip-margin));

}
  /* CSS border triangles */
.wrapper-tooltip-info .tooltip-object.top::before {
    top: 100%;
    border-top-color: var(--info-tooltip-background-color);
}
  
  /* Absolute positioning */
.wrapper-tooltip-info .tooltip-object.right {
    left: calc(100% + var(--info-tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
  /* CSS border triangles */
.wrapper-tooltip-info .tooltip-object.right::before {
    left: calc(var(--info-tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--info-tooltip-background-color);
}
  
  /* Absolute positioning */
.wrapper-tooltip-info .tooltip-object.bottom {
    bottom: calc(var(--info-tooltip-margin) * 1);
}
  /* CSS border triangles */
.wrapper-tooltip-info .tooltip-object.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--info-tooltip-background-color);
}
  
  /* Absolute positioning */
.wrapper-tooltip-info .tooltip-object.left {
    left: auto;
    right: calc(100% + var(--info-tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
  /* CSS border triangles */
.wrapper-tooltip-info .tooltip-object.left::before {
    left: auto;
    right: calc(var(--info-tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--info-tooltip-background-color);
}