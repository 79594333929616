.wrapper-consortium-modal{
    display: none;
    z-index: 5000;

}

.wrapper-consortium-modal .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 5001;
}

.consortium-modal-open{
    display: block;
}

.wrapper-consortium-modal.consortium-modal-open .overlay{
    
}


@keyframes consortium-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-consortium-modal .content{
    position: absolute;
    left:22.5%;
    /*top: calc( (-1*var(--navMain-real-height)) + var(--consortiumModal-topOffset));*/
    top: var(--consortiumModal-topOffset);
    margin-top: 5vh;
    background-color: #fff;
    z-index: 5002;
    box-sizing: border-box;
    width: 55%;
    display: none;
    border-radius: 0.5%;
    max-height: 90vh;
    min-height: 90vh;

}

.consortium-modal-open .content{
    word-wrap: break-word;
    animation: consortium-modal-fadein 0.2s ease-in-out;
    display: block;
    
}

.wrapper-consortium-modal .content .header{
    position: relative;
    width: 100%;
}

.wrapper-consortium-modal .content .header .header-content{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    padding: 1vmax;

}

.wrapper-consortium-modal .content .header .header-content .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.2vmax;
    word-wrap: break-word;
}

.wrapper-consortium-modal .content .header .header-content .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-consortium-modal .content .header .header-content .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;

    background-color: transparent;
}

.wrapper-consortium-modal .content .header .header-content .close .close-btn .img{
    position: relative;
    background-image: url("../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;
}

.wrapper-consortium-modal .content .header .header-content .close .close-btn:hover .img{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-consortium-modal .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    text-align: center;
    font-size:0.9vmax;
    margin: 2vmax 0;
    padding: 0 1.5vmax;
    overflow: auto;

    max-height: 60vh;
    min-height: 60vh;

}

.wrapper-consortium-modal .content .body .block-image{
    position: relative;
    width: 25%;
    margin: 0 auto;
    margin-bottom: 1.5vmax;;
    overflow: hidden;
}

.wrapper-consortium-modal .content .body .block-image img{
    width: 100%;
    height: 100%;
}


.wrapper-consortium-modal .content .body .block-text{
    position: relative;
    width: 100%;
    font-size: 0.9vmax;
    text-align: justify;
    margin-bottom: 0.25vmax;

}

.wrapper-consortium-modal .content .body .block-text:last-child{
    margin-bottom: 0;

}

.wrapper-consortium-modal .content .body .block-text span{
    display: block;
}



.wrapper-consortium-modal .content .footer{
    position: relative;
    width: 100%;

}


.wrapper-consortium-modal .content .footer .footer-content{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: flex-end;

}

.wrapper-consortium-modal .content .footer .footer-content .cancel{
    position: relative;
    width: 20%;

}


.wrapper-consortium-modal .content .footer .footer-content .cancel .cancel-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;

    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-consortium-modal .content .footer .footer-content .cancel .cancel-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}

.wrapper-consortium-modal .content .footer .footer-content .cancel .cancel-btn:hover{
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0px 1px rgb(0, 0, 0);
    background-color: rgba(216, 223, 213, 0.014);
}

