.wrapper-profile{
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 4vmax;;

}

.wrapper-profile .profile-banner{
    position: relative;
    width: 100%;
    /*background: radial-gradient( circle, transparent 5%, black 150%), rgba(255, 255, 255, 0.205);*/
    height: 28vmax;
    /*
    transform: skewY(-1deg);
    transform-origin: left;
    */
    background-image: var(--profile-banner);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    /*background-color: rgba(221, 221, 221, 0.466)*/
    
}

.wrapper-profile .profile-container{
    position:relative;
    top: -4vmax;
    background-color: white;

    /*
    -moz-border-radius: 1%;
    border-radius: 1%;
    */

    -webkit-box-shadow:0 0 10px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 10px rgba(0,0,0,0.8);
    box-shadow:0 0 10px rgba(0,0,0,0.8);

    width: 70%;
    margin: 0 auto;

}

.wrapper-profile .profile-container .profile-image-label{
    position: absolute;
    width: 100%;
    top: -9vmax;
    z-index: 100;

}

.wrapper-profile .profile-container .profile-image-label .profile-image{
    position: relative;
    height: 12.5vmax;
    width: 12.5vmax;
    background-color: white;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid white;

    -webkit-box-shadow:0 0 10px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 10px rgba(0,0,0,0.8);
    box-shadow:0 0 10px rgba(0,0,0,0.8);
    
}

.wrapper-profile .profile-container .profile-image-label .profile-image img{
    width:100%;
    height: 100%; 
}

.wrapper-profile .profile-container .profile-image-label .image-change {
    position: absolute;
    width: 20%;
    left:40%;
    z-index: 1100;
    bottom: 0;

  }
  
  .wrapper-profile .profile-container .profile-image-label .image-change .image-change-fab {
    position: relative;
    margin-left: 70%;
    border-radius: 50%;
    outline: none;
    user-select: none;
    border: none;
    padding: 0;
    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);
    background-color: white;
    
  }
  
  .wrapper-profile .profile-container .profile-image-label .image-change .image-change-fab .change {

    border-radius: 50%;
    outline: none;
    width: 2.2vmax;
    height: 2.2vmax;
    cursor: pointer;
    position: relative;
    background: url("../../assets/figma/camera.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;

    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }
  
  .wrapper-profile .profile-container .profile-image-label .image-change:hover .image-change-fab .change {
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
  }
  



.wrapper-profile .profile-container .profile-menu-label{
    position: relative;
    width: 100%;
    height: 3.5vmax;
    padding: 0.8vmax;
    margin-bottom: 1vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrapper-profile .profile-container .profile-menu-label .menu-right,
.wrapper-profile .profile-container .profile-menu-label .menu-left{
    width: 35%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrapper-profile .profile-container .profile-menu-label .menu-right{
    justify-content: flex-end;
    margin-left: 30%;
}

.wrapper-profile .profile-container .profile-menu-label .menu-right .add,
.wrapper-profile .profile-container .profile-menu-label .menu-right .remove,
.wrapper-profile .profile-container .profile-menu-label .menu-right .cancel{
    width: 40%;
    height: 100%;
    background-color: rgba(154, 194, 136, 0.541);
    outline: none;
    user-select: none;
    border: 1px solid green;
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0 2px rgb(0, 0, 0);
    z-index: 200;
    line-height: 0;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /*border-radius: 0.8vmax;*/

}

.wrapper-profile .profile-container .profile-menu-label .menu-right .add{
    background-color: rgba(154, 194, 136, 0.541);
}
.wrapper-profile .profile-container .profile-menu-label .menu-right .remove{
    background-color: rgba(194, 136, 136, 0.541);
    border: 1px solid red;

}
.wrapper-profile .profile-container .profile-menu-label .menu-right .cancel{
    background-color: rgba(184, 189, 188, 0.541);
    border: 1px solid grey;
}

.wrapper-profile .profile-container .profile-menu-label .menu-right .add .img,
.wrapper-profile .profile-container .profile-menu-label .menu-right .remove .img,
.wrapper-profile .profile-container .profile-menu-label .menu-right .cancel .img{
    position: relative;
    background-image: url("../../assets/figma/add.svg");
    background-size: 1vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 17%;
}
.wrapper-profile .profile-container .profile-menu-label .menu-right .add .img{
    background-image: url("../../assets/figma/add.svg");
    
}
.wrapper-profile .profile-container .profile-menu-label .menu-right .remove .img{
    background-image: url("../../assets/figma/remove.svg");
}
.wrapper-profile .profile-container .profile-menu-label .menu-right .cancel .img{
    background-image: url("../../assets/figma/wait.svg");
}




.wrapper-profile .profile-container .profile-menu-label .menu-right .add .info,
.wrapper-profile .profile-container .profile-menu-label .menu-right .remove .info,
.wrapper-profile .profile-container .profile-menu-label .menu-right .cancel .info{
    position: relative;
    display: block;
    font-size: 0.9vmax;
    word-wrap: break-word;
    text-align: left;

}

.wrapper-profile .profile-container .profile-menu-label .menu-left{
    justify-content: flex-start;
}


.wrapper-profile .profile-container .profile-id-label{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  
    align-items: flex-end;
    justify-content: center;
    height: 7vmax;


}

.wrapper-profile .profile-container .profile-id-label .user{
    position: relative;
    font-size: 1.5vmax;
    color: black;
    word-break: break-word;

}

.wrapper-profile .profile-container .profile-id-label .country{
    position: relative;
    font-size: 0.8vmax;;
    color: rgb(134, 134, 134);
    margin-left: 0.5vmax;
    padding-bottom: 0.5vmax;;
}

.wrapper-profile .profile-container .profile-info-label{
    position: relative;
    width: 100%;
    min-height: 9vmax;;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;


}

.wrapper-profile .profile-container .profile-info-label .info-left,
.wrapper-profile .profile-container .profile-info-label .info-right{
    position: relative;
    width: 50%;
    height: 100%;
    padding: 1vmax;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    word-break: break-word;
}


.wrapper-profile .profile-container .profile-info-label .info-left .name,
.wrapper-profile .profile-container .profile-info-label .info-right .email{
    position: relative;
}
.wrapper-profile .profile-container .profile-info-label .info-left .name .label,
.wrapper-profile .profile-container .profile-info-label .info-right .email .label{
    display: block;
    font-size: 0.7vmax;
    font-weight: bold;
}
.wrapper-profile .profile-container .profile-info-label .info-left .name .data,
.wrapper-profile .profile-container .profile-info-label .info-right .email .data{
    display: block;
    font-size: 1vmax;
    padding-left: 0.7vmax;
}


.wrapper-profile .profile-container .show-more{
    position: relative;
    width: 10%;
    padding:1vmax;
    margin: 0 auto;
    text-align: center;
    font-size: 0.5vmax;
    color: grey;
    cursor: pointer;
}



.wrapper-profile .profile-container .profile-content{
    position:absolute;
    left:0;
    bottom: -2.5vmax;
    width: 100%;
    margin: 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 1.2vmax 4vmax;
    justify-content: flex-start;

}

.wrapper-profile .profile-container .profile-content :first-child{
    margin-left: 0;
}

.wrapper-profile .profile-container .profile-content :last-child{
    margin-right: 0;

}

.wrapper-profile .profile-container .profile-content .edit-profile,
.wrapper-profile .profile-container .profile-content .delete-profile
{
    position: relative;
    width: 8%;
    margin: 0 calc(5%/4);
}

.wrapper-profile .profile-container .profile-content .edit-profile button,
.wrapper-profile .profile-container .profile-content .delete-profile button
{
    position: relative;
    height: 2.5vmax;
    width: 100%;
    outline: none;
    border: none;
    user-select: none;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow:rgba(78, 78, 78, 0.082) 1.5px 3px 2px 0px, inset rgba(255, 255, 255, 0.322)0px 0px 1px 2px;
    -moz-box-shadow:rgba(78, 78, 78, 0.082) 1.5px 3px 2px 0px, inset rgba(255, 255, 255, 0.322)0px 0px 1px 2px;
    -o-box-shadow:rgba(78, 78, 78, 0.082) 1.5px 3px 2px 0px, inset rgba(255, 255, 255, 0.322)0px 0px 1px 2px;
    box-shadow:rgba(78, 78, 78, 0.253) 1.5px 3px 2px 0px, inset rgba(105, 105, 105, 0.185)0px 0px 1px 2px;

}



.wrapper-profile .profile-container .profile-content .edit-profile button .img,
.wrapper-profile .profile-container .profile-content .delete-profile button .img
{
    position: relative;
    background-size: 1.5vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
}




.wrapper-profile .profile-container .profile-content .edit-profile:hover button .img,
.wrapper-profile .profile-container .profile-content .delete-profile:hover button .img
{
    background-size: 1.8vmax;

}


.wrapper-profile .profile-container .profile-content .edit-profile button .img{
    background-image: url("../../assets/figma/edit.svg");

}

.wrapper-profile .profile-container .profile-content .delete-profile button .img{
    background-image: url("../../assets/figma/delete.svg");

}
