:root {
    --PI: 3.141592653589793; 
  }


.wrapper-quiz-start{
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 100vh;
    padding-top: 5vmax;

    padding-bottom: 5vmax;;
    background-image: url("../../../../assets/images/map3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.wrapper-quiz-start .container-countdown{
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.144);
    padding:5vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
    

}


.wrapper-quiz-start .container-countdown .msg{
    position: relative;
    color:white;
    width: 100%;
    color: white;
    font-size: 3.5vmax;
    font-family: Montserrat, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.wrapper-quiz-start .container-countdown .countdown {
    position: relative;
    margin: auto;
    width: 20vmax;
    height: 20vmax;
    text-align: center;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

  }
  
  .wrapper-quiz-start .container-countdown .countdown .countdown-number{
    position: relative;
    color: white;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
  }
  

  .wrapper-quiz-start .container-countdown .countdown .countdown-number span{
      display: inline-block;
      font-size:8vmax;

  }
  
  
  .wrapper-quiz-start .container-countdown .countdown svg {
    position: absolute;
    left: 0;
    top:0;
    width: 20vmax;
    height: 20vmax;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }
  
  .wrapper-quiz-start .container-countdown .countdown svg .circle-grey {

    stroke-dasharray: calc(var(--PI)*2*7vmax);
    stroke-dashoffset: 0px;
    stroke-width: 15px;
    fill: none;
    stroke: rgba(0, 0, 0, 0.473);
    

  }

  .wrapper-quiz-start .container-countdown .countdown svg .circle-colour {

    stroke-dasharray: calc(var(--PI)*2*7vmax);
    stroke-dashoffset: 0px;
    stroke-width: 15px;
    fill: none;
    animation: countdown 3.2s linear forwards;
    stroke: rgb(255, 255, 255);

  }
  
  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: calc(var(--PI)*2*7vmax);
    }
  }
