.wrapper-publication{
    position: relative;
    width: 100%;
    cursor: default;
    user-select: none;
    padding-top:2.5vmax;
    padding-bottom:4vmax;
}


.wrapper-publication::before{
    position: absolute;
    top:0;
    left:0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("../../assets/homepage/hmmm.jpg");

    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.94;
}


.wrapper-publication .content-all{
    position: relative;
    background-color: #cfcccc2a;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.288);;
    padding: 3vmax 0 1vmax 0;
    width: 80%;
    margin: 2.5vmax auto 2.5vmax auto;
    min-height: 80vh;
}


.wrapper-publication .content-all .container-header {
    position: relative;
    width: 85%;
    margin: 0 auto 3.5vmax auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;

}

.wrapper-publication .content-all .container-header .content{
    width: 50%;
    font-size: 1.4vmax;
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    border-bottom: solid 1px rgba(0, 0, 0, 0.466);
    padding-bottom: 0.5vmax;
}

.wrapper-publication .content-all .container-header .content span{
    padding-left: 1.5%;
    text-transform: uppercase;
    color: black;
    background-color: #efe9cc;
    padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}


.wrapper-publication .content-all .container-body{
    position: relative;
    width: 87.5%;
    margin: 0 auto 0 auto;
    padding-left: 2vmax;
}

.wrapper-publication .content-all .container-body .publication-object{
    position: relative;
    width: 100%;
    margin: 5.5vh 0;
    background-color: rgba(255, 255, 255, 0.55);
    -webkit-box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    border-radius: 0.3vmax;
    padding: 1.2vmax 0.8vmax;

}



.wrapper-publication .content-all .container-body .publication-object .object-flex{
    position: relative;

    font-size: 1vmax;
    width: 100%;
    min-height: 2vmax;
    padding: 1vmax 0.8vmax;

}


.wrapper-publication .content-all .container-body .publication-object:hover {
    
    -webkit-box-shadow: rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    transform: scale(1.005);
}


.wrapper-publication .content-all .container-body .publication-object .object-flex > *{
    display: block;

}


.wrapper-publication .content-all .container-body .publication-object .object-flex .publication-object-title{
    color: #c36a2d;
    font-weight:600;

}

.wrapper-publication .content-all .container-body .publication-object .object-flex .publication-object-content{
    color: black;
    font-weight:350;
}

.wrapper-publication .content-all .container-body .publication-object .object-flex .publication-object-link{
    width: fit-content;
    font-weight:350;

}




.wrapper-publication .content-all .container-body .show-more{
    position: relative;
    width: 100%;
    height: 5vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: center;
}

.wrapper-publication .content-all .container-body .show-more .button-show{
    outline: none;
    border: none;
    font-size: 1.2vmax;
    padding: 0.35vmax 2vmax;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    -o-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;

}

.wrapper-publication .content-all .container-body .show-more .button-show:hover {
    transform: scale(1.02);
}




.wrapper-publication .content-all .container-body .no-content{
    display: block;
    position: relative;
    padding: 1vmax 0;
    box-shadow: 0 0 3px 5px #9c9c9cad;
    text-align: center;
    font-size: 1.1vmax;
    background-color: rgba(255, 255, 255, 0.459);
    font-family: "Montserrat", Arial, sans-serif;

}