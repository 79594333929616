.wrapper-kekax-calendar-page{
    position: relative;
    width: 100%;
    padding-bottom: 8vmax;

}


.wrapper-kekax-calendar-page .container-info-flex{
    width:85%;
    margin: 0 auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper{
    position: relative;
    width: 30%;
    background-color: rgba(228, 206, 142, 0.856);


}

.wrapper-kekax-calendar-page .container-info-flex .kekax-calendar-wrapper{
    position: relative;
    width: 70%;
}

.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-visor{
    position: relative;

    height: 50%;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    align-items: center;

}

.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-visor .island{
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;

}


.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-visor .island .kxDay{
    font-size: 8vmax;
    display:block;
    height: 10vmax;
}

.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-visor .island .kxDayWeek{
    font-size: 1.3vmax;
    display:block;
    min-height: 5vmax;

}


.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details{
    width: 100%;
}

.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-title{
    font-size: 1.7vmax;
    font-family: 'Roboto', sans-serif;
    padding-left: 10%;

}

.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-list{
    margin: 0 auto;
    width: 80%;
}



.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-list .day-details-list-object{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    width: 100%;
    font-family: 'Roboto', sans-serif;
    padding: 0.9vmax 0 0 1vmax;
    font-size: 1vmax;
    word-break: break-all;
    user-select: none;

}
.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-list .object-linked{
    cursor: pointer;
}
.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-list .object-linked:hover{
    color: #d77948;
}



.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-list .day-details-list-object .dot{
    width: 3%;

}
.wrapper-kekax-calendar-page .container-info-flex .kekax-info-wrapper .day-details .day-details-list .day-details-list-object .text{
    margin-left:2%;
    width: 95%;

}