.wrapper-add-auxMineTypes{
    display: none;
    z-index: 5000;

}

.wrapper-add-auxMineTypes .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 1200;
}

.addAuxMineTypes-modal-open{
    display: block;
}

.addAuxMineTypes-modal-open .overlay{
    
}



@keyframes fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-add-auxMineTypes .content{
    position: absolute;
    top: calc( (-1*var(--navMain-real-height)) + var(--addModalAuxMineTypes-topOffset));
    margin-top: 0;

    background-color: #fff;
    z-index: 5002;
    padding: 1vmax;
    box-sizing: border-box;
    width: 60vw;
    display: none;
    z-index: 1201;

}

.wrapper-add-auxMineTypes.addAuxMineTypes-modal-open .content{
    word-wrap: break-word;
    animation: fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-add-auxMineTypes .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;


}

.wrapper-add-auxMineTypes .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
}

.wrapper-add-auxMineTypes .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-add-auxMineTypes .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
}

.wrapper-add-auxMineTypes .content .header .close .close-btn .img{
    position: relative;
    background-image: url("../../../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;
}

.wrapper-add-auxMineTypes .content .header .close .close-btn:hover .img{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-add-auxMineTypes .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    margin: 2vmax 0;
    text-align: center;
    font-size:1.1vmax;

}

.wrapper-add-auxMineTypes .content .body > span{
    display: block;
}
.wrapper-add-auxMineTypes .content .body .publication-title{
    font-weight: bold;
    font-size: 1.3vmax;
}


.wrapper-add-auxMineTypes .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;


    margin: 2vmax 0;
    text-align: center;
    font-size:1.1vmax;
    min-height: 60vh;
    height: 60vh;

}



/*FORM ---------------------------------------------------------------------------------------------------------------*/


.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes{
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: left;

}


.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main{
    position: relative;
    width: 100%;
    height: 100%;
}




.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main .main-row{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main .main-row .input-normal{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main .main-row .input-normal .input-normal-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
}


.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .container-add-auxMineTypes-main .main-row .input-normal .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}


.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}





.wrapper-add-auxMineTypes .content .footer{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}



.wrapper-add-auxMineTypes .content .footer .next{
    position: relative;
    width: 30%;
    margin-left: 1%;

}

.wrapper-add-auxMineTypes .content .footer .submit{
    position: relative;
    width: 30%;
    margin-left: 1%;

}



.wrapper-add-auxMineTypes .content .footer .previous{
    position: relative;
    width: 30%;
    margin-left: 5%;

}

.wrapper-add-auxMineTypes .content .footer .cancel{
    position: relative;
    width: 20%;

}



.wrapper-add-auxMineTypes .content .footer .next .next-btn,
.wrapper-add-auxMineTypes .content .footer .submit .submit-btn,
.wrapper-add-auxMineTypes .content .footer .cancel .cancel-btn,
.wrapper-add-auxMineTypes .content .footer .previous .previous-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
    font-size: 0.9vmax;
    z-index: 1

}


.wrapper-add-auxMineTypes .content .footer .next .next-btn:hover,
.wrapper-add-auxMineTypes .content .footer .submit .submit-btn:hover,
.wrapper-add-auxMineTypes .content .footer .cancel .cancel-btn:hover,
.wrapper-add-auxMineTypes .content .footer .previous .previous-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}




.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-auxMineTypes .content .body .container-stepper-body .container-form-add-auxMineTypes .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}
