.wrapper-bo-manage-booklet{
    position: relative;
    width: 100%;
}

.wrapper-bo-manage-booklet .container-stats-flex{
    position: relative;
    width: 90%;
    margin: 2vmax 5%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-menu{
    position: relative;
    width: 15%;
    
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-display{
    position: relative;
    width: 85%;

}


.wrapper-bo-manage-booklet .container-stats-flex .stats-menu > *{
    position: relative;
    width: 85%;

    margin-bottom: 1vmax;
    min-height: 8vmax;
    box-shadow: 0 0 1.2px #e4ce8e94;
    border: 0.5px solid rgba(0, 0, 0, 0.123);

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt:hover{
    background-color: #e0be5736;
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .opt-selected{
    background-color: #e0be5736;
}


.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt:hover >*{
    transform: scale(1.1);
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt > *{
    display: block;
    width: 100%;
    text-align: center;
    
}
.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt .opt-icon{
    position: relative;
    background-size: 2.5vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 4vmax;
    width: 100%;
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt .icon-add{
    background-image: url("../../../assets/figma/bo/add.svg");
}
.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt .icon-chart{
    background-image: url("../../../assets/figma/bo/chart.svg");
}
.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt .icon-list{
    background-image: url("../../../assets/figma/bo/list.svg");
}

.wrapper-bo-manage-booklet .container-stats-flex .stats-menu .stats-opt .opt-text{
    font-size: 1.5vmax;
    margin-top: -4%;
}




