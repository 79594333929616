.wrapper-add-event{
    position: relative;
    width: 100%;
}


.wrapper-add-event .container-add-event-main{
    position: relative;
    width: 100%;
    height: 100%;
}

.wrapper-add-event .container-add-event-main .container-title,
.wrapper-add-event .container-add-event-attach .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}


.wrapper-add-event .container-add-event-main .container-title span,
.wrapper-add-event .container-add-event-attach .container-title span{
    display: block;
    padding-left: 1vmax;

}

.wrapper-add-event .container-add-event-article .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    margin-right: 5%;

}


.wrapper-add-event .container-add-event-article .container-title .title{
    position: relative;
    margin-right: 3%;

}

.wrapper-add-event .container-add-event-article .container-title .title span{
    display: block;
    padding-left: 1vmax;
}

.wrapper-add-event .container-add-event-article .container-title .options{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    align-items: center;

}


.wrapper-add-event .container-add-event-article .container-title .options .opt-addDown,
.wrapper-add-event .container-add-event-article .container-title .options .opt-addUp,
.wrapper-add-event .container-add-event-article .container-title .options .opt-rmv,
.wrapper-add-event .container-add-event-article .container-title .options .opt-info{
    position: relative;
    width: 5%;
    height: 1.7vmax;
    min-width: 1.7vmax;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;

    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);


}

.wrapper-add-event .container-add-event-article .container-title .options div{
    margin-right:10%;

}


.wrapper-add-event .container-add-event-article .container-title .options .opt-addDown .icon{
    background-image: url("../../../../assets/figma/addDown.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-add-event .container-add-event-article .container-title .options .opt-addUp .icon{
    background-image: url("../../../../assets/figma/addUp.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-add-event .container-add-event-article .container-title .options .opt-rmv .icon{
    background-image: url("../../../../assets/figma/delete.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}

.wrapper-add-event .container-add-event-article .container-title .options .opt-info .icon{
    background-image: url("../../../../assets/figma/info.svg");
    background-size: 1.5vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}

.wrapper-add-event .container-add-event-article .container-title .options .opt-addDown:hover .icon,
.wrapper-add-event .container-add-event-article .container-title .options .opt-addUp:hover .icon,
.wrapper-add-event .container-add-event-article .container-title .options .opt-rmv:hover .icon,
.wrapper-add-event .container-add-event-article .container-title .options .opt-info:hover .icon{

    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
}



.wrapper-add-event .container-add-event-main .main-row{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}

.wrapper-add-event .container-add-event-main .main-row .input-calendar{
    position: relative;
    height: 100%;
    width: 30%;
    margin-right:7.5%;

}

.wrapper-add-event .container-add-event-main .main-row .input-calendar .input-calendar-datepicker{
    position: relative;
    width: 100%;
    height: 100%;
    margin:0 !important;
    line-height: 0 !important;
}

.wrapper-add-event .container-add-event-main .main-row .input-calendar .input-calendar-datepicker .MuiInputBase-root{
    font-size: 0.9vmax;
    line-height: 0;
}

.wrapper-add-event .container-add-event-main .main-row .input-calendar .input-calendar-datepicker .MuiSvgIcon-root{
    font-size: 1vmax;
}

.wrapper-add-event .container-add-event-main .main-row .input-calendar .input-calendar-datepicker>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>* {
    user-select: none !important;
    outline: none !important;
}
.wrapper-add-event .container-add-event-main .main-row .input-calendar .input-calendar-datepicker>*>*>*:hover {
    background-color: transparent;
    transform: scale(1.1);
}



.wrapper-add-event .container-add-event-main .main-row .input-tag{
    position: relative;
    width: 25%;
}

.wrapper-add-event .container-add-event-main .main-row .input-tag .input-tag-select{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    font-size: 0.9vmax;;
    height: 1.8vmax;

}
.wrapper-add-event .container-add-event-main .main-row .input-tag .input-tag-select >*{
    outline: none;
    
}


.wrapper-add-event .container-add-event-main .main-row .input-importance{
    position: relative;
    width: 20%;
    height: 100%;
}

.wrapper-add-event .container-add-event-main .main-row .input-importance .input-importance-number{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;

}

.wrapper-add-event .container-add-event-main .main-row .input-title{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-add-event .container-add-event-main .main-row .input-title .input-title-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    font-size: 0.9vmax;;
    height: 1.8vmax;
}

.wrapper-add-event .container-add-event-main .main-row .input-description{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;

}

.wrapper-add-event .container-add-event-main .main-row .input-description .input-description-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    font-size: 0.9vmax;;
    height: 1.8vmax;
}



.wrapper-add-event .container-add-event-main .main-row .input-importance .label,
.wrapper-add-event .container-add-event-main .main-row .input-tag .label,
.wrapper-add-event .container-add-event-main .main-row .input-calendar .label,
.wrapper-add-event .container-add-event-main .main-row .input-title .label,
.wrapper-add-event .container-add-event-main .main-row .input-description .label,
.wrapper-add-event .container-add-event-article .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}




.wrapper-add-event .container-add-event-main .add-event-image{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

    margin-bottom: 2vmax;;;;;

}


.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview {
    position: relative;
    width: 32%;
    height: 16vmax;
}
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image {
    width: 100%;
    height: 100%;
}
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options{
    position: absolute;
    right:0;
    top: 0;
    height: 100%;
    padding:0.5vmax;
}


.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-change-fab,
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-delete-fab {
    position: relative;
    display: block;
    border-radius: 50%;
    outline: none;
    user-select: none;
    border: none;
    padding: 0;
    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);
    background-color: white;
    width: 2.2vmax;
    height: 2.2vmax;
  }

  .wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-delete-fab{
    margin-top: 0.5vmax;
  }

  .wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-hidden-fab{
    display: none;
}

  
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-change-fab .change {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/camera.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }

  .wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-delete-fab .delete {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/delete.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }
  
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-change-fab:hover .change,
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-preview .image-options .image-delete-fab:hover .delete {
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
  }
  

.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-content {
    position: relative;
    width: 68%;
    text-align: left;
}
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-content span {
    display: block;
    font-size: 1.5vmax;
    margin-left: 5%;
}
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-content .text-one {
    font-size: 1.5vmax;
}
.wrapper-add-event .container-add-event-main .add-event-image .add-event-image-content .text-two {
    font-size: 0.9vmax;
    padding-left: 1vmax;
}





.wrapper-add-event .container-add-event-article{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
}


.wrapper-add-event .container-add-event-article .add-event-article-text,
.wrapper-add-event .container-add-event-article .add-event-article-label{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    width: 90%;
    height: 100%;
}

.wrapper-add-event .container-add-event-article .add-event-article-text .add-event-article-text-input,
.wrapper-add-event .container-add-event-article .add-event-article-label .side-label{
    position: relative;
    width: 50%;
}

.wrapper-add-event .container-add-event-article .add-event-article-label .side-label span{
    font-size: 1vmax;;
    background-color: rgb(0, 0, 0);
    color:white;
    display: block;
    width: 100%;
    padding-left: 1vmax;
    text-transform: uppercase;
}


.wrapper-add-event .container-add-event-article .add-event-article-text .add-event-article-text-input .article-text{
    position: relative;
    width: 100%;
    outline: none;
    border: none;
    border-left: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 100%;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
    font-size: 0.9vmax;;

}


.wrapper-add-event .container-add-event-article .add-event-article-text .add-event-article-text-preview{
    position: relative;
    width: 50%;
    height: 100%;

}


.wrapper-add-event .container-add-event-article .add-event-article-text .add-event-article-text-preview .article-preview{
    position: relative;
    display: block;
    box-shadow: 0 0 20px #00000048;
    width: 100%;
    height: 100%;
    word-break: break-all;
    border-right: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 10vmax;

    padding: 0.2vmax;
    padding-left: 0.5vmax;
    font-size: 0.9vmax;;

}




.wrapper-add-event .container-add-event-article .add-event-article-gallery,
.wrapper-add-event .container-add-event-attach .add-event-attach{
    position: relative;
    width: 90%;
    margin-top: 1vmax;
    min-height: 8vmax;
    height: 100%;
    border: 0.1px solid rgba(211, 211, 211, 0.5);
    user-select: none;
    padding: 1vmax;
}

.wrapper-add-event .container-add-event-article .add-event-article-gallery .absolute-message,
.wrapper-add-event .container-add-event-attach .add-event-attach .absolute-message{
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    color: grey;
    font-size: 2vmax;
}

.wrapper-add-event .container-add-event-article .droppin,
.wrapper-add-event .container-add-event-attach .droppin {
    border: 0.1px solid rgba(69, 109, 219, 0.5);
}


.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object {
    position: relative;
    width: 100%;
    color: black;
    font-size: 1vmax;

    margin: 0.5vmax 0;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    box-shadow: 0 0 10px #00000048;

}


.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-main{
    width: 80%;
    margin-right: 5%;
    word-wrap: break-word;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;


}
.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main:hover,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-main:hover {
    text-decoration: underline;
}



.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-preview{
    position: relative;

    width: 18%;
    overflow: hidden;
    padding: 1vmax;
    
}
.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-preview img{
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin:auto;

}


.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-text{
    width: 77%;
    margin-left: 5%;
    padding-top: 0.2vmax;
}

.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-text span{
    display: block;
    font-size: 0.9vmax;
    margin-bottom: 0.5vmax;
}



.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-text .text-legend{
    width: 100%;
    outline: none;
    height: 3.5vmax;
    font-size: 0.8vmax;
    padding: 0.4vmax;
}


.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-menu,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-menu {
    position: relative;
    width: 15%;
    height: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;    

    justify-content: flex-end;
    padding: 0.4vmax;

}

.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-menu .article-gallery-object-menu-option,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-menu .event-attach-object-menu-option {
    position: relative;
    width: 40%;
    height: 100%;
    text-align: center;

}

.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-menu .article-gallery-object-menu-option .kxicon,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-menu .event-attach-object-menu-option .kxicon{
    cursor: pointer;
    background-image: url("../../../../assets/figma/delete.svg");
    position: relative;
    background-size: 1.8vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}

.wrapper-add-event .container-add-event-article .add-event-article-gallery .article-gallery-object .article-gallery-object-menu .article-gallery-object-menu-option .kxicon:hover,
.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-menu .event-attach-object-menu-option .kxicon:hover {
    background-size: 2vmax;
}



.wrapper-add-event .container-add-event-attach{
    position: relative;
    width: 100%;
    height: 100%;
}

.wrapper-add-event .container-add-event-attach .add-event-attach .event-attach-object .event-attach-object-main .attach-name{
    width: 100%;
    padding: 0.5vmax;
}
































.wrapper-add-event .container-form-bottom{
    position: relative;
    width: 100%;
    height: 100%;

    margin-top: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

}
.wrapper-add-event .container-form-bottom .submit{
    position: relative;
    width: 50%;
    border: none;

}

.wrapper-add-event .container-form-bottom .submit .submit-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    -moz-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    box-shadow:0 0 2px rgba(0, 0, 0, 0.411);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-add-event .container-form-bottom .submit .submit-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}



.wrapper-add-event .container-form-bottom .submit .submit-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}








.wrapper-add-event .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-event .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-event .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


