.wrapper-projectback{
    position: relative;
    width: 100%;
    min-height: 100vh;

    background-image: url("../../assets/homepage/2.jpg");

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top:2.5vmax;
    padding-bottom:4vmax;
}


.wrapper-projectback .container-header {
    position: relative;
    width: 68%;
    margin: 0 auto;
    padding-top: 5vh;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;


}

.wrapper-projectback .container-header .content{
    width: 50%;
    font-size: 1.4vmax;
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    border-bottom: solid 1px rgba(0, 0, 0, 0.466);
    padding-bottom: 0.5vmax;
}

.wrapper-projectback .container-header .content span{
    padding-left: 1.5%;
    text-transform: uppercase;
    color: black;
    background-color: #efe9cc;
    padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}


.wrapper-projectback .container-menu{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;


    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    width: 70%;
    margin: 2vmax auto;

}

.wrapper-projectback .container-menu > *{
    position: relative;
    height: 2vmax;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;


    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(238, 235, 235, 0.8);

    border:none;
    user-select: none;
    outline: none;
}

.wrapper-projectback .container-menu .previous{
    position: relative;
    width: 45%;
    margin-right: 10%;
}

.wrapper-projectback .container-menu .next{
    position: relative;
    width: 45%;

}


.wrapper-projectback .container-menu  > *:hover {
    background-color: rgba(247, 245, 245, 0.8);
}

.wrapper-projectback .container-menu > * span{
    display: block;
    width: 100%;
    font-size: 1vmax;
    text-align: center;

}




.wrapper-projectback .container-carousel{
    position: relative;
    width: 85%;
    margin: 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;


    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-top: 2vmax;


}



.wrapper-projectback .container-carousel .left{
    position: relative;
    width: 5%;
}


.wrapper-projectback .container-carousel .left .icon{
    position: absolute;
    top:50%;
    left:0;

    transform: translateX(-50%,0);

    background-image: url("../../assets/figma/arrow_leftW.svg");
    background-size: 3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 3vmax;
    width: 3vmax;

    cursor: pointer;

}


.wrapper-projectback .container-carousel .right{
    position: relative;
    width: 5%;
}

.wrapper-projectback .container-carousel .right  .icon{
    position: absolute;
    top:50%;
    right:0;

    transform: translateX(-50%,0);

    background-image: url("../../assets/figma/arrow_rightW.svg");
    background-size: 3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 3vmax;
    width: 3vmax;

    cursor: pointer;

}


.wrapper-projectback .container-carousel .slides{
    position: relative;
    width: 90%;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;


    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    overflow: hidden;
    background-color: rgba(247, 248, 241, 0.616);
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.774);;



}

.wrapper-projectback .container-carousel .slides .slide{
    position: relative;
    min-width: 100%;
    font-size: 1.2vmax;
    transition: transform 0.6s ease-in-out;
    width: 100%;
    padding: 3vmax 5vmax;
    color:black;
    font-family: 'Roboto', sans-serif;
    font-weight:300;
    text-align: justify;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: center;
    align-content: center;


}

.wrapper-projectback .container-carousel .slides .slide::after{
    position: absolute;
    content: '';
    top:0;
    left:0;
    width: 100%;
    height: 100%;;

}

.wrapper-projectback .container-carousel .slides .slide:last-child{
    margin:0;
}

.wrapper-projectback .container-carousel .slides .slide > *{
    display: blocK;


}

.wrapper-projectback .container-carousel .slides .slide .title{
    font-weight: bold;
    font-size: 2vmax;
    text-transform: uppercase;
}

