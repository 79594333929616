.wrapper-calendar-header {
    position: relative;
    width: 100%;
    user-select: none;

}

.wrapper-calendar-header .container-calendar-header-menu {
    display: table;
    table-layout: auto;
    width: 100%;
    padding: 2vmax 0;
    border-right: 1px solid #e4ce8e;
    border-top: 1px solid #e4ce8e;
    background-color: #ddd5be1c;
    font-size: 1.2vmax;

}

.wrapper-calendar-header .container-calendar-header-menu>* {
    display: table-cell;
    vertical-align: top;
}

.wrapper-calendar-header .container-calendar-header-menu > * > .move-years{
    transform: scale(1.5);
    margin: 0 1.2vmax;
}
.wrapper-calendar-header .container-calendar-header-menu > * > *{
    cursor: pointer;
}



.wrapper-calendar-header .container-calendar-header-menu .container-calendar-header-menu-left,
.wrapper-calendar-header .container-calendar-header-menu .container-calendar-header-menu-right {
    width: 20%;
}

.wrapper-calendar-header .container-calendar-header-menu .container-calendar-header-menu-center {
    width: 60%;
}

.wrapper-calendar-header .container-calendar-header-menu .container-calendar-header-menu-right {
    text-align: center;
}

.wrapper-calendar-header .container-calendar-header-menu .container-calendar-header-menu-left {
    text-align: center;
}

.wrapper-calendar-header .container-calendar-header-menu .container-calendar-header-menu-center {
    text-align: center;
}


.wrapper-calendar-header .container-calendar-header-week {
    margin: 0;
    padding: 1.5vmax 0;
    /*border: 1px solid lightgray;*/
    border-right: 1px solid #e4ce8e;
    border-bottom: 1px solid #e4ce8e;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

}

.wrapper-calendar-header .container-calendar-header-cell {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
    text-align: center;
    font-weight: bold;
    color: rgba(122, 122, 122, 0.829);
    text-transform: uppercase;
}
