/*
:root {
  --navMain-bg-color: radial-gradient( circle, transparent 5%, black 120%), rgba(81, 83, 48, 0.589);
  --navMain-position: absolute;
  --navMain-border: none;
  --navMain-global-color: transparent;
  --navMain-shadow: rgba(0, 0, 0, 0.8) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
}
*/

:root{
  --navMain-real-height: 8.85vmax
}

.header-wrapper {
  position: var(--navMain-position);
  width: 100%;
  z-index: 1000;
  user-select: none;
  /*background: var(--navMain-bg-color);*/
  background: var(--navMain-bg-color);
  /*border: var(--navMain-border);*/
  padding: 0 !important;

  -webkit-box-shadow: var(--navMain-shadow);
  -moz-box-shadow: var(--navMain-shadow);
  -o-box-shadow: var(--navMain-shadow);
  box-shadow: var(--navMain-shadow);


}

.header-wrapper .nav-wrapper {
  position: relative;
  width: 100%;
  height: var(--navMain-real-height);

}


.header-wrapper .nav-wrapper .nav-main {
  position: relative;
  width: 100%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0 !important;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  /*background-image: linear-gradient(rgba(0, 0, 0, 0.048) 10%, rgba(179, 155, 155, 0.157) 15%, rgba(246, 246, 246, 0.267) 30%),linear-gradient(to right, rgba(240, 236, 236, 0.082), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15));*/

  user-select: none;
  color: white;

}

.header-wrapper .nav-wrapper .nav-main .left-container {
  position: relative;
  width: 25%;

}

.header-wrapper .nav-wrapper .nav-main .left-container .left-menu {
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  padding:0.5vmax;

}

.header-wrapper .nav-wrapper .nav-main .left-container .left-menu .menu-icon{
  cursor: pointer;
  background-image: url("../../assets/figma/menu-white.svg");
  background-size: 1.6vmax;
  background-position: center;
  background-repeat: no-repeat;

  height: 1.6vmax;
  width: 1.6vmax;
}



.header-wrapper .nav-wrapper .nav-main .center-container {
  position: relative;
  width: 50%;
  margin: auto;

}

.header-wrapper .nav-wrapper .nav-main .center-container .center-drawer {
  position: relative;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;



  vertical-align: top;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.header-wrapper .nav-wrapper .nav-main .center-container .center-drawer .logo-wrapper {
  position: relative;
  width: 100%;
  height: 7vmax;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.header-wrapper .nav-wrapper .nav-main .center-container .center-drawer::after{
  box-shadow: inset 0 0 1000px rgb(255, 255, 255);
}

.header-wrapper .nav-wrapper .nav-main .right-container {
  position: relative;
  width: 25%;

}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex {
  position: relative;
  width: 100%;
  height: 100%;
  

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;

}


.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .lang-block {
  position: relative;
  width: 20%;
  height: 100%;
  margin-right: 5%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;
  /*justify-content: flex-end;*/


}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .lang-block .lang-block-avatar {
  position: relative;

  background-image: var(--language-img);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  background-color: transparent;
  outline: none;
  user-select: none;
  border-radius: 50%;
  box-shadow: 0 0 2px rgb(0, 0, 0);
  border:none;    
  width: 1.4vmax;
  height: 1.4vmax;
  min-width: 0.8em;
  min-height: 0.8em;
  cursor: pointer;
}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .user-block {
  position: relative;
  width: 30%;
  height: 100%;


  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;
}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .user-block .user-block-avatar {
  position: relative;
  overflow: hidden;

  cursor: pointer;
  width: 2.5vmax;
  height: 2.5vmax;
  min-width: 3vmax;
  min-height: 3vmax;
  outline: none;
  user-select: none;
  border-radius: 50%;
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.418);
  border: none;
  padding: 0;
  background-color: transparent;
}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .user-block .user-block-avatar img{
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  vertical-align: baseline   
}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block {
  position: relative;
  width: 60%;
  height: 100%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;

  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: red;
}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-in {
  position: relative;
  width: 70%;
  font-size: 0.8vmax;
  border:none;
  box-shadow: 0 0 1px 1px rgba(69, 70, 65, 0.63);
  background-color: rgba(196, 197, 174, 0.247);
  outline: none;
  color: white;
  padding: 0.2vmax 0;
  margin-bottom: 0.6vmax;
}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-in:hover{
  background-color: rgba(145, 145, 144, 0.6);

}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-up {
  position: relative;
  width: 100%;
  line-height: 0;

}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-up >* {
  display: inline-block;

}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-up .text1 {
  color: rgb(92, 201, 209);
  font-size: 0.65vmax;
  margin-right: 2%;

}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-up .text2 {
  cursor: pointer;
  color: white;
  font-size: 0.7vmax;

}

.header-wrapper .nav-wrapper .nav-main .right-container .right-flex .visitor-block .sign-up .text2:hover {
  color: rgba(159, 163, 105, 0.6);
}

.header-wrapper .nav-wrapper .nav-sub {
  position: relative;
  width: 100%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  user-select: none;
  /*background:radial-gradient( circle, rgba(187, 187, 28, 0.1) 70%, rgba(0, 0, 0, 0.884) 170%), rgba(206, 206, 142, 0.123);*/
  /*background:radial-gradient( circle, rgba(221, 221, 186, 0.5) 50%, rgba(0, 0, 0, 0.884) 170%), rgba(212, 212, 206, 0.158);*/

  box-shadow: 0 0 2px 0px var(--navMain-sub-shadow);
  background: var(--navMain-sub-bg);
}


.header-wrapper .nav-wrapper .nav-sub .nav-sub-tab {
  position: relative;
  min-width: 17%;
  min-height: 1.8vmax;
  margin:0 0.05%;
  background: radial-gradient( circle, transparent 80%, rgba(0, 0, 0, 0.055) 150%), rgba(98, 99, 81, 0.041);
  cursor: pointer;
}

.header-wrapper .nav-wrapper .nav-sub .nav-sub-tab .info{
  position: absolute;
  font-size: 0.95vmax;;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  text-transform: uppercase;
  /*font-weight: 500;*/
  color: black;
}

.header-wrapper .nav-wrapper .nav-sub .nav-sub-tab:hover .info{
  color: white;
}

.header-wrapper .nav-wrapper .nav-sub .nav-sub-tab:hover{
  background: radial-gradient( circle, transparent 80%, rgba(0, 0, 0, 0.055) 150%), rgba(98, 99, 81, 0.09);
}




.language-popper{
  position: absolute;
  display:none;
}
.language-popper-open{
  display: blocK;
}
.language-popper .lang-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-width: 15vmax;
  min-height: 2vmax;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  background-color: rgba(201, 201, 201, 0.418);
  align-items: center;
  border-radius: 10px;
}

.language-popper .lang-wrapper .lang-left,
.language-popper .lang-wrapper .lang-right{
  position: relative;
  width: 5%;
  cursor: pointer;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

}

.language-popper .lang-wrapper .lang-left{
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.language-popper .lang-wrapper .lang-right{
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}




.language-popper .lang-wrapper .lang-left:hover,
.language-popper .lang-wrapper .lang-right:hover{
  background-color: rgba(31, 31, 31, 0.151);
}


.language-popper .lang-wrapper .lang-left .arrow-left{
  background-image: url("../../assets/figma/arrow_left.svg");
  background-size: 1vmax;
  background-position: center;
  background-repeat: no-repeat;
  height: 2vmax;
  width: 100%;
}

.language-popper .lang-wrapper .lang-right .arrow-right{
  background-image: url("../../assets/figma/arrow_right.svg");
  background-size: 1vmax;
  background-position: center;
  background-repeat: no-repeat;
  height: 2vmax;

  width: 100%;
}



.language-popper .lang-wrapper .lang-center{
  position: relative;
  width: 90%;
  height: 100%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  
  align-items: center;
  justify-content: center;
}

.language-popper .lang-wrapper .lang-center .lang-option {
  position: relative;
  width: calc(90%/5);
  height: 100%;


  background-color: red;
}

.language-popper .lang-wrapper .lang-center .lang-option:nth-child(even){
  margin: 0 calc(10%/4);
}

.language-popper .lang-wrapper .lang-option .lang-option-button {
  position: absolute;
  width: 1.2vmax;
  height: 1.2vmax;
  min-width: 12px;
  min-height: 12px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  background-color: transparent;
  outline: none;
  user-select: none;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 2px rgb(0, 0, 0);
  cursor: pointer;

  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  
}


.profile-popper{
  position: absolute;
  display: none;
}

.profile-popper-open{
  display: block;
}

.profile-popper .profile-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: rgba(233, 233, 233, 0.918);
  border-radius: 5%;

}


.profile-popper .profile-wrapper .profile-container {
  position: relative;
  width: 13vmax;
  height: 100%;

}

.profile-popper .profile-wrapper .profile-container .profile-block-image {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1vmax 0;
}

.profile-popper .profile-wrapper .profile-container .profile-block-image .profile-image {
  position: relative;
  width: 6vmax;
  height: 6vmax;
  border-radius: 50%;

  overflow: hidden;
  margin: 0 auto;

  border-radius: 50%;
  background-color: rgba(92, 86, 86, 0.158);
}

.profile-popper .profile-wrapper .profile-container .profile-block-image .profile-image img{
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
}

.profile-popper .profile-wrapper .profile-container .profile-block-image:hover .profile-image img {
  transform: scale(1.1);
}

.profile-popper .profile-wrapper .profile-container .profile-block-image .image-change {
  position: absolute;
  width: 60%;
  left:20%;
  z-index: 1100;
  bottom: 5%;
}

.profile-popper .profile-wrapper .profile-container .profile-block-image .image-change .image-change-fab {
  position: relative;
  border-radius: 50%;
  left:60%;
  outline: none;
  user-select: none;
  border: none;
  padding: 0;
  -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
  -moz-box-shadow:0 0 4px rgb(0, 0, 0);
  box-shadow:0 0 4px rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.815);
  width: 1.8vmax;
  height: 1.8vmax;
}


.profile-popper .profile-wrapper .profile-container .profile-block-image .image-change .image-change-fab .camera-icon {
  /*background-color: rgba(134, 134, 134, 0.103);*/
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;

  background: url("../../assets/figma/camera.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.2vmax;

}



.profile-popper .profile-wrapper .profile-container .profile-block-image .image-change .image-change-fab:hover .camera-icon  {
  filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
}





.profile-popper .profile-wrapper .profile-container .profile-block-text {
  position: relative;
  width: 100%;
  height: 100%;
  color: black;
  word-wrap: break-word;
  text-align: center;
  margin-top: 1vmax;
  margin-bottom: 2vmax;;

}
.profile-popper .profile-wrapper .profile-container .profile-block-text .profile-user {
  display: block;
  font-size: 1vmax;
}

.profile-popper .profile-wrapper .profile-container .profile-block-divider {
  position: relative;
  height: 0.01vmax;
  width: 100%;
  background-color: rgba(58, 58, 58, 0.445);
  margin: 0;
  padding: 0;
  border: 0;
}

.profile-popper .profile-wrapper .profile-container .profile-block-menu {
  position: relative;
  width: 100%;
  height: 100%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;


}

.profile-popper .profile-wrapper .profile-container .profile-block-menu .menu-button{
  font-size: 0.8vmax;
  letter-spacing: normal;
  /*text-transform: capitalize;*/
  width: 50%;
  height: 100%;
  padding: 0.7vmax;

  user-select: none;
  outline: none;
  border: none;
  background-color: transparent;
}

.profile-popper .profile-wrapper .profile-container .profile-block-menu .menu-button:hover{
  background-color: rgba(92, 86, 86, 0.158);
}


@media only screen and (max-width: 1000px) {


}