.wrapper-list-event{
    position: relative;
    width: 100%;
    user-select: none;
    padding-bottom: 5vmax;

}


.wrapper-list-event .container-list-event{
    position: relative;
    width: 100%;
    min-height: 50vh;
    user-select: none;
}


.wrapper-list-event .container-list-event .container-event-flex{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    width: 100%;
    min-height: 4vmax;
    margin: 0 auto;

}

.wrapper-list-event .container-list-event .container-event-flex .event-object{
    position: relative;
    width: calc(80%/4);
    margin: 0 calc(20%/8) 4vmax calc(20%/8) ;
    min-height: 27vmax;
    cursor: pointer;
    box-shadow: 0px 0px 3px 3px rgba(109, 109, 109, 0.349);
    /*    border: 0.1px solid rgba(211, 211, 211, 0.5);*/
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0.3vmax;;
    padding: 0.05vmax;

}

.wrapper-list-event .container-list-event .container-event-flex .event-object:hover{
    transform: scale(1.01);
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-img{
    width: 100%;
    height: 15vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-radius: 0.3vmax;;

    align-items: center;
    justify-content: center;
    
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-img img{
    width: 100%;
    height: 100%;
    border-radius: 0.3vmax;;

    display: block;
    margin: 0 auto;
    
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content {
    font-family: 'Roboto', sans-serif;
    color: white;
    position: absolute;
    z-index: 1000;
    top: 15vmax;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 1.2px #00000048;
    height: 10vmax;
    /*border-bottom: 0.5px solid rgba(0, 0, 0, 0.329);*/
}


.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content::before {
    position: absolute;
    width: 95%;
    margin-left: 2.5%;
    left: 0;
    height: 6vmax;
    top: 1vmax;
    content: "";
    z-index: -1;
    background:
        linear-gradient(#4e4e4e25,
            #4e4e4e1e);
    filter: blur(20px);
    -moz-filter: blur(20px);
    -webkit-filter: blur(20px);
    -o-filter: blur(20px);

}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content > *{
    display: block;
    margin-top: 1.2vmax;
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content .event-object-title{
    text-shadow: 0.3px 0.3px rgba(0, 0, 0, 0.479);
    font-size: 1.1vmax;
    color: #4e4e4e;
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content .event-object-date{
    display: block;
    margin-top:0vmax;
    font-size: 0.65vmax;
    color: #c36a2d;
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content .event-object-date > *{
    display: inline;
}

.wrapper-list-event .container-list-event .container-event-flex .event-object .event-object-content .event-object-date .event-object-dot{
    margin: 0 0.2vmax;
}

.wrapper-list-event  .container-event-footer{
    position: relative;
    width:100%;
    min-height: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;


    background-color: rgba(218, 219, 194, 0.747);
}

.wrapper-list-event  .container-event-footer .event-footer-info{
    width: 60%;
    height: 100%;
}

.wrapper-list-event  .container-event-footer .event-footer-options {
    width: 40%;
    height: 100%;
    user-select: none;

}


.wrapper-list-event  .container-event-footer .event-footer-options > * {
    font-size: 1.7vmax;
    cursor: pointer;
    display: inline-block;

}

.wrapper-list-event  .container-event-footer .event-footer-options .go-left{
    margin-left: 45%;
    margin-right: 0.5%;

}

.wrapper-list-event  .container-event-footer .event-footer-options .go-right{
    margin-left: 0.5%;
}

.wrapper-list-event  .container-event-footer .event-footer-options .go-pages {
    margin: 1.5vmax 0.5% 0 0.5%;
    font-size: 1vmax;

}

.wrapper-list-event  .container-event-footer .event-footer-options .go-pages:hover{
    text-decoration: underline;
    color: blue;
    
}

.wrapper-list-event  .container-event-footer .event-footer-options .selected-go-pages{
    font-weight: bold;
    color: black;
    text-decoration: underline;

}
.wrapper-list-event  .container-event-footer .event-footer-options .selected-go-pages:hover{
    text-decoration: underline;
    color: black;

}

.wrapper-list-event .container-list-event .container-event-nocontent{
    position: relative;
    padding: 1vmax 0;
    box-shadow: 0 0 3px 5px #9c9c9cad;
    text-align: center;
    font-size: 1.1vmax;
    background-color: rgba(255, 255, 255, 0.459);
    font-family: "Montserrat", Arial, sans-serif;

}

