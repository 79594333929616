.wrapper-add-publication{
    position: relative;
    width: 100%;
}


.wrapper-add-publication .container-add-publication-main{
    position: relative;
    width: 100%;
    height: 100%;
}




.wrapper-add-publication .container-add-publication-main .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-publication .container-add-publication-main .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-add-publication .container-add-publication-main .main-row{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}




.wrapper-add-publication .container-add-publication-main .main-row .input-calendar{
    position: relative;
    height: 100%;
    width: 30%;
    margin-right:7.5%;

}

.wrapper-add-publication .container-add-publication-main .main-row .input-calendar .input-calendar-datepicker{
    position: relative;
    width: 100%;
    height: 100%;
    margin:0 !important;
    line-height: 0 !important;
}

.wrapper-add-publication .container-add-publication-main .main-row .input-calendar .input-calendar-datepicker .MuiInputBase-root{
    font-size: 0.9vmax;
    line-height: 0;
}

.wrapper-add-publication .container-add-publication-main .main-row .input-calendar .input-calendar-datepicker .MuiSvgIcon-root{
    font-size: 1vmax;
}

.wrapper-add-publication .container-add-publication-main .main-row .input-calendar .input-calendar-datepicker>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>* {
    user-select: none !important;
    outline: none !important;
}
.wrapper-add-publication .container-add-publication-main .main-row .input-calendar .input-calendar-datepicker>*>*>*:hover {
    background-color: transparent;
    transform: scale(1.1);
}




.wrapper-add-publication .container-add-publication-main .main-row .input-title,
.wrapper-add-publication .container-add-publication-main .main-row .input-text1,
.wrapper-add-publication .container-add-publication-main .main-row .input-text2,
.wrapper-add-publication .container-add-publication-main .main-row .input-url{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-add-publication .container-add-publication-main .main-row .input-title .input-title-text,
.wrapper-add-publication .container-add-publication-main .main-row .input-text1 .input-text1-text,
.wrapper-add-publication .container-add-publication-main .main-row .input-text2 .input-text2-text,
.wrapper-add-publication .container-add-publication-main .main-row .input-url .input-url-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    font-size: 0.9vmax;;
}

.wrapper-add-publication .container-add-publication-main .main-row .input-title .input-title-text,
.wrapper-add-publication .container-add-publication-main .main-row .input-url .input-url-text{
    height: 1.8vmax;
}
.wrapper-add-publication .container-add-publication-main .main-row .input-text1 .input-text1-text,
.wrapper-add-publication .container-add-publication-main .main-row .input-text2 .input-text2-text{
    height: 3.5vmax;

}

.wrapper-add-publication .container-add-publication-main .main-row .input-title .label,
.wrapper-add-publication .container-add-publication-main .main-row .input-text1 .label,
.wrapper-add-publication .container-add-publication-main .main-row .input-text2 .label,
.wrapper-add-publication .container-add-publication-main .main-row .input-url .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}




.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}





.wrapper-add-publication .container-add-publication-content{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
    margin-top: 1vmax;

}

.wrapper-add-publication .container-add-publication-content .container-title{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    margin-right: 5%;

}


.wrapper-add-publication .container-add-publication-content .container-title span{
    position: relative;
    margin-right: 3%;
    color:black;
    font-size: 1vmax;;
    font-weight: bold;

}




.wrapper-add-publication .container-add-publication-content .add-publication-content-text{
    position: relative;
    width: 90%;
    height: 100%;
}

.wrapper-add-publication .container-add-publication-content .add-publication-content-text .add-publication-content-text-input{
    position: relative;
    width: 100%;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
    min-height: 2vmax;
}

.wrapper-add-publication .container-add-publication-content .add-publication-content-text .add-publication-content-text-input > * {
    word-wrap: break-word;
}

.wrapper-add-publication .container-add-publication-content .add-publication-content-text .add-publication-content-text-input .content-text-text1{
}
.wrapper-add-publication .container-add-publication-content .add-publication-content-text .add-publication-content-text-input .content-text-title{
    font-weight: bold;
}
.wrapper-add-publication .container-add-publication-content .add-publication-content-text .add-publication-content-text-input .content-text-text2{
}







.wrapper-add-publication .container-add-publication-main .main-row .input-calendar .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}













.wrapper-add-publication .container-form-bottom{
    position: relative;
    width: 100%;
    height: 100%;

    margin-top: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

}
.wrapper-add-publication .container-form-bottom .submit{
    position: relative;
    width: 50%;
    border: none;

}

.wrapper-add-publication .container-form-bottom .submit .submit-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    -moz-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    box-shadow:0 0 2px rgba(0, 0, 0, 0.411);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-add-publication .container-form-bottom .submit .submit-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}



.wrapper-add-publication .container-form-bottom .submit .submit-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
    
}

.wrapper-add-publication .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-publication .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-publication .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


