

.wrapper-goddess {
    position: relative;
    width: 100%;
    user-select: none;
    margin: 8vmax 0;
    min-height: 30vh;
}

.wrapper-goddess .container-goddess {
    position: relative;
    width: 80%;
    margin: 0 auto;
}

.wrapper-goddess .container-goddess .container-goddess-title {
    position: relative;
    font-size: 4vmin;


}

.wrapper-goddess .container-goddess .container-goddess-title span{
    text-transform: uppercase;
    font-family: "Montserrat";
    color:#c4a981;
    padding: 0.2%;

}



.wrapper-goddess .container-goddess .container-goddess-flex{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    font-family: "Montserrat";

}


.wrapper-goddess .container-goddess .container-goddess-flex .goddess-story{
    position: relative;
    width: 50%;
    padding: 4vmax 2vmax 0 0;

}
.wrapper-goddess .container-goddess .container-goddess-flex .goddess-story > * {
    display: block;
    text-align: left;
}

.wrapper-goddess .container-goddess .container-goddess-flex .goddess-story .goddess-story-god{
    font-size: 2.5vmin;
    padding-bottom: 1vmax;
    font-weight: bold;
}

.wrapper-goddess .container-goddess .container-goddess-flex .goddess-story .goddess-story-description{
    font-size: 2vmin;
    text-align: justify;
}



.wrapper-goddess .container-goddess .container-goddess-flex .goddess-image{
    position: relative;
    width: 50%;
    margin: 0 auto;
    min-height: 25vmax;
    background-image: url("../../../assets/goddess/beta3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35vmax;
}

