.wrapper-quizpage{
    position: relative;
    width: 100%;
    /*background: radial-gradient( circle, rgba(255, 255, 255, 0.363) 20%, transparent 50%), rgba(58, 100, 163, 0.685);*/
    min-height: 100vh;
    background-image: url("../../../assets/images/map3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
}

.wrapper-quizpage .container-quizpage {
    position: relative;
    width: 100%;
}

