.wrapper-quiz-end{
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 100vh;
    padding-top: 5vmax;
    padding-bottom: 5vmax;;
    background-image: url("../../../../assets/images/map3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;

}
.wrapper-quiz-end .container-quiz-end{
    position: relative;
    margin: 0 auto;
    background-color: rgba(49, 49, 47, 0.438);
    width: 60%;
    text-transform: uppercase;
    padding: 2vmax;
    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 5px, inset rgba(255, 255, 255, 0.637) 0px 0px 5px 2px;
}

.wrapper-quiz-end .container-quiz-end div{
    position: relative;
    width: 100%;
    font-family: Montserrat, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2vmax;

}

.wrapper-quiz-end .container-quiz-end .msg{
    color: white;
    font-size: 3vmax;
}

.wrapper-quiz-end .container-quiz-end .total span,
.wrapper-quiz-end .container-quiz-end .correct span,
.wrapper-quiz-end .container-quiz-end .wrong span,
.wrapper-quiz-end .container-quiz-end .empty span
{
    display: block;
    text-shadow:0px 0px 8px rgba(80, 75, 75, 0.582);

}


.wrapper-quiz-end .container-quiz-end .total .score{
    color: white;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.wrapper-quiz-end .container-quiz-end .correct .score{
    color: white;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}


.wrapper-quiz-end .container-quiz-end .wrong .score{
    color: white;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.wrapper-quiz-end .container-quiz-end .empty .score{
    color: white;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}




.wrapper-quiz-end .container-quiz-end .total .text{
    color: rgb(19, 17, 17);
}
.wrapper-quiz-end .container-quiz-end .correct .text{
    color: rgb(80, 226, 80);
}
.wrapper-quiz-end .container-quiz-end .wrong .text{
    color: rgb(216, 68, 68);
}
.wrapper-quiz-end .container-quiz-end .empty .text{
    color: rgb(202, 196, 196);
}
.wrapper-quiz-end .container-quiz-end .return .text{
    color: white;

} 




.wrapper-quiz-end .container-quiz-end .total .text,
.wrapper-quiz-end .container-quiz-end .correct .text,
.wrapper-quiz-end .container-quiz-end .wrong .text,
.wrapper-quiz-end .container-quiz-end .empty .text{
    padding-left:1vmax;

}


.wrapper-quiz-end .container-quiz-end .return{
    font-size: 1.5vmax;
    padding: 1vmax;
    margin-top:2vmax;
    background-color: rgba(0, 0, 0, 0.144);
    cursor: pointer;

} 

.wrapper-quiz-end .container-quiz-end .return:hover{
    background-color: rgba(0, 0, 0, 0.185);
}