.wrapper-tabs-calendarEvents{
    position: relative;
    width: 100%;
    background-color: #efe9cc4d;
    margin-bottom: 4vmax;
    padding: 1vmax 5%;
}

.wrapper-tabs-calendarEvents .container-tabs{
    position: relative;
    width: 100%;
    margin: 0 auto;
    /*background-color: rgba(81, 83, 48, 0.3);*/

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;


}

.wrapper-tabs-calendarEvents .container-tabs .tabs-normal{
    position: relative;
    height: 2.5vmax;
    width: 18%;
    font-size: 0.9vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: 0.5px solid #463b0934;
    margin-right: 0.6%;
    font-size: 1vmax;
    color:black;
    font-family: "Montserrat", Arial, sans-serif;

}

.wrapper-tabs-calendarEvents .container-tabs .tabs-normal:last-child{
    margin-right: 0;
}

.wrapper-tabs-calendarEvents .container-tabs .tabs-active{
    background: linear-gradient(to right, #efe9cc4d,  rgba(218, 216, 216, 0.315), #efe9cc4d);
    border: 0.5px solid #aca06431;
    
}

.wrapper-tabs-calendarEvents .container-tabs .tabs-underline{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #7977718a;
    transition: left 500ms, right 500ms;
}