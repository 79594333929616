.wrapper-advertise {
    background-color: rgb(236, 253, 0);
    text-align: center;
    font-weight: bold;
    font-size: 2vmax;
    height: 41.2vmax;
    font-family: "Montserrat", Arial, sans-serif;

}

.wrapper-advertise .container-advertise{
    padding-top: 17vmax;

}

.wrapper-advertise .container-advertise>span {
    display: block;
    font-size: 1.4vmax;
}