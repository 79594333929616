.wrapper-footer {
    position: relative;
    width: 100%;
    user-select: none;
}

.wrapper-footer .container-footer-content {
    position: relative;
    width: 100%;
    min-height: 100%;
    background-color: rgb(12, 12, 12);
    padding: 4vmax 0;

}

.wrapper-footer .container-footer-content .footer-content-flex{
    position: relative;
    width: 80%;
    margin: 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit{
    position: relative;
    margin-bottom: 1vmax;
    width: 43%;
    height: 17.5vmax;
    overflow: hidden;

}


@media only screen and (max-width: 880px) {

    .wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit{
        position: relative;
        margin-bottom: 1vmax;
        width: 43%;

        height: 15.7vmax;
        overflow: hidden;
    }

  }
  @media only screen and (max-width: 780px) {

    .wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit{
        position: relative;
        margin-bottom: 1vmax;
        width: 43%;

        height: 13.5vmax;
        overflow: hidden;
    }

  }
  @media only screen and (max-width: 650px) {

    .wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit{
        position: relative;
        margin-bottom: 1vmax;
        width: 43%;

        height: 11.5vmax;
        overflow: hidden;
    }

  }
  @media only screen and (max-width: 550px) {

    .wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit{
        position: relative;
        margin-bottom: 1vmax;
        width: 43%;

        height: 10vmax;
        overflow: hidden;
    }

  }

  @media only screen and (max-width: 490px) {

    .wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit{
        position: relative;
        margin-bottom: 1vmax;
        width: 43%;

        height: 10vmax;
        overflow: hidden;
    }

  }


.wrapper-footer .container-footer-content .footer-content-flex .footer-content-eit img{
    width: 100%;
    height: 100%;
}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-europe{
    position: relative;

    width: 30vmax;
    max-width: 50%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;



}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-europe .footer-content-europe-img{
    position: relative;
    width: 15%;
    overflow: hidden;
}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-europe .footer-content-europe-img img{
    width: 100%;
    height: 100%;
}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-europe .footer-content-europe-all-img{
    width: 15%;
    overflow: hidden;
}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-europe .footer-content-europe-all-img img{
    width: 100%;
    height: 100%;
}


.wrapper-footer .container-footer-content .footer-content-flex .footer-content-europe .footer-content-europe-text{
    width: 82%;
    font-size: 0.7vmax;
    margin-right: 3%;
    text-align: justify;
    color: white;
    line-height: 1.1;

}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-right {
    position: relative;
    width: 50%;
    color: white;
    font-size: 0.75vmax;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: flex-end
}

.wrapper-footer .container-footer-content .footer-content-flex .footer-content-right span{
    display: block;
}

.wrapper-footer .container-footer-list{
    position: relative;
    width: 90%;
    margin: 4vmax auto 1vmax auto;
}

.wrapper-footer .container-footer-list .footer-list-row{
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.wrapper-footer .container-footer-list .footer-list-row .footer-list-object{
    width: calc(85%/4);
    margin: 0.2vmax calc(15%/8);
}

.wrapper-footer .container-footer-list .footer-list-row .footer-list-object img{
    display: block;
    max-width: 85%;
    max-height: 85%;
    margin: 0 auto 0 auto;
}
