.wrapper-news {
    position: relative;
    width: 100%;


}

.wrapper-news .container-content {
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    /*border-bottom: 2px solid rgba(211, 211, 211, 0.5);*/
    height: 31.5vmax;

}


/*DISPLAY SIDE*/

/*container*/
.wrapper-news .container-content .content-display {
    position: relative;
    width: 70%;
    height:100%;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 1px 6px #a5a5a5c2;
    overflow: hidden;
    background-color: white;
}

/*image*/
.wrapper-news .container-content .content-display .content-display-img {
    position: relative;
    width: 100%;
    height: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.wrapper-news .container-content .content-display:hover .content-display-img .content-display-img-main {
    transform: scale(1.05);
}

.wrapper-news .container-content .content-display .content-display-img .content-display-img-main {
    position: relative;
    display: block;
    margin: auto;
    height: auto;
    max-height: 100%;
    zoom:2;
    width: auto;
    max-width: 100%;

    transition: all .5s;
    /*box-shadow:inset -100px -100px 1000px rgb(0, 0, 0);*/
}

/*text*/
.wrapper-news .container-content .content-display-text {
    font-family: 'Roboto', sans-serif;
    color: white;
    position: absolute;
    top: 65%;
    width: 100%;
    z-index: 100;

}

.wrapper-news .container-content .content-display-text::before {
    position: absolute;
    width: 98%;
    left: 1%;
    height: 6.8vmax;
    top: 3.2vmax;
    content: "";
    z-index: -1;
    background:
        linear-gradient(#4e4e4e46,
        #4e4e4e38);
    filter: blur(15px);
    -moz-filter: blur(15px);
    -webkit-filter: blur(15px);
    -o-filter: blur(15px);
}

.wrapper-news .container-content .content-display-text .content-display-text-type {
    display: block;
    font-size: 1.4vmax;
    /*background-color: #e2c275;*/
    background-color: #c36a2d;

    padding: 0.2vmax 1vmax;
    width: 20%;

    text-align: center;
    color: white;
    margin-left: 4%;
    margin-bottom: 1vmax;
    font-family: "Montserrat", Arial, sans-serif;

}


.wrapper-news .container-content .content-display-text .content-display-text-title {
    display: block;
    font-size: 2vmax;
    padding-left: 4%;
    color: white;
    font-weight: bold;
    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);


}

.wrapper-news .container-content .content-display-text .content-display-text-date {
    display: block;
    font-size: 0.6vmax;
    padding-left: 4.2%;
    margin-top: -0.4vmax;
    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);

}



.wrapper-news .container-content .content-display-text .content-display-text-info {
    display: block;
    font-size: 1vmax;
    padding-left: 4.2%;
    line-height: 1.2;
    margin-top: 0.5vmax;
    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);
}





/*RIGHT-SIDE*/
.wrapper-news .container-content .content-side {
    width: 29%;
    height: 100%;
    margin-left: 1%;
}
.wrapper-news .container-content .content-side .content-side-object {
    height: calc(31vmax/2);
    position: relative;
    width: 100%;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 1px 6px #a5a5a5c2;



}

.wrapper-news .container-content .content-side .content-side-object-last {
    margin-top: 0.5vmax;
}

.wrapper-news .container-content .content-side .content-side-object .content-side-object-media {
    position: relative;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;
}

.wrapper-news .container-content .content-side .content-side-object:hover .content-side-object-media .content-side-object-media-video {
    transform: scale(1.05);
}

.wrapper-news .container-content .content-side .content-side-object .content-side-object-media .content-side-object-media-video {
    position: relative;

    display: block;
    margin: auto;

    height: auto;
    max-height: 100%;

    width: auto;
    max-width: 100%;
    transition: all .5s;
}

.wrapper-news .container-content .content-side .content-side-object .content-side-object-text {
    font-family: 'Roboto', sans-serif;
    color: white;
    position: absolute;
    z-index: 100;
    top: 68%;
    width: 100%;
}

.wrapper-news .container-content .content-side .content-side-object .content-side-object-text::before {
    position: absolute;
    width: 97%;
    left: 1.5%;
    top: 0.5vmax;
    height: 3.5vmax;
    content: "";
    z-index: -1;
    background:
        linear-gradient(#4e4e4e46,
        #4e4e4e38);
    filter: blur(15px);
    -moz-filter: blur(15px);
    -webkit-filter: blur(15px);
    -o-filter: blur(15px);
}



.wrapper-news .container-content .content-side .content-side-object .content-side-object-text .content-side-object-text-type {
    display: block;
    font-family: "Montserrat", Arial, sans-serif;

    font-size: 0.6vmax;
    /*background-color: rgba(109, 84, 84, 0.616);
    */
    background-color: #c36a2d;
    padding: 0.15vmax 0.5vmax;
    width: 20%;
    text-align: center;
    color: white;
    margin-left: 3%;
}

.wrapper-news .container-content .content-side .content-side-object .content-side-object-text .content-side-object-text-title {
    display: block;
    font-size: 0.9vmax;
    padding-left: 3%;
    color: white;
    font-weight: bold;
    text-shadow: 0.5px 0.5px black;
    margin-top: 0.2vmax;
    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);

}
.wrapper-news .container-content .content-side .content-side-object .content-side-object-text .content-side-object-text-info {
    display: block;
    font-size: 0.65vmax;
    padding-left: 3.2%;
    margin-top: 0.4vmax;
    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);

}

.wrapper-news .container-content .content-side .content-side-object .content-side-object-text .content-side-object-text-date {
    display: block;
    font-size: 0.5vmax;
    padding-left: 3.2%;
    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);
}







/*BOTTOM SIDE*/
.wrapper-news .container-list-flex {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    margin-top: 1vmax;

    /*vertical-align: top;*/

}

.wrapper-news .container-list-flex .list-object {
    position: relative;
    width: 100%;
    max-width: calc(100%/3);
    /*margin: 0 calc(4%/6);*/
    height: calc(31vmax/2);
    width: 32.8%;
    /*98.4*/
    margin-right: 0.8%;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 1px 6px #a5a5a5c2;

    /*box-shadow: 0px 0px 5px 5px #4e4e4e;*/
    /*box-shadow: 0 0px 2px 2px #4e4e4eb0;*/
    overflow: hidden;

}

.wrapper-news .container-list-flex .list-object-last {
    margin-right: 0;
}



.wrapper-news .container-list-flex .list-object .list-object-img {
    position: relative;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;

}

.wrapper-news .container-list-flex .list-object:hover .list-object-img .list-object-img-main {
    transform: scale(1.05);
}

.wrapper-news .container-list-flex .list-object .list-object-img .list-object-img-main {
    position: relative;

    display: block;
    margin: auto;

    height: auto;
    max-height: 100%;

    width: auto;
    max-width: 100%;
    transition: all .5s;
}

.wrapper-news .container-list-flex .list-object .list-object-text {
    font-family: 'Roboto', sans-serif;
    color: white;
    position: absolute;
    z-index: 100;
    top: 68%;
    width: 100%;
}

.wrapper-news .container-list-flex .list-object .list-object-text::before {
    position: absolute;
    width: 97%;
    left: 1.5%;
    top: 0.5vmax;
    height: 3.5vmax;
    content: "";
    z-index: -1;
    background:
        linear-gradient(#4e4e4e46,
        #4e4e4e38);
    filter: blur(15px);
    -moz-filter: blur(15px);
    -webkit-filter: blur(15px);
    -o-filter: blur(15px);
}



.wrapper-news .container-list-flex .list-object .list-object-text .list-object-text-type {
    display: block;
    font-family: "Montserrat", Arial, sans-serif;

    font-size: 0.6vmax;
    /*background-color: rgba(109, 84, 84, 0.616);
    */
    background-color: #c36a2d;
    padding: 0.15vmax 0.5vmax;
    width: 20%;
    text-align: center;
    color: white;
    margin-left: 3%;
}

.wrapper-news .container-list-flex .list-object .list-object-text .list-object-text-title {
    display: block;
    font-size: 0.9vmax;
    padding-left: 3%;
    color: white;
    font-weight: bold;
    text-shadow: 0.5px 0.5px black;
    margin-top: 0.2vmax;
}

.wrapper-news .container-list-flex .list-object .list-object-text .list-object-text-info {
    display: block;
    font-size: 0.65vmax;
    padding-left: 3.2%;
    margin-top: 0.4vmax;
}

.wrapper-news .container-list-flex .list-object .list-object-text .list-object-text-date {
    display: block;
    font-size: 0.5vmax;
    padding-left: 3.2%;

    text-shadow:0px 0px 2.2px rgba(0, 0, 0, 0.582);
}