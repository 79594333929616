.wrapper-edit-profile{
    display: none;
    z-index: 5000;
}

.wrapper-edit-profile .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 5001;
}

.edit-profile-modal-open{
    display: block;
}

.edit-profile-modal-open .overlay{
    
}


@keyframes editProfile-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-edit-profile .content{
    position: absolute;
    left:20%;
    top: var(--editProfileModal-topOffset);
    margin-top: 10%;
    background-color: #fff;
    z-index: 5002;
    padding: 1vmax;
    box-sizing: border-box;
    width: 60%;
    display: none;

}

.edit-profile-modal-open .content{
    word-wrap: break-word;
    animation: editProfile-modal-fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-edit-profile .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;


}

.wrapper-edit-profile .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
    padding-left: 2.5%;;
}

.wrapper-edit-profile .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-edit-profile .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
}

.wrapper-edit-profile .content .header .close .close-btn .img{
    position: relative;
    background-image: url("../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;
}

.wrapper-edit-profile .content .header .close .close-btn:hover .img{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-edit-profile .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 2vmax;
    margin-top: 2vmax;
    font-size:0.9vmax;
    min-height: 15vmax;
}

.wrapper-edit-profile .content .body .body-form{
    position: relative;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    text-align: left;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

}


.wrapper-edit-profile .content .body .body-form .left,
.wrapper-edit-profile .content .body .body-form .right{
    position: relative;
    width: 50%;

}

.wrapper-edit-profile .content .body .body-form .left .main-row,
.wrapper-edit-profile .content .body .body-form .right .main-row{
    position: relative;
    width: 85%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0 auto;
    margin-bottom: 0.3vmax;
    min-height: 5.5vmax;
    
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input,
.wrapper-edit-profile .content .body .body-form .right .main-row .input{
    position: relative;
    width: 100%;

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field{
    position: relative;
    width: 100%;
    height: 2vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-icon,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-icon{
    position: relative;
    width: 10%;
    height: 100%;
    position: relative;
    background-image: url("../../../assets/figma/edit.svg");
    background-size: 1vmax;
    background-repeat: no-repeat;
    background-position: center;
    /*
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    */
    /*background-color: rgba(245, 245, 245, 0.788);*/
    cursor: pointer;
    border-radius: 50%;
    /*
    -webkit-box-shadow: inset 0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:inset 0 0 2px rgb(0, 0, 0);
    -o-box-shadow:inset 0 0 2px rgb(0, 0, 0);
    box-shadow: inset 0 0 1px rgb(0, 0, 0);
    */
}

.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-icon:hover,
.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-icon:hover{
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-text,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-text{

    position: relative;
    width: 88%;
    margin-left: 2%;
    height: 2vmax;
    outline: none;
    user-select: none;
    padding-left:0.5vmax;

    border:none;
    border-radius: 0.1vmax;
    padding: 0 3% 0 3%;

    /*
    -webkit-box-shadow:0 0 1.5px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1.5px rgb(27, 27, 27);
    -o-box-shadow:0 0 1.5px rgb(0, 0, 0);
    box-shadow:0 0 1.5px rgb(0, 0, 0);
*/
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .input-text,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field-password .input-text{
    padding: 0 19% 0 3%;

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-user,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-user{
    margin-left: 12%;
}
.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-country,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-country{
    position: relative;
    margin: 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-content: center;
    justify-content: flex-start;
    cursor: default;
    
}


.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-country .opt-flag,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-country .opt-flag{
    position: relative;
    width: 10%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    height: 100%;

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-country .text-country,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-country .text-country{
    position: relative;
    width: 82%;
    height: 100%;
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-country .text-country span,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-country .text-country span{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    padding-left:2%;

}


.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-country .text-ph,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-country .text-ph{
    position: relative;
    width: 91%;
    text-align: left;
    height: 100%;


}

.wrapper-edit-profile .content .body .body-form .main-row  .input .field .input-country .text-ph span,
.wrapper-edit-profile .content .body .body-form .main-row  .input .field .input-country .text-ph span{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    padding-left:1%;

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .input-country .arrow-down,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .input-country .arrow-down{
    position: relative;
    width: 8%;
    background: url("../../../assets/figma/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    height: 100%;
}


.wrapper-edit-profile .content .body .body-form .left .main-row .input .field .text-active,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .field .text-active{

    background-color: rgba(243, 243, 243, 0.671);
    -webkit-box-shadow:0 0 1.5px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1.5px rgb(27, 27, 27);
    -o-box-shadow:0 0 1.5px rgb(0, 0, 0);
    box-shadow:0 0 1.5px rgb(0, 0, 0);
}


.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .show-pass{
    position: absolute;
    top:0;
    right: 8%;
    width: 8%;
    height: 100%;
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .show-pass .showpass-icon
{
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 2vmax;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .confirm{
    position: absolute;
    top:0;
    right: 0%;
    width: 8%;
    height: 100%;
    background-color: rgba(89, 126, 74, 0.24);
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .confirm .confirm-icon
{
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 2vmax;
    position: relative;
  
    background: url("../../../assets/figma/profile/confirmPassword.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .confirm:hover .confirm-icon
{
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .confirm .goback-icon
{
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 2vmax;
    position: relative;
  
    background: url("../../../assets/figma/profile/goBackPassword.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .field-password .confirm:hover .goback-icon
{
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);

}

.wrapper-edit-profile .content .body .body-form .left .main-row .input .label,
.wrapper-edit-profile .content .body .body-form .right .main-row .input .label{

    display: block;
    width: 100%;
    color:rgba(0, 0, 0, 0.568);
    font-size: 0.9vmax;
    margin-bottom: 0.5vmax;
}



.wrapper-edit-profile .content .footer{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

}



.wrapper-edit-profile .content .footer .submit{
    position: relative;
    width: 50%;
}

.wrapper-edit-profile .content .footer .cancel{
    position: relative;
    width: 20%;
    margin-left: 1.5%;

}



.wrapper-edit-profile .content .footer .submit .submit-btn,
.wrapper-edit-profile .content .footer .cancel .cancel-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-edit-profile .content .footer .submit .submit-btn .info,
.wrapper-edit-profile .content .footer .cancel .cancel-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}

.wrapper-edit-profile .content .footer .submit .submit-btn{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0 2px rgb(0, 0, 0);
}

.wrapper-edit-profile .content .footer .submit .submit-btn:hover,
.wrapper-edit-profile .content .footer .cancel .cancel-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
    background-color: rgba(186, 201, 179, 0.123);
}



.wrapper-edit-profile .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;
    margin: 0.3vmax 2.8vmax;

}

.wrapper-edit-profile .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;
    margin: 0.3vmax 2.8vmax;

}

.wrapper-edit-profile .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


/*DROPDOWN*/


.wrapper-edit-profile .flags-menu-drop{
    position: absolute;
    cursor: pointer;
    font-size: 0.9vmax;
    display: none;
    -webkit-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -moz-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -o-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    width: 36.2%;
    z-index: 2000;
    height: 10vmax;
}

.wrapper-edit-profile .flags-menu-drop-open{
    display: block;
}

.wrapper-edit-profile .flags-menu-drop .wrapper-flags-menu-dropdown{
    position: relative;
    background-color: rgb(255, 255, 255);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;

}

.wrapper-edit-profile .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option{
    height: 2vmax;
    width: 100%;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center; 
    text-transform: uppercase;

    font-size: 0.9vmax;

}

.wrapper-edit-profile .flags-menu-drop .wrapper-flags-menu-dropdown .opt-active{
    background-color: rgba(226, 226, 226, 0.322);
}


.wrapper-edit-profile .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option:hover{
    background-color: rgba(226, 226, 226, 0.322);
}

.wrapper-edit-profile .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option .text{
    padding-left: 1.5%;
}

.wrapper-edit-profile .flags-menu-drop .wrapper-flags-menu-dropdown .drop-option .icon{

    position: relative;
    width: 2vmax;
    height: 2vmax;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
}
