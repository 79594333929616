.wrapper-list-event-stats{
    position: relative;
    width: 100%;

}

.wrapper-list-event-stats .list-event-stats-header{
    position: relative;
    width: 100%;
}

.wrapper-list-event-stats .list-event-stats-body{
    position: relative;
    width: 100%;
    min-height: 80vh;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object{
    position: relative;
    width: 100%;
    margin-bottom: 1.2vmax;

}


.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union {
    position: relative;
    width: 100%;
    border: 0.8px solid #00000021;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 1vmax;
    min-height: 5.5vmax;

}


.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-content{
    width: 60%;
    
}


.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-content > *{
    display: block;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-content .event-object-title{
    text-shadow: 0.3px 0.3px rgba(0, 0, 0, 0.479);
    font-size: 1.1vmax;
    color: #4e4e4e;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-content .event-object-date{
    font-size: 0.65vmax;
    color: #c36a2d;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-content .event-object-date > *{
    display: inline;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-content .event-object-date .event-object-dot{
    margin: 0 0.2vmax;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-info{
    width: 40%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;


    justify-content: center;

}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-info .object-info-slice{
    width: 30%;
    margin-left:70%;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-info .object-info-slice > *{
    display:block;
    text-align: center;
    font-family: 'Roboto', sans-serif;

}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-info .object-info-slice .info-slice-title{
    font-size: 1.2vmax;
    font-weight: bold;

}
.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-info .object-info-slice .info-slice-content{
    font-size: 1vmax;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu{
    position: absolute;
    width: 100%;
    bottom:0;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: flex-start;
    bottom: -0.5vmax;
}


.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu .object-menu-opt{
    width: 8%;
    text-align: center;
    border: 0.1px solid #00000011;
    margin-right: 0.5%;
    background-color: white;
    font-size: 0.8vmax;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}
.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu .object-menu-opt .menu-opt-icon{
    font-size: 0.9vmax;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu .object-menu-opt .menu-opt-text{
    font-size: 0.7vmax;
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu .object-menu-opt:hover > * {
    transform: scale(1.05);
}


.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu .opt-delete{
    border: 0.1px solid rgba(230, 92, 92, 0.589);
    color:rgba(230, 92, 92, 0.87);
}

.wrapper-list-event-stats .list-event-stats-body .event-stats-object .event-stats-object-union .event-stats-object-menu .opt-edit{
    border: 0.1px solid rgba(94, 152, 206, 0.541);
    color: rgba(94, 152, 206, 0.87);
}



.wrapper-list-event-stats .list-event-stats-footer{
    position: relative;
    width:100%;
    margin-top: 1vmax;
    min-height: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;


    background-color: rgba(107, 109, 81, 0.192);
}

.wrapper-list-event-stats .list-event-stats-footer .footer-info{
    width: 60%;
    height: 100%;
}

.wrapper-list-event-stats .list-event-stats-footer .footer-pagination {
    width: 40%;
    height: 100%;
    user-select: none;

}


.wrapper-list-event-stats .list-event-stats-footer .footer-pagination > * {
    font-size: 1.4vmax;
    cursor: pointer;
    display: inline-block;

}

.wrapper-list-event-stats .list-event-stats-footer .footer-pagination .go-left{
    margin-left: 45%;
    margin-right: 0.5%;

}

.wrapper-list-event-stats .list-event-stats-footer .footer-pagination .go-right{
    margin-left: 0.5%;

}

.wrapper-list-event-stats .list-event-stats-footer .footer-pagination .go-pages {
    margin: 1.5vmax 0.5% 0 0.5%;
    font-size: 1vmax;

}

.wrapper-list-event-stats .list-event-stats-footer .footer-pagination .go-pages:hover{
    text-decoration: underline;
    color: blue;
    
}


.wrapper-list-event-stats .list-event-stats-footer .footer-pagination .selected-go-pages{
    font-weight: bold;
    color: black;
    text-decoration: underline;

}
.wrapper-list-event-stats .list-event-stats-footer .footer-pagination .selected-go-pages:hover{
    text-decoration: underline;
    color: black;
}

.modal-delete-event{
}

.modal-delete-event .modal-delete-event-body{
    position: relative;
    width: 100%;
    height: 10vh;
    text-align: center;
}

.modal-delete-event .modal-delete-event-body .delete-event-question{
    display: block;
    width: 100%;
    font-size: 1vmax;
}

.modal-delete-event .modal-delete-event-body .delete-event-what{
    display: block;
    width: 100%;

    font-size: 1vmax;
    font-weight: bold;
}

.modal-delete-event .modal-delete-event-footer{
    border: none;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    text-align: center;
    justify-content: center;
}

.modal-delete-event .modal-delete-event-footer .modal-cancel-button{
    width: 40%;
    background-color: rgba(202, 211, 154, 0.363);

}

.modal-delete-event .modal-delete-event-footer .modal-submit-button{
    width: 30%;
}

