.wrapper-perks{
    position: relative;
    width: 85%;
    margin: 7vmax auto;
    user-select: none;
    min-height: 40vh; /*optional for animation*/
    
}

.wrapper-perks .container-perks{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    justify-content: center;

}

.wrapper-perks .container-perks .container-perk-object {
    text-align: center;
    background-color: white;
    overflow: hidden;
    height: 50vmin;
    cursor: pointer;
    min-width: calc(82%/3);
    width: 300px;;
    margin: 1.5vmax calc(18%/6);
    position: relative;
    padding: 1vmax;
    animation: middlePerk 1.5s;
    transition: transform 0.7s;
    -webkit-box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 2.5px, inset rgba(255, 255, 255, 0.575) 0px 0px 10px 5px;
    -moz-box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 2.5px, inset rgba(255, 255, 255, 0.575) 0px 0px 10px 5px;
    -o-box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 2.5px, inset rgba(255, 255, 255, 0.575) 0px 0px 10px 5px;
    box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 2.5px, inset rgba(255, 255, 255, 0.575) 0px 0px 10px 5px;
    color:blacK;
    text-decoration: none;
}


.wrapper-perks .container-perks .container-perk-object::before{
    position: absolute;
    top:0;
    left:0;
    content:'';
    height: 100%;
    width: 100%;
    background-image: url("../../../assets/homepage/wallpaper.jpg");
    /* Set a specific height */
      
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.05;

}

.wrapper-perks .container-perks .container-perk-object:hover{
    transform: scale(1.05);
}

.wrapper-perks .container-perks .container-perk-object:first-child{
    animation: firstPerk 1.5s;
}
.wrapper-perks .container-perks .container-perk-object:last-child{
    animation: lastPerk 1.5s;
}
@keyframes firstPerk{
    from{
        transform: scale(0.5) translate(-100px);
        opacity: 0;
    }
    to{
        transform: scale(1) translate(0px);
        opacity: 1
    }
}
@keyframes middlePerk{
    from{
        transform: scale(0.5);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1
    }
}
@keyframes lastPerk{
    from{
        transform: scale(0.5) translate(100px);
        opacity: 0;
    }
    to{
        transform: scale(1) translate(0px);
        opacity: 1
    }
}





.wrapper-perks .container-perks .container-perk-object:hover {
    -webkit-box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 5px, inset rgba(255, 255, 255, 0.575) 0px 00px 5px 5px;
    -moz-box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 5px, inset rgba(255, 255, 255, 0.575) 0px 00px 5px 5px;
    -o-box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 5px, inset rgba(255, 255, 255, 0.575) 0px 00px 5px 5px;
    box-shadow:rgba(0, 0, 0, 0.164) 0px 0px 5px 5px, inset rgba(255, 255, 255, 0.575) 0px 00px 5px 5px;
}




/*
.wrapper-perks .container-perk-object::before {
    content: "";
    box-shadow:  0 0 5px 5px #00000057;
    height: 0.1vmax;    
    margin-top: 1vmax;
    margin-bottom: 3vmax;

    left: 0;
    width: 70%;
    margin-left: 15%;
    display: block;

}
.wrapper-perks .container-perk-object::after {
    content: "";
    position: absolute;
    background-image: url("../../../assets/icons/pickicon.png");
    width: 3vmax;
    height: 3vmax;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top:0.2vmax;
    left: 45%;
    display: block;


}

*/




.wrapper-perks .container-perks .container-perk-object .perk-object-image {
    position: relative;
    width: 40%;
    height: 40%;
    padding: 0.5vmax;
    margin: 0 auto;
}

.wrapper-perks .container-perks .container-perk-object .perk-object-image img {
    width: 100%;
    height: 100%;
}

.wrapper-perks .container-perks .container-perk-object .perk-object-text{
    position: relative;
    height: 60%;

    margin-top:3vmax;;



}

.wrapper-perks .container-perks .container-perk-object .perk-object-text > * {
    display: block;
    font-family: "Montserrat";

}

.wrapper-perks .container-perks .container-perk-object .perk-object-text .perk-object-text-title{
    font-size: 4vmin;
}
.wrapper-perks .container-perks .container-perk-object .perk-object-text .perk-object-text-desc{
    font-size: 2vmin;
}


@media only screen and (max-width: 650px) {

    .wrapper-perks .container-perks .container-perk-object{
        text-align: center;
        background-color: white;
        color:blacK;
        text-decoration: none;

        overflow: hidden;
        height: 50vmin;
        cursor: pointer;

        margin: 1.5vmax calc(18%/6);
        position: relative;
        padding: 1vmax;

        width: 300px;

    }

  }



@media only screen and (max-width: 1300px) {

    /*
    .wrapper-perks{
        position: relative;
        width: 100%;
        padding: 7vmax 0;
        user-select: none;
        margin: 0 auto;
        min-height: 40vh;
    }


    .wrapper-perks .container-perks{
        position: relative;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        display: block;

    }

    .wrapper-perks .container-perks .container-perk-object {
        position: relative;

        text-align: center;
        background-color: white;
        overflow: hidden;
        height: 50vmin;
        cursor: pointer;
        width: 65%;
        margin: 4vmax auto;
        min-width: 280px;

        padding: 1vmax;
        -webkit-box-shadow:rgba(0,0,0,0.8) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
        -moz-box-shadow:rgba(0,0,0,0.8) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
        -o-box-shadow:rgba(0,0,0,0.8) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
        box-shadow:rgba(0,0,0,0.8) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
    }
    */

    

  }

