.wrapper-models{
    position: relative;
    width: 100%;
    user-select: none;
    padding-top:2.5vmax;
    padding-bottom:4vmax;
}


.wrapper-models::before{
  position: absolute;
  top:0;
  left:0;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("../../assets/homepage/hmmm.jpg");

  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.94;
}


.wrapper-models .container-header {
  position: relative;
  width: 85%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  align-items: center;
  margin: 4vmax 0 0 0;
  margin-left: 10%;

}

.wrapper-models .container-header .content{
  position: relative;
  width: 50%;
  font-size: 1.4vmax;
  color: rgb(0, 0, 0);
  font-family: 'Roboto', sans-serif;
  border-bottom: solid 1px rgba(0, 0, 0, 0.466);
  padding-bottom: 0.5vmax;
}

.wrapper-models .container-header .content span{
  padding-left: 1.5%;
  text-transform: uppercase;
  color: black;
  background-color: #efe9cc;
  padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}


.wrapper-models .container-models{
  position: relative;
  width: 100%;
  background-color: #aabd872a;
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.137);;      
  margin:3vmax auto 0 auto;
  overflow: hidden;

}


.wrapper-models .container-models .model-box{
  position: relative;
  width: 100%;

  /*min-height: calc(80vh - (110vw/-12));
    margin:3vmax auto 3vmax auto;
  padding: 5vmax;
  */

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;


  -ms-flex-wrap: wrap;
  -webkit-flex-wrap:wrap;
  flex-wrap: wrap;


  padding: 1vmax 5vmax;
  font-size: 1.2vmax;
  word-wrap: break-word;

  /*
  -webkit-transform: skew(0, -1.5deg) translateY(calc(50vw/-12));
  -ms-transform: skew(0, -1.5deg) translateY(calc(50vw/-12));
  transform: skew(0, -1.5deg) translateY(calc(50vw/-12));
  */
}

/*
.wrapper-models .container-models .model-box:nth-child(even)::before{
  background-color: rgba(34, 34, 32, 0.212);
  position: absolute;
  content: '';
  top: 0;
  left:0;
  width: 100%;
  height: 100%;

}
.wrapper-models .container-models .model-box:nth-child(odd)::before{
  background-color: rgba(228, 230, 210, 0.11);
  position: absolute;
  content: '';
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}
*/
.wrapper-models .container-models .model-box:first-child{
  margin-top: 7vmax;
}
.wrapper-models .container-models .model-box:last-child{
  margin-bottom:0vmax;
}
/*
.wrapper-models .container-models .model-box > *{
  -webkit-transform: skew(0, 1.5deg) translateY(0);
  -ms-transform: skew(0, 1.5deg) translateY(0);
  transform: skew(0, 1.5deg) translateY(0);
}
*/


.wrapper-models .container-models .model-box .model-box-ayy {
  position: relative;
  width: 100%;

  padding: 2vmax;
}



  .wrapper-models .container-models .model-box .model-box-ayy .model-title{
    position: relative;
    width: 100%;
    font-size: 2.5vmax;;
    text-align: center;
    background-color: rgba(228, 228, 224, 0.384);
    padding: 1vmax;
    font-family: Montserrat, 'Segoe UI', Roboto, sans-serif;
    font-weight: bold;
    margin-bottom: 2vmax;;

    padding:1vmax;
    /*border-radius:89% 11% 85% 15% / 11% 85% 15% 89%;*/


    color: rgba(255, 255, 255, 0.801);
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.459);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

  }


  .wrapper-models .container-models .model-box .model-box-ayy .model-title .model-title-a{
    position: relative;
  }
  .wrapper-models .container-models .model-box .model-box-ayy .model-title .model-title-b{
    position: absolute;
    width: 5vmax;
    cursor: pointer;
    bottom:-1vmax;
    right: 2vmax;
    background-color: rgba(228, 228, 224, 0.575);


  }
  .wrapper-models .container-models .model-box .model-box-ayy .model-title .model-title-b .model-title-b-icon-down{
    background-image: url("../../assets/figma/arrowDown.svg");
    position: relative;
    background-size: 2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
}
.wrapper-models .container-models .model-box .model-box-ayy .model-title .model-title-b .model-title-b-icon-up{
  background-image: url("../../assets/figma/arrowUp.svg");
  position: relative;
  background-size: 2vmax;
  background-repeat: no-repeat;
  background-position: center;
  height:2vmax;
}





  .wrapper-models .container-models .model-box .model-box-ayy .model{
    position: relative;
    width: 90%;
    margin:0 auto;
  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block{
    position: relative;
    width: 100%;
    margin-bottom: 4vmax;

  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .block-separator{
    background-color: rgba(255, 255, 255, 0.63);

  }


  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .big-block-title{
    position: relative;
    font-size: 1.7vmax;
    margin-bottom: 3vmax;
    font-weight: 100;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap: wrap;
    background-color: rgba(49, 49, 49, 0.5);
    color:rgba(255, 255, 255, 0.849);
    padding: 0.5vmax;

  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .big-block-title .big-block-title-a{
    position: relative;
    width: 100%;
    padding-left: 3%;
  }
  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .big-block-title .big-block-title-b{
    position: relative;
    width: 5%;
    cursor: pointer;

  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .big-block-title .big-block-title-b .big-block-title-b-icon-down{
    background-image: url("../../assets/figma/arrowDownW.svg");
    position: relative;
    background-size: 2vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:3vmax;
    border: 1px solid rgba(255, 255, 255, 0.308);
}

.wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .big-block-title .big-block-title-b .big-block-title-b-icon-up{
  background-image: url("../../assets/figma/arrowUpW.svg");
  position: relative;
  background-size: 2vmax;
  background-repeat: no-repeat;
  background-position: center;
  height:3vmax;
  border: 1px solid rgba(255, 255, 255, 0.308);
}



  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block:not(:first-child) .big-block-title{
    margin-top: 1.5vmax;
  }
  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block:first-child .big-block-title{
    margin-top: 5vmax;

  }


  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .model-small-block{
    position: relative;
    width: 100%;
    margin-bottom: 1.5vmax;
    padding:1.2vmax;

  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block  .model-small-block:hover{
    transform: scale(1.005);
  }


  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .model-small-block .small-block-title{
    position: relative;
    width: 100%;
    font-size: 1.4vmax;
    font-family: Montserrat, 'Segoe UI', Roboto, sans-serif;
    background-color: rgba(255, 255, 255, 0.295);
    padding:  0 1vmax;
  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .model-small-block .small-block-link{
    position: relative;
    width: 100%;
    font-size: 0.95vmax;
    cursor: pointer;
    font-family: Montserrat, 'Segoe UI', Roboto, sans-serif;
  }

  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .model-small-block .small-block-title span,
  .wrapper-models .container-models .model-box .model-box-ayy .model .model-big-block .model-small-block .small-block-link a{
    color:white;

  }
