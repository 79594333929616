.wrapper-documents{
    position: relative;
    width: 100%;
    cursor: default;
    user-select: none;
    padding-top:2.5vmax;
    padding-bottom:4vmax;
}


.wrapper-documents::before{
    position: absolute;
    top:0;
    left:0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("../../assets/homepage/hmmm.jpg");

    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.94;
}

.wrapper-documents .content-all{
    position: relative;
    background-color: #cfcccc2a;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.288);;       
     padding: 3vmax 0 1vmax 0;
    width: 80%;
    margin: 2.5vmax auto 2.5vmax auto;
    min-height: 80vh;
}


.wrapper-documents .content-all .container-header {
    position: relative;
    width: 85%;
    margin: 0 auto 3.5vmax auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;

}

.wrapper-documents .content-all .container-header .content{
    width: 50%;
    font-size: 1.4vmax;
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    border-bottom: solid 1px rgba(0, 0, 0, 0.466);
    padding-bottom: 0.5vmax;
}

.wrapper-documents .content-all .container-header .content span{
    padding-left: 1.5%;
    text-transform: uppercase;
    color: black;
    background-color: #efe9cc;
    padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}


.wrapper-documents .content-all .container-body{
    position: relative;
    width: 87.5%;
    margin: 0 auto 0 auto;
    padding-left: 2vmax;
}

.wrapper-documents .content-all .container-body .no-content{
    display: block;
    position: relative;
    padding: 1vmax 0;
    box-shadow: 0 0 3px 5px #9c9c9cad;
    text-align: center;
    font-size: 1.1vmax;
    background-color: rgba(255, 255, 255, 0.459);
    font-family: "Montserrat", Arial, sans-serif;


}



.wrapper-documents .content-all .container-body .documents-object{
    position: relative;
    width: 100%;
    margin: 5.5vh 0;
    background-color: rgba(255, 255, 255, 0.55);
    -webkit-box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.6) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.329) 0px 0px 1px 1px;
    border-radius: 0.3vmax;
    padding: 1.8vmax 1.2vmax;
}


.wrapper-documents .content-all .container-body .documents-object:hover {
    -webkit-box-shadow: rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.829) 0px 0px 1px 2px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    transform: scale(1.005);
}



.wrapper-documents .content-all .container-body .documents-object .object-flex{
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    font-size: 1vmax;
    width: 100%;
    min-height: 2vmax;

}


.wrapper-documents .content-all .container-body .documents-object .object-flex .document-object-img{
    width: 3.5%;
    /*min-width: 2vmax;*/
    min-width: 2vmax;
    overflow: hidden;
    max-height: 2vmax;
}

.wrapper-documents .content-all .container-body .documents-object .object-flex .document-object-img img{
    width: 100%;
    height: 100%;
}





.wrapper-documents .content-all .container-body .documents-object .object-flex .document-object-name{
    margin: 0 2%;
}

.wrapper-documents .content-all .container-body .documents-object .object-flex .document-object-name span{

}




.wrapper-documents .content-all .container-body .documents-object .document-object-menu{
    position: absolute;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    left: 4.5%;
    bottom: -0.8vmax;
}


.wrapper-documents .content-all .container-body .documents-object .document-object-menu > * {
    position: relative;
    width: 1.8vmax;
    height: 1.8vmax;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.486);
    -webkit-box-shadow:rgba(0, 0, 0, 0.062) 0px -1px 1px 1px, inset rgba(0, 0, 0, 0.075) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(0, 0, 0, 0.062) 0px -1px 1px 1px, inset rgba(0, 0, 0, 0.075) 0px 0px 1px 1px;
    -o-box-shadow:rgba(0, 0, 0, 0.062) 0px -1px 1px 1px, inset rgba(0, 0, 0, 0.075) 0px 0px 1px 1px;
    box-shadow:rgba(0, 0, 0, 0.062) 0px -1px 1px 1px, inset rgba(0, 0, 0, 0.075) 0px 0px 1px 1px;
}

.wrapper-documents .content-all .container-body .documents-object .document-object-menu >*:nth-child(2n){
    margin: 0 0.5vmax;
}


.wrapper-documents .content-all .container-body .documents-object .document-object-menu .option-show .option-show-icon{
    position: relative;
    background-image: url("../../assets/figma/booklets/show.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    cursor: pointer;
    width: 100%;
    height: 1.8vmax;
}

.wrapper-documents .content-all .container-body .documents-object .document-object-menu .option-download .option-download-icon{
    position: relative;
    background-image: url("../../assets/figma/booklets/save.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.2vmax;
    cursor: pointer;
    width: 100%;
    height: 1.8vmax;
}

.wrapper-documents .content-all .container-body .documents-object .document-object-menu > * .option-show-icon:hover,
.wrapper-documents .content-all .container-body .documents-object .document-object-menu > * .option-download-icon:hover{
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);

}



.wrapper-documents .content-all .container-body .show-more{
    position: relative;
    width: 100%;
    height: 5vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: center;
}

.wrapper-documents .content-all .container-body .show-more .button-show{
    outline: none;
    border: none;
    font-size: 1.2vmax;
    padding: 0.35vmax 2vmax;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    -o-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;

}

.wrapper-documents .content-all .container-body .show-more .button-show:hover {
    transform: scale(1.02);
}


