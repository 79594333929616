.wrapper-bo-manage-admin{
    position: relative;
    width: 100%;
    user-select: none;

}

.wrapper-bo-manage-admin .container-display{
    position: relative;
    width: 90%;
    margin: 2vmax auto;;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-start;
    padding:5vmax 2vmax;
    background-color: rgba(221, 220, 220, 0.336);
}


.wrapper-bo-manage-admin .container-display .display-option{
    position: relative;
    min-width:10vmax;
    min-height: 10vmax;
    margin: 0 2%;

    box-shadow: 0 0 1.2px #e4ce8e94;
    border: 0.5px solid rgba(0, 0, 0, 0.123);
    background-color: white;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;


}

.wrapper-bo-manage-admin .container-display .display-option:hover{
    background-color: #e0be5736;
}

.wrapper-bo-manage-admin .container-display .display-option .opt-icon{
    position: relative;
    background-size: 4vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 5vmax;
    width: 100%;
}

.wrapper-bo-manage-admin .container-display .display-option .icon-hire{
    background-image: url("../../../assets/figma/bo/hire.svg");
}
.wrapper-bo-manage-admin .container-display .display-option .icon-fire{
    background-image: url("../../../assets/figma/bo/fire.svg");
}


.wrapper-bo-manage-admin .container-display .display-option .opt-text{
    position: relative;
    width: 100%;
    font-size: 1.2vmax;
    text-align: center;
    font-family:Montserrat,Roboto, 'Segoe UI', sans-serif

}
