.wrapper-news-page{
    position: relative;
    width: 100%;    
    background-color: rgba(255, 255, 255, 1);
    padding-top:2.5vmax;
    padding-bottom: 5vmax;;

}

.wrapper-news-page::before{
    position: absolute;
    top:0;
    left:0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("../../assets/homepage/hmmm.jpg");
  
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
  }
  
  

.wrapper-news-page .container-header {
    position: relative;
    width: 75%;
    margin: 7vh auto 6vh auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;


}

.wrapper-news-page .container-header .content{
    width: 50%;
    font-size: 1.4vmax;
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    border-bottom: solid 1px rgba(0, 0, 0, 0.466);
    padding-bottom: 0.5vmax;
}

.wrapper-news-page .container-header .content span{
    padding-left: 1.5%;
    text-transform: uppercase;
    color: black;
    background-color: #efe9cc;
    padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}

.wrapper-news-page .container-header .today-date{
    width: 50%;
    text-align: center;
    font-size: 1.2vmax;

    /*color: #4e4e4eb0;*/
    color:white;
    text-align: right;

}

.wrapper-news-page .container-header .today-date span{
    padding:0.4vmax 0.8vmax;
    background-color: rgba(255, 255, 255, 0.815);
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.288);;      
    color:black;

}




.wrapper-news-page .container-news-ads-full{
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 100%;
    /*background-color:  #a7a390c2;*/
    padding: 1vmax 0;
}

.wrapper-news-page .container-news-ads-full .background{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /*
    background: url("../../assets/images/wallpaper.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;


    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter:blur(15px);
    -ms-filter: blur(15px);
    filter: blur(5px);
    opacity: 0.4;
    */
}


.wrapper-news-page .container-news-ads-full .left-side,
.wrapper-news-page .container-news-ads-full .right-side{
   /* width: calc(28%/2);*/
    width: calc(25%/2);

    /*background: url("../../assets/mineheritageLogo/banner.png") no-repeat center center;*/


}


.wrapper-news-page .container-news-ads-full .container-news-ads {
    position: relative;
    width: 75%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.wrapper-news-page .container-news-ads-full  .container-news-ads .container-news {
    width: 100%;
    /*width: 82%;*/
}

.wrapper-news-page .container-news-ads-full  .container-news-ads .container-ads {
    display:none;
    width: 18%;
    padding-left: 2%;
}

.wrapper-news-page .container-news-filter{
    position: relative;
    /*width: 72%;*/
    width:100%;
    margin: 0 auto;
    margin-top: 5vmax;;

    padding: 5vmax 8vmax 5vmax 8vmax;
    background-color: #ebebeb44;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.288);;      
 
}


@media only screen and (max-width: 600px) {
    .wrapper-news-page .container-news-ads-full .container-news-ads .container-ads {
        display: none;
    }

    
    .wrapper-news-page .container-news-ads-full  .container-news-ads .container-news {
        width: 100%;
        /*width: 100%;*/
    }

  }

