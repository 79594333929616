.wrapper-edit-publication{
    display: none;
    z-index: 5000;

}

.wrapper-edit-publication .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 1200;
}

.editPublication-modal-open{
    display: block;
}

.editPublication-modal-open .overlay{
    
}


@keyframes editPublication-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-edit-publication .content{
    position: absolute;
    top: calc( (-1*var(--navMain-real-height)) + var(--editModalPublication-topOffset));
    margin-top: 0;

    background-color: #fff;
    z-index: 5002;
    padding: 1vmax;
    box-sizing: border-box;
    width: 60vw;
    display: none;
    z-index: 1201;
}

.wrapper-edit-publication.editPublication-modal-open .content{
    word-wrap: break-word;
    animation: editPublication-modal-fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-edit-publication .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;


}

.wrapper-edit-publication .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
}

.wrapper-edit-publication .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-edit-publication .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    /*
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    */
    background-color: transparent;


    position: relative;
    background-image: url("../../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;

}

.wrapper-edit-publication .content .header .close .close-btn:hover{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}


.wrapper-edit-publication .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;


    margin: 2vmax 0;
    text-align: center;
    font-size:1.1vmax;
    min-height: 60vh;
    height: 60vh;

}

.wrapper-edit-publication .content .body .container-stepper{
    position: relative;
    width: 100%;
}

.wrapper-edit-publication .content .body .container-stepper-body{
    position: relative;
    width: 100%;


}



.wrapper-edit-publication .content .footer{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}



.wrapper-edit-publication .content .footer .next{
    position: relative;
    width: 30%;
    margin-left: 1%;

}

.wrapper-edit-publication .content .footer .submit{
    position: relative;
    width: 30%;
    margin-left: 1%;

}



.wrapper-edit-publication .content .footer .previous{
    position: relative;
    width: 30%;
    margin-left: 5%;

}

.wrapper-edit-publication .content .footer .cancel{
    position: relative;
    width: 20%;

}



.wrapper-edit-publication .content .footer .next .next-btn,
.wrapper-edit-publication .content .footer .submit .submit-btn,
.wrapper-edit-publication .content .footer .cancel .cancel-btn,
.wrapper-edit-publication .content .footer .previous .previous-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
    font-size: 0.9vmax;
    z-index: 1

}


.wrapper-edit-publication .content .footer .next .next-btn:hover,
.wrapper-edit-publication .content .footer .submit .submit-btn:hover,
.wrapper-edit-publication .content .footer .cancel .cancel-btn:hover,
.wrapper-edit-publication .content .footer .previous .previous-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}





/*FORM ---------------------------------------------------------------------------------------------------------------*/


.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication{
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: left;

}


.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main{
    position: relative;
    width: 100%;
    height: 100%;
}




.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}




.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar{
    position: relative;
    height: 100%;
    width: 30%;
    margin-right:7.5%;

}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar .input-calendar-datepicker{
    position: relative;
    width: 100%;
    height: 100%;
    margin:0 !important;
    line-height: 0 !important;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar .input-calendar-datepicker .MuiInputBase-root{
    font-size: 0.9vmax;
    line-height: 0;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar .input-calendar-datepicker .MuiSvgIcon-root{
    font-size: 1vmax;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar .input-calendar-datepicker>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>* {
    user-select: none !important;
    outline: none !important;
}
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar .input-calendar-datepicker>*>*>*:hover {
    background-color: transparent;
    transform: scale(1.1);
}


.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-calendar .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-title,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-text1,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-text2,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-url{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-title .input-title-text,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-text1 .input-text1-text,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-text2 .input-text2-text,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-url .input-url-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
}


.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-title .label,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-text1 .label,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-text2 .label,
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-main .main-row .input-url .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}




.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
    margin-top: 1vmax;

}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .container-title{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    margin-right: 5%;

}


.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .container-title span{
    position: relative;
    margin-right: 3%;
    color:black;
    font-size: 1vmax;;
    font-weight: bold;

}




.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .edit-publication-content-text{
    position: relative;
    width: 100%;
    height: 100%;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .edit-publication-content-text .edit-publication-content-text-input{
    position: relative;
    width: 100%;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
    min-height: 2vmax;
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .edit-publication-content-text .edit-publication-content-text-input > * {
}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .edit-publication-content-text .edit-publication-content-text-input .content-text-text1{
}
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .edit-publication-content-text .edit-publication-content-text-input .content-text-title{
    font-weight: bold;

}
.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .container-edit-publication-content .edit-publication-content-text .edit-publication-content-text-input .content-text-text2{
}







.wrapper-edit-publication .container-edit-publication-main .main-row .input-calendar .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}





.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-edit-publication .content .body .container-stepper-body .container-form-edit-publication .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


