.wrapper-tabs-backoffice{
    position: relative;
    width: 100%;
}

.wrapper-tabs-backoffice .container-tabs{
    position: relative;
    width: 100%;
    /*background-color: rgba(81, 83, 48, 0.3);*/

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wrapper-tabs-backoffice .container-tabs .tabs-normal{
    position: relative;
    height: 2.5vmax;
    width: 20%;
    font-size: 0.9vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.wrapper-tabs-backoffice .container-tabs .tabs-active{
    background-color: rgba(225, 226, 148, 0.521);
}

.wrapper-tabs-backoffice .container-tabs  .tabs-normal:hover{
    background-color: rgba(225, 226, 148, 0.144);
}

.wrapper-tabs-backoffice .container-tabs .tabs-underline{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px  solid rgb(212, 201, 180);
    transition: left 500ms, right 500ms;
}

