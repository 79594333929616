.wrapper-moreinfoapp {
    position: relative;
    width: 100%;
    user-select: none;
    min-height: 50vh;
    margin-top: 5vmax;
}

.wrapper-moreinfoapp .container-moreinfoapp {
    position: relative;
    width: 80%;
    margin: 0 auto;
}



.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-intro {
    position: relative;
    font-size: 4vmin;
    margin-bottom: 1vmax;


}

.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-intro span{
    text-transform: uppercase;
    font-family: "Montserrat";
    background-color: black;
    color:#c4a981;
    padding: 0.2%;
    padding-left: 5%;
    padding-right: 10%;
}

.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-desc{
    margin-top: 2.5vmax;
    margin-bottom: 1.8vmax;
    font-size: 2.5vmin;
    padding:1vmax 0;
    /*
    padding-right: 5vmax;
    padding-left: 2vmax;
    */
    text-align: justify;
    font-family: "Montserrat";

}


.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-list {
    position: relative;
    width: 100%;
    padding-right: 10vmax;
    padding-left: 3vmax; 

}

.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-list .moreinfoapp-list-object {
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 1vmax;

}


.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-list .moreinfoapp-list-object  .imavatar{
    display: block;;
    width: 7%;
    min-width: 3vmin;
    max-width: 3vmin;
    height: 3vmin;
}

.wrapper-moreinfoapp .container-moreinfoapp  .container-moreinfoapp-list .moreinfoapp-list-object .moreinfoapp-list-object-content{
    width: 92%;
    margin-left:1%;
    display: block;
    font-size: 2.5vmin;
    font-family: "Montserrat";

}

