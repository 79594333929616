.wrapper-videos{
    position: relative;
    width: 100%;
    padding-top:2.5vmax;
    padding-bottom:4vmax;
}

.wrapper-videos::before{
  position: absolute;
  top:0;
  left:0;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("../../assets/homepage/hmmm.jpg");

  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.94;
}


.wrapper-videos .container-header {
  position: relative;
  width: 85%;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;

  align-items: center;
  margin: 4vmax 0 0 0;
  margin-left: 10%;

}

.wrapper-videos .container-header .content{
  width: 50%;
  font-size: 1.4vmax;
  color: rgb(0, 0, 0);
  font-family: 'Roboto', sans-serif;
  border-bottom: solid 1px rgba(0, 0, 0, 0.466);
  padding-bottom: 0.5vmax;

}

.wrapper-videos .container-header .content span{
  padding-left: 1.5%;
  text-transform: uppercase;
  color: black;
  background-color: #efe9cc;
  padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}


.wrapper-videos .container-videos{
    position: relative;
    width: 100%;
    background-color: #aabd872a;
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.137);;      
    margin:3vmax auto 0 auto;
    overflow: hidden;
}

.wrapper-videos .container-videos .video-box{
  position: relative;
  width: 100%;
  margin:2vmax auto 2vmax auto;
  padding: 5vmax;
  -webkit-transform: skew(0, -1.5deg) translateY(calc(50vw/-12));
  -ms-transform: skew(0, -1.5deg) translateY(calc(50vw/-12));
  transform: skew(0, -1.5deg) translateY(calc(50vw/-12));
  min-height: calc(60vh - (110vw/-12));

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;


  -ms-flex-wrap: wrap;
  -webkit-flex-wrap:wrap;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;

}





.wrapper-videos .container-videos .video-box:nth-child(even){
  background-color: rgba(170, 175, 100, 0.521);
}
.wrapper-videos .container-videos .video-box:nth-child(odd){
  background-color: rgba(222, 224, 190, 0.151);
   
  
}

.wrapper-videos .container-videos .video-box:first-child{
  margin-top: 7vmax;
}
.wrapper-videos .container-videos .video-box:last-child{
  margin-bottom:0vmax;
}

.wrapper-videos .container-videos .video-box > *{
  -webkit-transform: skew(0, 1.5deg) translateY(0);
  -ms-transform: skew(0, 1.5deg) translateY(0);
  transform: skew(0, 1.5deg) translateY(0);
}



.wrapper-videos .container-videos .video-box .video{
    position: relative;
    overflow: hidden;
    width: 60%;
    margin: 0 auto;
    padding-top: 30%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    
  }

  
  /* Then style the iframe to fit in the container div with full height and width */
  .wrapper-videos .container-videos .video-box .video .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

  }


  .wrapper-videos .container-videos .video-box .video-title{
    position: relative;
    width: 60%;
    margin-bottom: 1.5vmax;;
    font-size: 1.2vmax;;
    background-color: rgba(170, 175, 100, 0.521);
    padding: 0.3vmax;
    padding-left: 0.8vmax;
    font-family: Montserrat, 'Segoe UI', Roboto, sans-serif;

  }

  .wrapper-videos .container-videos .video-box .video-title > span{
    display: inline;
  }


  .wrapper-videos .container-videos .video-box .video-title span:nth-child(2){
    font-weight: bold;
    padding-left: 0.5%;;
  }



  .wrapper-videos .show-more{
    position: relative;
    width: 100%;
    height: 5vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    margin-bottom:3vmax;

}

.wrapper-videos .show-more .button-show{
    outline: none;
    border: none;
    font-size: 1.2vmax;
    padding: 0.5vmax 2vmax;
    background-color: rgba(255, 255, 255, 0.589);
    -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    -o-box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px, inset rgba(0,0,0,0.15) 0px 0px 2px;

}

.wrapper-videos .show-more .button-show:hover{
    background-color: rgba(219, 219, 219, 0.589);
}