:root{
    --kekax-stepper-nSteps: 1
  }
  

.wrapper-stepper-kekax{
    position: relative;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 2vmax;
}

.wrapper-stepper-kekax .container-stepper{
    position: relative;
    width: 95%;
    margin: 0 auto;
    /*background-color: rgba(81, 83, 48, 0.3);*/

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;


    z-index: 2;
    /*padding: 0.5vmax 0;*/

}

.wrapper-stepper-kekax .container-stepper .line{
    position: absolute;
    height: 0.1vmax;
    width: 100%;
    top: 35%;
    left: 0;
    background-color: rgba(129, 129, 129, 0.26);
    margin: 0;
    padding: 0;
    z-index:-1;

}



.wrapper-stepper-kekax .container-stepper .stepper-normal{
    position: relative;
    min-width: calc(100%/var(--kekax-stepper-nSteps));

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;


    cursor: pointer;
}




.wrapper-stepper-kekax .container-stepper .stepper-normal .circle{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;


    height: 2.5vmax;
    width: 2.5vmax;
    font-size: 1vmax;;
    border-radius: 50%;
    background-color: rgba(221, 221, 218, 0.521);
    border: 0.5px solid rgba(156, 156, 156, 0.404);
}

.wrapper-stepper-kekax .container-stepper .stepper-normal:hover{
    background-color: rgba(165, 165, 165, 0.144);
}

.wrapper-stepper-kekax .container-stepper .stepper-normal span{
    font-size: 0.9vmax;
    display: block;

}


.wrapper-stepper-kekax .container-stepper .stepper-normal .circle::before{
    content:'';
    position:absolute;
    left:0;
    width: 100%;
    bottom:0;
    height: 100%;
    z-index: -1;
    background-color: rgb(255, 255, 255);
  }




.wrapper-stepper-kekax .container-stepper .stepper-active .circle{
    background-color: rgba(225, 226, 148, 0.521);
    border: 0.5px solid rgba(214, 161, 80, 0.404);
}

.wrapper-stepper-kekax .container-stepper .stepper-active span{
    color:black;
}

.wrapper-stepper-kekax .container-stepper .stepper-before .circle{
    background-color: rgba(125, 216, 64, 0.521);
    border: 0.5px solid rgba(214, 161, 80, 0.404);
}


