.wrapper-event-page{
    position: relative;
    width: 100%;
    min-height: 65vh;
}

.wrapper-event-page .header-event-page {
    position: relative;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    font-family: "Montserrat", Arial, sans-serif;
    background-color: rgba(172, 172, 124, 0.377);
    margin-top: 3vmax;
    margin-bottom: 1vmax;
    min-height: 5vmax;
}

.wrapper-event-page .header-event-page .header-event-page-title {
    font-size: 2.3vmax;
    font-weight: bold;
    display: block;
    color: rgb(0, 0, 0);
    padding-left: 1%;
}

.wrapper-event-page .header-event-page .header-event-page-sub{
    font-size: 0.7vmax;
    font-weight: bold;
    display: block;
    color: rgb(83, 83, 83);
    padding-left: 1.5%;
}

.wrapper-event-page .header-event-page .header-event-page-sub > *{
    display: inline;
}

.wrapper-event-page .header-event-page .header-event-page-sub .event-page-sub-dot{
    margin: 0 0.2vmax;
}

/*
.wrapper-event-page .header-event-page .header-event-page-sub {
    font-size: 0.7vmax;
    font-weight: bold;
    display: block;
    color: rgb(83, 83, 83);
    padding-left: 1.5%;
}
*/

.wrapper-event-page .body-event-page {
    position: relative;
    width: 90%;
    margin: 0 auto;
    text-align: left;
}

.wrapper-event-page .body-event-page .body-event-page-content {
    position: relative;
    width: 89%;
    margin: 0 auto;
    font-size: 1.1vmax;
    font-family: 'Montserrat', Arial, sans-serif;;
}

.wrapper-event-page .body-event-page .body-event-page-gallery {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    position: relative;
    width: 91%;
    margin: 0 auto;
    margin-top: 4vmax;
}

.wrapper-event-page .body-event-page .body-event-page-gallery .media-object {
    width: calc(90%/3);
    overflow: hidden;
    margin: 0 calc(10%/6) 0.8vmax calc(10%/6);
}

.wrapper-event-page .body-event-page .body-event-page-gallery .media-object .media-object-img{
}

.wrapper-event-page .body-event-page .body-event-page-gallery .media-object .media-object-img img{
    max-height: 20vmax;
    max-width: 100%;
    min-width: 70%;
    display: block;

}

.wrapper-event-page .body-event-page .body-event-page-gallery .media-object .media-object-img .media-object-legend{
    padding: 0.4vmax 0.6vmax 1.1vmax 0.2vmax;
    width: 100%;
    font-size: 0.8vmax;
    background-color: rgba(247, 247, 247, 0.342);
    text-align: left;
}


.wrapper-event-page .body-event-page .body-event-page-attach {
    position: relative;
    width: 89%;
    margin: 5vmax auto 6vmax auto;    
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    width: 100%;
    border: 0.1px solid rgba(211, 211, 211, 0.5);
    box-shadow: 0 0 2px #00000048;
    padding: 0.8vmax 0;
    margin: 0.5vmax 0;

}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-icon {
    width: 8%;
    height: 100%;
    padding-left: 2%;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-icon .ayy-icon {
    font-size: 2vmax;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-name {
    width: 65%;
    cursor: pointer;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-name:hover {
    text-decoration: underline;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-name span {
    padding-left: 1vmax;
    font-size: 1vmax;
    word-wrap: break-word;
    height: 100%;
    display: block;
    padding-top: 0.1vmax;

}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-menu {
    width: 27%;
    height: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-menu>* {
    width: 20%;
    text-align: center;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-menu .byy-icon {
    margin-top: 0.4vmin;
    font-size: 1.4vmax;
    cursor: pointer;
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-menu .byy-icon:hover {
    transform: scale(1.2);
}

.wrapper-event-page .body-event-page .body-event-page-attach .attach-object .attach-menu .attach-menu-option-show {
    margin-left: 40%;
}