.wrapper-minemap{
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    background-image: url("../../assets/images/map3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}



.wrapper-minemap .container-header {
    position: relative;
    width: 85%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    margin: 4vmax 0 0 0;
    margin-left: 10%;

}


.wrapper-minemap .container-header .content {
    position: relative;
    width: 50%;
    font-size: 1.4vmax;
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    border-bottom: solid 1px rgba(0, 0, 0, 0.466);
    padding-bottom: 0.5vmax;
}

.wrapper-minemap .container-header .title {
    padding-left: 1.5%;
    text-transform: uppercase;
    color: black;
    background-color: #efe9cc;
    padding: 0.2vmax 7vmax 0.2vmax 4vmax;
}

.wrapper-minemap .map-wrapper{
    position: relative;
    width: 85%;
    margin: 3vmax auto 10vmax auto;
    height: 50vmax;

}


.wrapper-minemap .map-wrapper .map-container {
    position: absolute; 
    top: 0;
    left:0;
    width: 100%;
    height: 50vmax;
    border-radius: 10px;
    height: 50vmax;
    -webkit-box-shadow: rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -moz-box-shadow:rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    -o-box-shadow:rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;
    box-shadow:rgba(235, 235, 235, 0.5) 0px 0px 10px 5px, inset rgba(236, 236, 236, 0.788) 0px 0px 1px 1px;

    /*
    padding: 3vmax 0 1vmax 0;
    margin: 2.5vmax auto 2.5vmax auto;
    */

}


.wrapper-minemap .marker {
    background-image: url('../../assets//markers/marker4.svg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0;
  }




.mapboxgl-canvas:focus{
    outline: none !important;
    border: none !important;
    user-select: none !important;
}



.mine-popup{
    -webkit-box-shadow:rgba(0, 0, 0, 0.562) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
    -moz-box-shadow:rgba(0, 0, 0, 0.562) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
    -o-box-shadow:rgba(0, 0, 0, 0.562) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;
    box-shadow:rgba(0, 0, 0, 0.562) 0px 0px 10px, inset rgba(0,0,0,0.15) 0px -10px 20px;

}


.mapboxgl-popup-content{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    padding: 0 !important;
    min-width:33vmax !important;
    max-width:33vmax !important;

    background-color: rgba(255, 255, 255, 0.911) !important;

}

.mapboxgl-popup-tip {

}

.mapboxgl-popup-close-button{
    z-index:1000;


}




.mapboxgl-popup-content .mine-popup{
    position: relative;
    overflow: hidden;
    word-break: break-all;
    cursor: default;

}

.mapboxgl-popup-content .mine-popup .mine-popup-title  {
    position: relative;
    width: 90%;
    margin: 0 auto;
    min-height: 4vmax;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    padding: 1vmax;;


}
.mapboxgl-popup-content .mine-popup .mine-popup-title span{
    font-size: 1.5vmax;
    line-height: 1;
    display: block;
}

.mapboxgl-popup-content .mine-popup .mine-popup-content{
    position: relative;
    width: 90%;
    margin: 0 auto 0 auto;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    padding: 1vmax;;

}


.mapboxgl-popup-content .mine-popup .mine-popup-content .mine-popup-text{
    position: relative;
    min-height: 1.5vmax;

}

.mapboxgl-popup-content .mine-popup .mine-popup-content .mine-popup-text span{
    font-size: 0.8vmax;
    display: block;
    line-height:normal;
}



.mapboxgl-popup-content .mine-popup .mine-popup-game{
    position: relative;
    min-height: 2vmax;

    width: 90%;
    margin: 1vmax auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    background-color:rgba(167, 185, 115, 0.849);
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.mapboxgl-popup-content .mine-popup .mine-popup-game:hover{
    background-color:rgb(167, 185, 115);
}

.mapboxgl-popup-content .mine-popup .mine-popup-game:hover span{
    color: rgb(0, 0, 0);
}


.mapboxgl-popup-content .mine-popup .mine-popup-game span{
    font-size: 0.9vmax;
    display: block;
    color: white;
    word-break: break-all;
    text-transform: uppercase;

}


.mapboxgl-popup-content .mine-popup .mine-popup-nogame{
    position: relative;
    min-height: 2vmax;

    width: 90%;
    margin: 1vmax auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    background-color:rgba(159, 165, 139, 0.849);
    font-family: 'Roboto', sans-serif;
    cursor: default;
}

.mapboxgl-popup-content .mine-popup .mine-popup-nogame span{
    font-size: 0.9vmax;
    display: block;
    color: white;
    word-break: break-all;
    text-transform: uppercase;
}

.wrapper-minemap  .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #ffffff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
    font-size: 0.8vmax;
    }
  
/*
.wrapper-map {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 2em auto;
    outline: none;
    user-select: none; 
}

.wrapper-map .wrapper-marker .marker-button{ 
    outline: none;
    user-select: none;
    background-image: url('../../assets/markers/markerRed.png');
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    background-color: Transparent;
    border: none;
}


.wrapper-map .wrapper-marker .marker-button:hover{
    user-select: none;

}

.wrapper-map .wrapper-geolocate{
    position: absolute;
    top: 0;
    left: 100px;
    margin: 100px;
}

.wrapper-map .wrapper-search{
    position: relative;
    width: 100%;
    text-align: "center";
    background-color: rgba(238, 238, 223, 0.616);
}

.wrapper-map .wrapper-search >* {
    margin: 0 auto;
}
*/


