.wrapper-edit-consortium{
    display: none;
    z-index: 5000;

}

.wrapper-edit-consortium .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 1200;
}

.editConsortium-modal-open{
    display: block;
}

.editConsortium-modal-open .overlay{
    
}


@keyframes editConsortium-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-edit-consortium .content{
    position: absolute;
    top: calc( (-1*var(--navMain-real-height)) + var(--editModalConsortium-topOffset));
    margin-top: 0;

    background-color: #fff;
    z-index: 5002;
    padding: 1vmax;
    box-sizing: border-box;
    width: 60vw;
    display: none;
    z-index: 1201;
}

.wrapper-edit-consortium.editConsortium-modal-open .content{
    word-wrap: break-word;
    animation: editConsortium-modal-fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-edit-consortium .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;


}

.wrapper-edit-consortium .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
}

.wrapper-edit-consortium .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-edit-consortium .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    /*
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    */
    background-color: transparent;


    position: relative;
    background-image: url("../../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;

}

.wrapper-edit-consortium .content .header .close .close-btn:hover{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}


.wrapper-edit-consortium .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;


    margin: 2vmax 0;
    text-align: center;
    font-size:1.1vmax;
    min-height: 60vh;
    height: 60vh;

}

.wrapper-edit-consortium .content .body .container-stepper{
    position: relative;
    width: 100%;
}

.wrapper-edit-consortium .content .body .container-stepper-body{
    position: relative;
    width: 100%;


}



.wrapper-edit-consortium .content .footer{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}



.wrapper-edit-consortium .content .footer .next{
    position: relative;
    width: 30%;
    margin-left: 1%;

}

.wrapper-edit-consortium .content .footer .submit{
    position: relative;
    width: 30%;
    margin-left: 1%;

}



.wrapper-edit-consortium .content .footer .previous{
    position: relative;
    width: 30%;
    margin-left: 5%;

}

.wrapper-edit-consortium .content .footer .cancel{
    position: relative;
    width: 20%;

}



.wrapper-edit-consortium .content .footer .next .next-btn,
.wrapper-edit-consortium .content .footer .submit .submit-btn,
.wrapper-edit-consortium .content .footer .cancel .cancel-btn,
.wrapper-edit-consortium .content .footer .previous .previous-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
    font-size: 0.9vmax;
    z-index: 1

}

.wrapper-edit-consortium .content .footer .next .next-btn:hover,
.wrapper-edit-consortium .content .footer .submit .submit-btn:hover,
.wrapper-edit-consortium .content .footer .cancel .cancel-btn:hover,
.wrapper-edit-consortium .content .footer .previous .previous-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}



/*FORM ---------------------------------------------------------------------------------------------------------------*/


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium{
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: left;

}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main{
    position: relative;
    width: 100%;
    height: 100%;
}




.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .container-title,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .container-title span,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .main-row{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}




.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .input-title,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .input-website{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .input-title .input-title-text,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .input-website .input-website-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;

}



.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

    margin-bottom: 2vmax;;;;;
    margin-top: 1vmax;;;;;

}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview {
    position: relative;
    width: 32%;
    height: 16vmax;
}
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image {
    width: 100%;
    height: 100%;
}
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options{
    position: absolute;
    right:0;
    top: 0;
    height: 100%;
    padding:0.5vmax;
}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-change-fab,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-delete-fab {
    position: relative;
    display: block;
    border-radius: 50%;
    outline: none;
    user-select: none;
    border: none;
    padding: 0;
    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);
    background-color: white;
    width: 2.2vmax;
    height: 2.2vmax;
  }

  .wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-delete-fab{
    margin-top: 0.5vmax;
  }

  .wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-hidden-fab{
    display: none;
}

  
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-change-fab .change {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/camera.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }

  .wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-delete-fab .delete {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/delete.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }
  
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-change-fab:hover .change,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-preview .image-options .image-delete-fab:hover .delete {
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
  }
  

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-content {
    position: relative;
    width: 68%;
    text-align: left;
}
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-content span {
    display: block;
    font-size: 1.5vmax;
    margin-left: 5%;
}
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-content .text-one {
    font-size: 1.5vmax;
}
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-image .edit-consortium-image-content .text-two {
    font-size: 0.9vmax;
}



.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
    margin-top: 2vmax;

}



.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-text,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-label{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    width: 100%;
    height: 100%;
}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-text .edit-consortium-content-text-input,
.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-label .side-label{
    position: relative;
    width: 50%;
}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-label .side-label span{
    font-size: 1vmax;;
    background-color: rgb(0, 0, 0);
    color:white;
    display: block;
    width: 100%;
    padding-left: 1vmax;
    text-transform: uppercase;
}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-text .edit-consortium-content-text-input .content-text{
    position: relative;
    width: 100%;
    outline: none;
    border: none;
    border-left: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 100%;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
    

}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-text .edit-consortium-content-text-preview{
    position: relative;
    width: 50%;
    height: 100%;

}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .edit-consortium-content-text .edit-consortium-content-text-preview .content-preview{
    position: relative;
    display: block;
    box-shadow: 0 0 20px #00000048;
    width: 100%;
    height: 100%;
    word-break: break-all;
    border-right: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 10vmax;

    padding: 0.2vmax;
    padding-left: 0.5vmax;


}




.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-main .main-row .input-calendar .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}




.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    margin-right: 5%;

}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .title{
    position: relative;
    margin-right: 10%;
    color:black;
    font-size: 0.9vmax;;

}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .title span{
    display: block;
}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .options{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    align-items: center;

}


.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .options .opt-info{
    position: relative;
    width: 5%;
    height: 1.2vmax;
    min-width: 1.2vmax;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;

    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);


}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .options div{
    margin-right:10%;
}



.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .options .opt-info .icon{
    background-image: url("../../../../assets/figma/info.svg");
    background-size: 1.5vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .container-edit-consortium-content .container-toolbar .options .opt-info:hover .icon{
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
}






.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-edit-consortium .content .body .container-stepper-body .container-form-edit-consortium .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


