.wrapper-adminAuth{
    position: relative;
    width: 100%;
    padding: 1vmax;
    min-height: 100vh;

}

.wrapper-adminAuth::before{
    position: absolute;
    top:0;
    left:0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("../../assets/homepage/work3.jpg");
  
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
  }
  

.wrapper-adminAuth .container-login{
    position: relative;
    width: 55%;
    margin-left: 22.5%;
    padding: 1vmax;
    border:3px solid rgba(0, 0, 0, 0.24);
    background-color: rgba(255, 255, 255, 0.85);
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0px 2px 2px rgba(0, 0, 0, 0.685) inset, 0 0px 5px 1px rgba(0, 0, 0, 0.411) ;
    box-shadow:0 0px 2px 2px rgba(0, 0, 0, 0.685) inset, 0 0px 5px 1px rgba(0, 0, 0, 0.411) ;
    margin-top: 7vmax;

}


.wrapper-adminAuth .container-login .login-header{
    position: relative;
    width: 100%;
    padding: 0.5vmax;
    font-size: 1.2vmax;
    text-transform: uppercase;
    text-align: center;

}



.wrapper-adminAuth .container-login .login-form{
    position: relative;
    width: 100%;
    padding: 1vmax 0 2vmax 0;

}


.wrapper-adminAuth .container-login .login-form .main-row{
    position: relative;
    width: 100%;
    text-align: left;
    margin: 0 auto;

}

.wrapper-adminAuth .container-login .login-form .main-row .input{
    position: relative;
    margin: 0 auto;

}

.wrapper-adminAuth .container-login .login-form .main-row .input .label{
    display: block;
    text-align: left;
    padding-left:7.5%;
    margin-bottom: 0.5vmax;
    font-size: 0.95vmax;

}

.wrapper-adminAuth .container-login .login-form .main-row .input .field{
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0.85vmax;

}
.wrapper-adminAuth .container-login .login-form .main-row .input .field-flex{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.85vmax;


}

.wrapper-adminAuth .container-login .login-form .main-row .input .field-flex .input-text{
    position: relative;
    width: 85%;
    border: none;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    height: 2vmax;
    padding-left:0.5vmax;
    padding-right:12%;

}

.wrapper-adminAuth .container-login .login-form .main-row .input .field-flex .input-text:focus,
.wrapper-adminAuth .container-login .login-form .main-row .input .field .input-text:focus{
    outline: rgba(0, 0, 0, 0.473) solid 0.2px;
}

.wrapper-adminAuth .container-login .login-form .main-row .input .field-flex .input-icon{
    position: absolute;
    border: none;
    cursor: pointer;
    background-image: var(--ayy-visibility);
    background-size: 1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 10%;
    height: 2vmax;
    border: 0.5px solid rgba(0, 0, 0, 0.432);
    top:0;
    left:80%;
    border:none;

}

.wrapper-adminAuth .container-login .login-form .main-row .input .field .input-text{
    position: relative;
    width: 85%;
    padding-left:0.5vmax;
    border: 0.5px solid rgba(0, 0, 0, 0.24);
    height: 2vmax;

}

.wrapper-adminAuth .container-login .login-form .submit{
    position: relative;
    width: 85%;
    margin: 0 auto;
    margin-top:2vmax;
    text-align: right;
}

.wrapper-adminAuth .container-login .login-form .submit .submit-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
    font-size: 0.8vmax;
} 

.wrapper-adminAuth .container-login .login-form .submit .submit-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
    background-color: rgba(186, 201, 179, 0.123);
}



.wrapper-adminAuth .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;
    margin: 0.3vmax;

}

.wrapper-adminAuth .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;
    margin: 0.3vmax;

}

.wrapper-adminAuth .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.1vmax;
    width: 85%;
    margin: 0 auto;
}


.wrapper-adminAuth .container-loggedin{
    position: relative;
    width: 50%;
    margin: 0 auto;
    min-height: 10vmax;
    margin-top: 7vmax;
    border:3px solid rgba(0, 0, 0, 0.24);
    background-color: rgba(255, 255, 255, 0.575);
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0px 2px 2px rgba(0, 0, 0, 0.685) inset, 0 0px 5px 1px rgba(0, 0, 0, 0.411) ;
    box-shadow:0 0px 2px 2px rgba(0, 0, 0, 0.685) inset, 0 0px 5px 1px rgba(0, 0, 0, 0.411) ;
}

.wrapper-adminAuth .container-loggedin .message{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 80%;
    text-align: center;
    font-size: 1.5vmax;
    font-family:Montserrat,Roboto, 'Segoe UI', sans-serif

}

.wrapper-adminAuth .container-loggedin .message span:nth-child(2){
    font-weight: bold;;
}