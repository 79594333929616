.wrapper-add-mine-bibliography {
    position: relative;
    width: 100%;
    
}



.wrapper-add-mine-bibliography .container-bibliography-form{
    position: relative;
    width: 100%;
    height: 100%;

}

.wrapper-add-mine-bibliography .container-bibliography-form .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-add-mine-bibliography .container-bibliography-form .container-title span{
    display: block;
    padding-left: 1vmax;
}



.wrapper-add-mine-bibliography .container-bibliography-form .main-row{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-normal{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
    
}

.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-medium{
    position: relative;
    width: 55%;
    height: 100%;
    margin: 0.5vmax 0;
    
}



.wrapper-add-mine-bibliography .container-bibliography-form .main-row-split,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-triple,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-quadra{
position: relative;
width: 90%;
height: 100%;
min-height: 3.5vmax;;

display: -ms-flexbox;
display: -webkit-flex;
display: flex;

-ms-flex-direction: row;
-webkit-flex-direction: row;
flex-direction: row;

-ms-flex-wrap: nowrap;
-webkit-flex-wrap: nowrap;
flex-wrap: nowrap;

justify-content: space-between;
}


.wrapper-add-mine-bibliography .container-bibliography-form .main-row-split .input-normal{
position: relative;
width: calc(96%/2);
height: 100%;
}

.wrapper-add-mine-bibliography .container-bibliography-form .main-row-triple .input-normal{
position: relative;
width: calc(96%/3);
height: 100%;
}

.wrapper-add-mine-bibliography .container-bibliography-form .main-row-quadra .input-normal{
    position: relative;
    width: calc(96%/4);
    height: 100%;
}


.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-normal .input-normal-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-medium .input-normal-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-split .input-normal .input-normal-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-triple .input-normal .input-normal-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-quadra .input-normal .input-normal-text{
position: relative;
width: 100%;
outline: none;
user-select: none;
margin-top: 0.5vmax;
padding-left:0.5vmax;
height: 1.8vmax;
font-size: 0.9vmax;;

}

.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-normal .input-area-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-medium .input-area-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-split .input-normal .input-area-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-triple .input-normal .input-area-text,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-quadra .input-normal .input-area-text{
position: relative;
width: 100%;
outline: none;
user-select: none;
margin-top: 0.5vmax;
padding-left:0.5vmax;
height: 3vmax;
font-size: 0.9vmax;;

}


.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-normal .label,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row .input-medium .label,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-split .input-normal .label,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-triple .input-normal .label,
.wrapper-add-mine-bibliography .container-bibliography-form .main-row-quadra .input-normal .label{

display: block;
width: 100%;
color:black;
font-size: 0.9vmax;;
}

select::after {
position: absolute;

content: "";
top:0;
left:0;
width: 0.2vmax;
height:0.2vmax;
background-color: red;
z-index: 100;

}

.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}

.wrapper-add-mine-bibliography .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-mine-bibliography .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-mine-bibliography .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}
