.wrapper-edit-mine-mineProductions {
    position: relative;
    width: 100%;
    
}

.wrapper-edit-mine-mineProductions .edit-mine-mineProductions-menu{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: center;
    align-items: center;
    margin-bottom: 1vmax;
}


.wrapper-edit-mine-mineProductions .edit-mine-mineProductions-menu .edit-new-mineProduction{
    position: relative;
    width: 30%;
    outline: none;
    border: none;
    padding: 0.5vmax;
    font-size: 1.2vmax;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(160, 158, 158, 0.507);;       

}

.wrapper-edit-mine-mineProductions .edit-mine-mineProductions-menu .edit-new-mineProduction:hover{
    background-color: rgba(163, 165, 43, 0.192);

}

.wrapper-edit-mine-mineProductions .container-mineProduction {
    position: relative;
    width: 100%;
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    font-size: 1vmax;
    padding: 0.5vmax;
    /*font-family: Montserrat, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;*/
    
    box-shadow: 0px 1px 1px 1px rgba(160, 158, 158, 0.507);;       
    margin-bottom: 1vmax;;
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-text{
    position: relative;
    width: 50%;
    cursor: pointer;
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-text:hover{
    font-size: 1.005vmax;

}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-options{
    position: relative;
    width: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-options .remove-mineProduction{
    position: relative;
    width: 10%;
    height: 1.7vmax;
    min-width: 1.7vmax;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;

    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);
    z-index:1000;

}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-options .remove-mineProduction{
    position: relative;
    width: 5%;
    height: 1.7vmax;
    min-width: 1.7vmax;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;

    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);

}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-options .remove-mineProduction .icon{
    background-image: url("../../../../../assets/figma/delete.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}


.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-icon .mineProduction-icon-options .remove-mineProduction:hover .icon{

    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
}



.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form{
    position: relative;
    width: 100%;
    height: 100%;

}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .container-title span{
    display: block;
    padding-left: 1vmax;
}



.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-normal{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
    
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-medium{
    position: relative;
    width: 55%;
    height: 100%;
    margin: 0.5vmax 0;
    
}



.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-split,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-triple,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-quadra{
position: relative;
width: 90%;
height: 100%;
min-height: 3.5vmax;;

display: -ms-flexbox;
display: -webkit-flex;
display: flex;

-ms-flex-direction: row;
-webkit-flex-direction: row;
flex-direction: row;

-ms-flex-wrap: nowrap;
-webkit-flex-wrap: nowrap;
flex-wrap: nowrap;

justify-content: space-between;
}


.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-split .input-normal{
position: relative;
width: calc(96%/2);
height: 100%;
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-triple .input-normal{
position: relative;
width: calc(96%/3);
height: 100%;
}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-quadra .input-normal{
    position: relative;
    width: calc(96%/4);
    height: 100%;
}


.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-normal .input-normal-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-medium .input-normal-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-split .input-normal .input-normal-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-triple .input-normal .input-normal-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-quadra .input-normal .input-normal-text{
position: relative;
width: 100%;
outline: none;
user-select: none;
margin-top: 0.5vmax;
padding-left:0.5vmax;
height: 1.8vmax;
font-size: 0.9vmax;;

}

.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-normal .input-area-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-medium .input-area-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-split .input-normal .input-area-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-triple .input-normal .input-area-text,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-quadra .input-normal .input-area-text{
position: relative;
width: 100%;
outline: none;
user-select: none;
margin-top: 0.5vmax;
padding-left:0.5vmax;
height: 3vmax;
font-size: 0.9vmax;;

}


.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-normal .label,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row .input-medium .label,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-split .input-normal .label,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-triple .input-normal .label,
.wrapper-edit-mine-mineProductions .container-mineProduction .container-mineProduction-form .main-row-quadra .input-normal .label{

display: block;
width: 100%;
color:black;
font-size: 0.9vmax;;
}

select::after {
position: absolute;

content: "";
top:0;
left:0;
width: 0.2vmax;
height:0.2vmax;
background-color: red;
z-index: 100;

}

.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}

.wrapper-edit-mine-mineProductions .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-edit-mine-mineProductions .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-edit-mine-mineProductions .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}
