.wrapper-link-graph{
    position: relative;
    width: 100%;
    min-height: 80vh;

}


.wrapper-link-graph .container-graph-display{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    background-color: rgba(221, 220, 220, 0.336);

}


.wrapper-link-graph .container-graph-display .main-graph{
    position: relative;
    width: 50%;
    height: 20vmax;
    margin: 5vmax 0 5vmax 10%;

}

@media only screen and (max-width: 1000px) {

    .wrapper-link-graph .container-stats-newsletter-flex {
        position: relative;
        display: block;

        width: 95%;
        margin: 0 auto;
    }

    .wrapper-link-graph .container-stats-newsletter-flex > * {
        width: 100%;
    }


    .wrapper-link-graph .container-stats-newsletter-flex .main-stats-newsletter{
        height: 35vmax;
        background-color: rgba(221, 220, 220, 0.336);
        width: 100%;
    
    }
    
    .wrapper-link-graph .container-stats-newsletter-flex .main-stats-newsletter .main-graph{
        width: 85%;
        height: 100%;
        padding: 2vmax 0 1vmax 0;
    }



}