.wrapper-add-booklet{
    position: relative;
    width: 100%;
}


.wrapper-add-booklet .container-add-booklet-main{
    position: relative;
    width: 100%;
    height: 100%;
}


.wrapper-add-booklet .container-add-booklet-main .container-title,
.wrapper-add-booklet .container-add-booklet-attach .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;
}


.wrapper-add-booklet .container-add-booklet-main .container-title span,
.wrapper-add-booklet .container-add-booklet-attach .container-title span{
    display: block;
    padding-left: 1vmax;
}


.wrapper-add-booklet .container-add-booklet-main .main-row{
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}


.wrapper-add-booklet .container-add-booklet-main .main-row .input-title{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-add-booklet .container-add-booklet-main .main-row .input-title .input-title-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;
    font-size: 0.9vmax;;
    height: 1.8vmax;
}

.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}




.wrapper-add-booklet .container-add-booklet-main .main-row .input-title .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}





.wrapper-add-booklet .container-add-booklet-article{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
}




.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach{
    position: relative;
    width: 90%;
    margin-top: 1vmax;
    min-height: 8vmax;
    height: 100%;
    border: 0.1px solid rgba(211, 211, 211, 0.5);
    user-select: none;
    padding: 1vmax;
}

.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .absolute-message{
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    color: grey;
    font-size: 2vmax;
    z-index: 9999;
}

.wrapper-add-booklet .container-add-booklet-attach .droppin {
    border: 0.1px solid rgba(69, 109, 219, 0.5);
}

.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object {
    position: relative;
    width: 100%;
    color: black;
    font-size: 1vmax;

    margin: 0.5vmax 0;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    box-shadow: 0 0 10px #00000048;

}


.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-main{
    width: 80%;
    margin-right: 5%;
    word-wrap: break-word;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;


}

.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-main:hover {
    text-decoration: underline;
}





.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-menu {
    position: relative;
    width: 15%;
    height: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;    

    justify-content: flex-end;
    padding: 0.4vmax;

}


.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-menu .booklet-attach-object-menu-option {
    position: relative;
    width: 40%;
    height: 100%;
    text-align: center;

}

.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-menu .booklet-attach-object-menu-option .kxicon{
    cursor: pointer;
    background-image: url("../../../../assets/figma/delete.svg");
    position: relative;
    background-size: 1.8vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}

.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-menu .booklet-attach-object-menu-option .kxicon:hover {
    background-size: 2vmax;
}



.wrapper-add-booklet .container-add-booklet-attach{
    position: relative;
    width: 100%;
    height: 100%;
}

.wrapper-add-booklet .container-add-booklet-attach .add-booklet-attach .booklet-attach-object .booklet-attach-object-main .attach-name{
    width: 100%;
    padding: 0.5vmax;

}































.wrapper-add-booklet .container-form-bottom{
    position: relative;
    width: 100%;
    height: 100%;

    margin-top: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

}
.wrapper-add-booklet .container-form-bottom .submit{
    position: relative;
    width: 50%;
    border: none;

}

.wrapper-add-booklet .container-form-bottom .submit .submit-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    -moz-box-shadow:0 0 2px  rgba(0, 0, 0, 0.411);
    box-shadow:0 0 2px rgba(0, 0, 0, 0.411);
    background-color: transparent;
    padding: 0.3vmax;
}

.wrapper-add-booklet .container-form-bottom .submit .submit-btn .info{
    display: block;
    height: 100%;
    width: 100%;
    font-size: 0.9vmax;
}



.wrapper-add-booklet .container-form-bottom .submit .submit-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}

.wrapper-add-booklet .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-add-booklet .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-add-booklet .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


