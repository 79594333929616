.wallpaper-wrapper{
    position: relative;
    width: 100%;
    overflow: hidden;

}

.wallpaper-wrapper .slide{
    position: relative;
    width: 100%;
    height:95vh;
    min-height:95vmin;

    background-size: cover;
    background-repeat: no-repeat;
}

.wallpaper-wrapper .slide{
    position: relative;
    height:95vh;
    min-height:95vmin;

    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    background-image: url("../../../assets/homepage/wallpaper.jpg");
    /* Set a specific height */
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.wallpaper-wrapper .slide .info{
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    justify-content: center;
}


.wallpaper-wrapper .slide .active {
    animation: fadeinout 8s linear forwards;
    -webkit-animation: fadeinout 8s linear forwards;
    -moz-animation: fadeinout 8s linear forwards;
    -o-animation: fadeinout 8s linear forwards;
    -ms-animation: fadeinout 8s linear forwards;
}


@keyframes fadeinout {
    0%,100% { opacity: 0; }
    10%,90% { opacity: 1; }
}
    
@-moz-keyframes fadeinout {
    0%,100% { opacity: 0; }
    10%,90% { opacity: 1; }

}
    
@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0; }
    10%,90% { opacity: 1; }

}
    
@-o-keyframes fadeinout {
    0%,100% { opacity: 0; }
    10%,90% { opacity: 1; }

}
    
@-ms-keyframes fadeinout {
    0%,100% { opacity: 0; }
    10%,90% { opacity: 1; }

}


.wallpaper-wrapper .slide .info div{
    position: relative;
    width: 100%;

}

.wallpaper-wrapper .slide .info div span{
    display: block;
    color:rgba(255, 255, 255, 0.685);

}

.wallpaper-wrapper .slide .info .text1{
    font-size: 3.6vmax;
    /*font-family: 'Roboto', sans-serif;*/
}

.wallpaper-wrapper .slide .info .text2{
    font-size: 1.6vmax;
    padding-top: 4vh;
    /*font-family: "Montserrat",'Roboto', sans-serif;*/
    font-weight: 300;


}



.wallpaper-wrapper .container-clouds{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 300vw;
    height: 80vh;
    /*animation: cloudLoop 80s linear infinite;*/
    animation-duration: 80s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: cloudLoop;
}

@keyframes cloudLoop{
    0% {
        transform: translateZ(0);
    }
    100% {
        transform: translate3d(-50%,0,0);
    }
}


.wallpaper-wrapper .slide-text{
    color:rgba(255, 255, 255, 0.685);
    height: 100%;
    text-align: center;
}

.wallpaper-wrapper .slide-text > div{
    position: relative;
    top:30%;

}

.wallpaper-wrapper .slide-text .text1{
    font-size: 4vmax;
    display: block;

}
.wallpaper-wrapper .slide-text .text2{
    font-size: 1.5vmax;
    padding-top:3vh;
    display: block;

}

.wallpaper-wrapper .container-arrow{
    position: absolute;
    text-align: center;
    bottom: 0;
    left:47.5%;
    z-index: 2000;
}

.wallpaper-wrapper .container-arrow .arrow-img{
    width:5vw;
    height:10vh;
    cursor: pointer;
    filter: brightness(0.8);
    -webkit-filter: brightness(0.8);
}
.wallpaper-wrapper .container-arrow .arrow-img:hover{
    filter: brightness(200%);
    -webkit-filter: brightness(200%);
    transform: scale(1.03);
}

.carousel-indicators{
    top:85% !important;
}


/*


.wallpaper-wrapper .menu{
    position: relative;
    width: 80%;
    margin: 2vmax auto 2vmax auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-around;
}



.wallpaper-wrapper .menu .container-perk-object{
    position: relative;
    width: 30.5%;
    height: 50vmin;
    background-color: rgba(255, 255, 255, 0.699);

    padding: 1.5vmax;


    -webkit-box-shadow:rgba(0, 0, 0, 0.555) 2px 2px 10px 1px, inset rgba(255, 255, 255, 0.575) 0px -10px 10px 5px;
    -moz-box-shadow:rgba(0, 0, 0, 0.555) 2px 2px 10px 1px, inset rgba(255, 255, 255, 0.575) 0px -10px 10px 5px;
    -o-box-shadow:rgba(0, 0, 0, 0.555) 2px 2px 10px 1px, inset rgba(255, 255, 255, 0.575) 0px -10px 10px 5px;
    box-shadow:rgba(0, 0, 0, 0.555) 2px 2px 10px 1px, inset rgba(255, 255, 255, 0.575) 0px -10px 10px 5px;
    text-align: center;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.7s;
    animation: middlePerk 1.5s;

}

.wallpaper-wrapper .menu .container-perk-object:first-child{
    animation: firstPerk 1.5s;
}
.wallpaper-wrapper .menu .container-perk-object:last-child{
    animation: lastPerk 1.5s;
}
@keyframes firstPerk{
    from{
        transform: scale(0.5) translate(-100px);
        opacity: 0;
    }
    to{
        transform: scale(1) translate(0px);
        opacity: 1
    }
}
@keyframes middlePerk{
    from{
        transform: scale(0.5);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1
    }
}
@keyframes lastPerk{
    from{
        transform: scale(0.5) translate(100px);
        opacity: 0;
    }
    to{
        transform: scale(1) translate(0px);
        opacity: 1
    }
}


.wallpaper-wrapper .menu .container-perk-object::before{
    position: absolute;
    top:0;
    left:0;
    content:'';
    height: 100%;
    width: 100%;
    background-image: url("../../../assets/homepage/wallpaper.jpg");

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.15;

}

.wallpaper-wrapper .menu .container-perk-object:hover{
    transform: scale(1.05);
}



.wallpaper-wrapper .menu .container-perk-object .perk-object-image {
    position: relative;
    width: 40%;
    height: 40%;
    padding: 0.5vmax;
    margin: 0 auto;
}

.wallpaper-wrapper .menu .container-perk-object .perk-object-image img {
    width: 100%;
    height: 100%;
}

.wallpaper-wrapper .menu .container-perk-object .perk-object-text{
    position: relative;
    height: 60%;

    margin-top:3vmax;;



}

.wallpaper-wrapper .menu .container-perk-object .perk-object-text > * {
    display: block;
    font-family: "Montserrat";

}

.wallpaper-wrapper .menu .container-perk-object .perk-object-text .perk-object-text-title{
    font-size: 4vmin;
}
.wallpaper-wrapper .menu .container-perk-object .perk-object-text .perk-object-text-desc{
    font-size: 2vmin;
}
*/