.wrapper-filter-news{
    position: relative;
    width: 85%;
    margin: 0 auto;

    
}

.wrapper-filter-news .container-filter-news-header{
    position: relative;
    width:100%;
    
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-title{
    width: 35%;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    margin-bottom:0.5vmax;
    margin-left: -5%;;


}

.wrapper-filter-news .container-filter-news-header .filter-news-header-title span{
    display: block;
    font-size: 1.4vmax;
    padding-left: 0.4vmax;
    text-transform: uppercase;
    background-color: #efe9cc;
    padding-top: 0.1vmax;
    padding-bottom: 0.1vmax;

}

.wrapper-filter-news .container-filter-news-header .filter-news-header-separator{
    padding: 0.05vmax 0;
    margin-left: -5%;;
    background-color: rgb(255, 255, 255);
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    width: 105%;
    margin-left: -5%;
    user-select: none;
    margin-top:0.5vmax;
    /*background-color: #efe9cc46;*/

}
.wrapper-filter-news .container-filter-news-header .filter-news-header-menu > * {
    height:3.3vmax;

}



.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options{
    width: 30%;
}
.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

}


.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .filter-news-header-options-opt {
    width: calc(90%/2); /*98*/
    margin: 0 calc(10%/4) 0 calc(10%/4);
    padding: 0.5vmax 0;
    

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;

    text-align: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    align-items: center; 
    max-height: 2.5vmax;
    box-shadow: 0 3px 3px #00000048;
    background-color: rgb(255, 255, 255);

}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .filter-news-header-options-opt:first-child{
    margin-left: 0;

}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .filter-news-header-options-opt .kekax-span    
{
    width: 60%;;
    text-align: right;

}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .filter-news-header-options-opt .kekax-icon    
{
    width: 40%;
    text-align: left;
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .filter-news-header-options-opt .kekax-icon > * 
{ 
    font-size: 1.5vmax;


}


.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .options-opt-all:hover{
    box-shadow: 2px 2px 1px 1px #29191994, -0.1px -0.1px 1.5px 2.5px #00000094 inset;;
}


.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .opt-active{
    box-shadow: 2px 2px 1px 1px #29191994, -0.1px -0.1px 1.5px 2.5px #00000094 inset;;

}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-options .filter-news-header-options-flex .filter-news-header-options-opt span{
    display: block;
    font-size: 0.9vmax;
    text-transform: uppercase;
    width: 100%;
}


.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search{
    position: relative;
    width: 70%;


}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex{
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    cursor: pointer;
    height: 2.5vmax;

    font-family: "Montserrat";
    align-items: center;
    background-color: rgba(255, 255, 255, 0.822);

}



.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex > *{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-text{
    width: 80%;
    font-size: 1vmax;
    
}
.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-text .kekax-real-input{
    width: 100%;
    height: 2.5vmax;
    padding: 0 2.5% 0 2.5%;
    border:none;
    background-color: transparent;
}
.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-text .kekax-real-input:hover{
    /*cursor: pointer;*/
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-text .kekax-real-input:focus{
    cursor: auto;
    outline: none;
}
.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex:focus{
    border: 1px solid rgba(175, 175, 175, 0.521);
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-split{
    width: 0.50%;


    background-color: rgba(175, 175, 175, 0.521);
    height: 2vmax;

}


.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-search{
    position: relative;
    width: 9.50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-search .search-icon{
    position: relative;
    font-size: 1.2vmax;;

}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-search:hover{
    transform: scale(1.2);
}


.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-details{
    position: relative;
    width: 9.50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-details:hover{
    transform: scale(1.25);
}
.wrapper-filter-news .container-filter-news-header .filter-news-header-menu .filter-news-header-search .filter-news-header-search-flex .kekax-input-details .expand-icon{
    font-size: 1.5vmax;;

}


.wrapper-filter-news .search-news-details{
    position: absolute;
    left:0;
    top:0;
    cursor: pointer;
    font-size: 0.9vmax;
    display: none;
    -webkit-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -moz-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -o-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    background-color: white;

}

.wrapper-filter-news .search-news-details-open{
    display: block;
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper{
    width: 40vmax;
    /*background-color: transparent;*/
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header{
    position: relative;
    height: 2vmax;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header > *{
    height: 100%;
    width: calc(100%/2);
    
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header .search-news-header-opt{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    border: 0.5px solid rgba(143, 142, 142, 0.308);
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header .search-news-header-opt:hover{
    box-shadow: 0 3px 3px #00000048;
    cursor: pointer;
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header .search-news-header-opt .opt-icon{
    width: 10%;
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header .search-news-header-opt .opt-icon >*{
    height: 0.7vmax;
    width: 0.7vmax;

}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-header .search-news-header-opt .opt-label{
    width: 20%;

    font-size: 0.9vmax;
}


.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-body{
    position: relative;    
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-body .object-search-news{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    /*justify-content: center;*/
    align-items: center; 
    height: 2vmax;
    width: 100%;
}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-body .object-search-news > span{
    margin-left: 5%;

}

.wrapper-filter-news .search-news-details .search-news-details-wrapper .search-news-body .object-search-news:hover{
    border: 0.5px solid rgba(103, 104, 103, 0.555);
    box-shadow: 0 0px 3px rgba(103, 104, 103, 0.555);
}


/*DROPDOWN*/


.wrapper-filter-news .filter-news-drop{
    position: absolute;
    left:0;
    top:0;
    margin-top:-0.9vmax;
    cursor: pointer;
    font-size: 0.9vmax;
    display: none;
    -webkit-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -moz-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    -o-box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    box-shadow:rgba(0,0,0,0.8) 0px 0px 2px, inset rgba(0,0,0,0.1) 0px -2px 2px;
    background-color: white;

}

.wrapper-filter-news .filter-news-drop-open{
    display: block;
}

.wrapper-filter-news .filter-news-drop .drop-filter-wrapper{
    width: 10vmax;
    background-color: transparent;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.wrapper-filter-news .filter-news-drop .drop-filter-wrapper .drop-option{
    height: 2.5vmax;
    width: 100%;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    align-items: center; 
    text-transform: uppercase;

    font-size: 0.9vmax;

}

.wrapper-filter-news .filter-news-drop .drop-filter-wrapper .opt-active{
    background-color: #efe9cc93;
}


.wrapper-filter-news .filter-news-drop .drop-filter-wrapper .drop-option:hover{
    background-color: #efe9ccc0;
}

.wrapper-filter-news .filter-news-drop .drop-filter-wrapper .drop-option span{
    padding-left: 10%;
}




.wrapper-filter-news .container-filter-news{
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 3vmax;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

}

.wrapper-filter-news .container-filter-news .object-filter-news{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    margin-bottom: 3vh;
    /*box-shadow: 0 0 3px 1px #c3692d5b;*/

    border-left: 5px solid #c3692dbd;

}

.wrapper-filter-news .container-filter-news .object-filter-news:hover .object-filter-news-media .object-filter-news-img{
    transform: scale(1.02);

}
.wrapper-filter-news .container-filter-news .object-filter-news:hover{
    transform: scale(1.005);

}



.wrapper-filter-news .container-filter-news .object-filter-news > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    cursor: pointer;

}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-media{
    position: relative;
    width: 20%;
    margin-right: 1%;
    height: 8.5vmax;
    overflow: hidden;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.288);;     
    background-color: white;  
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: center;
    align-content: center;

}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-media .object-filter-news-img{
    position: relative;
    display: block;
    margin: auto;
    height: auto;
    max-height: 100%;
    zoom:2;
    width: auto;
    max-width: 100%;
}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    width: 79%;

    background-color: rgba(243, 243, 237, 0.747);
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.288);;       
}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content > * {
    color: black;
    margin-left:1%;

}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-title{
    font-size: 1.4vmax;
}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-sub-title{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-sub-title > *{
    line-height: 0.8;
}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-sub-title .object-filter-news-date{
    font-size: 0.7vmax;
    color: rgb(149, 149, 149);
}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-sub-title .object-filter-news-dot{
    font-size: 0.7vmax;
    margin: 0 0.2vmax;
}
.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-sub-title .object-filter-news-type{
    font-size: 0.7vmax;
    color: #c36a2d;

}

.wrapper-filter-news .container-filter-news .object-filter-news .object-filter-news-content .object-filter-news-desc{
    font-size: 0.8vmax;
    margin-top: 1.5vmax;

}

.wrapper-filter-news .container-filter-news-footer{
    position: relative;
    width:100%;
    margin-top: 1vmax;
    min-height: 4vmax;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;


    background-color: rgba(218, 219, 194, 0.747);
}

.wrapper-filter-news .container-filter-news-footer .filter-news-footer-info{
    width: 60%;
    height: 100%;
}

.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options {
    width: 40%;
    height: 100%;
    user-select: none;

}


.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options > * {
    font-size: 1.4vmax;
    cursor: pointer;
    display: inline-block;

}

.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options .go-left{
    margin-left: 45%;
    margin-right: 0.5%;

}

.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options .go-right{
    margin-left: 0.5%;

}

.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options .go-pages {
    margin: 1.5vmax 0.5% 0 0.5%;
    font-size: 1vmax;

}

.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options .go-pages:hover{
    text-decoration: underline;
    color: blue;
    
}


.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options .selected-go-pages{
    font-weight: bold;
    color: black;
    text-decoration: underline;

}
.wrapper-filter-news .container-filter-news-footer .filter-news-footer-options .selected-go-pages:hover{
    text-decoration: underline;
    color: black;

}
