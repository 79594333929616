.wrapper-page-backoffice{
    position: relative;
    width: 100%;
}

.wrapper-page-backoffice .container-page-backoffice-header{
    position: relative;
    width: 100%;
    background-color: rgba(193, 194, 181, 0.171);
    margin: 1vmax 0;
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wrapper-page-backoffice .container-page-backoffice-body{
    position: relative;
    width: 100%;

}

.wrapper-page-backoffice .container-page-backoffice-header .center-side{
    position: relative;
    width: 80%;
}

.wrapper-page-backoffice .container-page-backoffice-header .left-side{
    position: relative;
    width: 10%;

}





.wrapper-page-backoffice .container-page-backoffice-header .left-side .icon-left{
    position: absolute;
    right: 0%;
    top:0;
    width: 25%;
    height: 100%;
    background-image: url("../../assets/figma/arrow_left.svg");
    background-size: 1.8vmax;
    background-repeat: no-repeat;
    background-position:center;
    cursor: pointer;
    border:none;
    user-select: none;
    outline: none;
}




.wrapper-page-backoffice .container-page-backoffice-header .right-side{
    position: relative;
    width: 10%;

}

.wrapper-page-backoffice .container-page-backoffice-header .right-side .icon-right{
    position: absolute;
    left: 0%;
    top:0;
    width: 25%;
    height: 100%;
    background-image: url("../../assets/figma/arrow_right.svg");
    background-size: 1.8vmax;
    background-repeat: no-repeat;
    background-position:center;
    cursor: pointer;
    border:none;
    user-select: none;
    outline: none;


}

.wrapper-page-backoffice .container-page-backoffice-header .left-side .icon-left:hover,
.wrapper-page-backoffice .container-page-backoffice-header .right-side .icon-right:hover{
    background-size: 2vmax;

}
