:root{
    /*
    --kekax-game-quiz-bg:radial-gradient( circle, transparent 5%, rgba(0, 0, 0, 0.281) 150%), rgba(78, 103, 136, 0.65);
    --kekax-game-quiz-border: rgba(60, 75, 102, 0.7);
    */
    /*
    --kekax-game-quiz-bg:radial-gradient( circle, transparent 5%, rgba(10, 10, 10, 0.616) 200%), rgba(115, 119, 84, 0.829);
    --kekax-game-quiz-border: rgba(80, 83, 57, 0.877);
    
    --kekax-game-quiz-bg-hover:rgba(0, 0, 0, 0.281);
    --kekax-game-quiz-border-hover: rgba(0, 0, 0, 0.281);
    */
    --kekax-game-quiz-bg:radial-gradient( circle, transparent 120%, rgba(49, 49, 47, 0.37) 10%), rgba(49, 49, 47, 0.664);
    --kekax-game-quiz-border: rgba(49, 49, 47, 0.664);
    
    --kekax-game-quiz-bg-hover:rgba(49, 49, 47, 0.4);
    --kekax-game-quiz-border-hover: rgba(49, 49, 47, 0.4);


}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  } 

  .blinky{
    animation: blinker 1s linear infinite;
  }




.wrapper-quiz-question{
    position: relative;
    width: 100%;
    min-height: 100vh;
    user-select: none;
}


.wrapper-quiz-question .wrap-the-info {
    /*
    position: relative;
    width: 50%;
    margin: 0 auto;
    min-height: 25vmax;
    background-image: url("../../../../assets/goddess/beta3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35vmax;
    */

    position: relative;
    width: 100%;
}

.wrapper-quiz-question .wrap-the-quiz {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 10vmax;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu {
    position: relative;
    width:76%;
    margin: 0 auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    user-select: none;
    padding:0.5vmax 0.5vmax;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-menu .info{
    position: relative;
    width: 40%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: flex-end;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .info .board {
    position: relative;
    width: 25%;
    cursor: pointer;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .info .board .rounds{
    position: relative;
    width: 100%;    
    height: 3vmax;

    background-color: rgba(255, 255, 255, 0.575);
    font-size: 2vmax;

    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .info .board .rounds span{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .info .help{
    position: relative;
    margin-right:5%;;

}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .info .help .question{
    position: relative;
    height: 2.5vmax;
    width: 2.5vmax;
    user-select: none;
    outline: none;
    
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.521);

    background-image: url("../../../../assets/figma/game/help.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3vmin;

    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    background-color: rgba(255, 255, 255, 0.575);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .info .help:hover .question{
    background-size: 3.5vmin;;
}



.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-menu .options{
    position: relative;
    width: 60%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
 
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .fifty-option,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .call-option,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .freeze-option{
    position: relative;
    margin-right: 2.5%;;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-menu .options .fifty-option .fifty{
    position: relative;
    height: 2.5vmax;
    width: 2.5vmax;
    user-select: none;
    outline: none;

    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.521);

    background-image: url("../../../../assets/figma/game/fifty.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3vmin;

    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;

    background-color: rgba(0, 0, 0, 0.24);

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-menu .options .freeze-option .freeze{
    position: relative;
    height: 2.5vmax;
    width: 2.5vmax;
    user-select: none;
    outline: none;

    border: 2px solid rgba(0, 0, 0, 0.521);
    border-radius: 50%;

    background-image: url("../../../../assets/figma/game/clock.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3vmin;

    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;

    background-color: rgba(0, 0, 0, 0.24);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-menu .options .call-option .call{
    position: relative;
    height: 2.5vmax;
    width: 2.5vmax;
    user-select: none;
    outline: none;
    
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.521);

    background-image: url("../../../../assets/figma/game/call.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3vmin;


    -webkit-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    -o-box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.438) 0px 0px 10px 1px, inset rgba(0, 0, 0, 0.507) 0px 0px 0px 0px;

    background-color: rgba(0, 0, 0, 0.24);
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .active .fifty,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .active .freeze,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .active .call{
    background-color: rgba(255, 255, 255, 0.575);
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .active:hover .fifty,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .active:hover .freeze,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question  .quiz-menu .options .active:hover .call{
    background-size: 3.5vmin;;
}



.wrapper-quiz-question .wrap-the-quiz .container-quiz-question {
    position: relative;
    width: 100%;
    padding: 4.5vmax 0 2.5vmax 0;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question {
    position: relative;
    width:95%;
    margin: 0 auto;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .outside-black,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black

{
    position: relative;
    width: 80%;
    margin: 0 auto;
    min-height:  12vmax;
    /*clip-path: polygon(15% 30%, 84% 30%, 100% 50%, 85% 70%, 15% 70%, 0% 50%);*/
    background: var(--kekax-game-quiz-bg);
    
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black{
    min-height: 8vmax;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-wrong .outside-black{
    background: rgba(255, 0, 0, 0.637);
}
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-correct .outside-black{
    background: rgba(0, 128, 0, 0.637);
}








.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-anime:hover{
    transform: scale(1.01);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-anime:hover .outside-black
{
    background: var(--kekax-game-quiz-bg-hover);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-anime:hover .outside-black::after
{
    left:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid transparent;
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid var(--kekax-game-quiz-border-hover);

}
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-anime:hover .outside-black::before
{
    right:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid var(--kekax-game-quiz-border-hover);
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid transparent;
}





.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .outside-black::after,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black::after{
    position: absolute;
    left:-12vmax;
    content: '';
    width: 0;
    height: 100%;
    border-top: 6vmax solid transparent;
    border-left: 6vmax solid transparent;
    border-bottom: 6vmax solid transparent;
    border-right: 6vmax solid var(--kekax-game-quiz-border);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black::after{
    left:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid transparent;
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid var(--kekax-game-quiz-border);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-wrong .outside-black::after{
    left:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid transparent;
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid rgba(255, 0, 0, 0.637);
}
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-correct .outside-black::after{
    left:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid transparent;
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid rgba(0, 128, 0, 0.637)
}




.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .outside-black::before,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black::before{
    position: absolute;
    right:-12vmax;
    content: '';
    width: 0;
    height: 100%;
    border-top: 6vmax solid transparent;
    border-left: 6vmax solid var(--kekax-game-quiz-border);
    border-bottom: 6vmax solid transparent;
    border-right: 6vmax solid transparent;
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black::before{
    right:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid var(--kekax-game-quiz-border);
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid transparent;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-wrong .outside-black::before{
    right:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid rgba(255, 0, 0, 0.637);;
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid transparent;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-correct .outside-black::before{
    right:-8vmax;
    border-top: 4vmax solid transparent;
    border-left: 4vmax solid rgba(0, 128, 0, 0.637);
    border-bottom: 4vmax solid transparent;
    border-right: 4vmax solid transparent;
}





.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .outside-black .quiz-text,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text{
    position: absolute;
    width: 95%;
    height: 65%;
    background-color: transparent;
    left:50%;
    top:50%;
    transform: translate(-50%,-60%);

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    z-index: 1000;
    word-break:normal;


}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .outside-black .quiz-text{
    overflow:auto;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text{
    transform: translate(-50%,-50%);
    height: 85%;
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .outside-black .quiz-text span{
    display: block;
    color: rgba(255, 255, 255, 0.966);
    font-size:1.5vmax;
    line-height: normal;
    font-family:'Montserrat',sans-serif,monospace,"Courier New";
    text-shadow:0px 0px 10px rgba(0, 0, 0, 0.582);


}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .answer{
    position: relative;

    color: rgba(0, 0, 0, 0.692);
    text-transform: uppercase;
    font-weight: bold;
    width: 10%;
    font-size:1.6vmax;

    height: 100%;
    text-shadow:0px 0px 5px rgba(175, 175, 175, 0.322);

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .answer span{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .text{
    position: relative;
    height: 100%;
    width: 90%;
    font-size:1.08vmax;
    overflow:auto;


    color: rgba(255, 255, 255, 0.966);
    font-family:'Montserrat',sans-serif,monospace,"Courier New";
    /*
    justify-self: flex-start;
    align-self: flex-start;
    */
    text-shadow:0px 0px 10px rgba(0, 0, 0, 0.582);
    
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  

}

/*HACKS*/
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .text::before,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .text::after {
    content: '';
  }
  .wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .text::before {
    margin-top: auto;
  }
  .wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .text::after {
    margin-bottom: auto; 
  }
/*
  .wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .outside-black .quiz-text .text span{
    margin: 0 auto;
  }
*/


.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .inside-white,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .inside-white{
    position: absolute;
    width: 79.5%;
    margin: 0 auto;
    min-height: 11vmax;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .inside-white{
    min-height: 7vmax;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .inside-white .inside-white-left,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .inside-white .inside-white-left{
    position: absolute;
    width: 7.8vmax;
    min-height:7.7vmax;
    top:50%;
    left:0;
    transform: translate(-50%,-50%) rotate(45deg);
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .inside-white .inside-white-left{
    width: 5vmax;
    min-height: 5vmax;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .inside-white .inside-white-right,
.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .inside-white .inside-white-right{
    position: absolute;
    width: 7.8vmax;
    min-height:7.7vmax;
    top:50%;
    right:0%;
    transform: translate(50%,-50%) rotate(45deg);
    border-right: 2px solid white;
    border-top: 2px solid white;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer .inside-white .inside-white-right{
    width: 5vmax;
    min-height: 5vmax;
}


/*
.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .inside-white ::after{
    position: absolute;
    left:-20px;
    content: '';
    width: 0;
    height: 100%;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 2px solid white;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-question .inside-white::before{
    position: absolute;
    right:-20px;
    content: '';
    width: 0;
    height: 100%;
    border-top: 5px solid transparent;
    border-left: 2px solid white;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
}
*/

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers{
    position: relative;
    width: 100%;
    margin: 0 auto;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

  
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap:wrap;
    flex-wrap: wrap;

    justify-content: flex-start;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer
{
    position: relative;
    width: 40%; 
    cursor: pointer;
    margin: 1vmax 5%;
    
}


.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-hidden{
    visibility: hidden;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-answers .quiz-answer-bye
{
    animation: answer-bye-anime 2s;

}

@keyframes answer-bye-anime {
        from{
            transform: translate(0px,0px);
            opacity: 1;
        }
        to{
            transform: translate(50vw,0px);
            opacity: 0;
        }
    }
    
    
    

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer {
    position: absolute;
    width:5vmax;
    height: 5vmax;
    background-color: white;
    border-radius: 50%;
    bottom:0;
    left:50%;
    transform: translate(-50%,0%);
    background-color: rgba(255, 255, 255, 0.952);
    border: 2.5px solid var(--kekax-game-quiz-border);
    

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer .time{
    position: absolute;
    display: block;
    color: black;
    font-size:1.5vmax;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer .freeze{
    position: absolute;
    min-width:500%;
    top:-10%;
    left: -200%;
    border-radius: 7px;
    overflow: hidden;
    color: transparent;
    display: none;
    text-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.281),0px 0px 10px rgba(238, 238, 238, 0.486), 0px 0px 5px rgb(28, 99, 165);
    text-transform: uppercase;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer .freeze::before{
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    filter: blur(10px);

    -webkit-box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);
    -o-box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 500px rgba(192, 192, 192, 0.726);
    
    background: inherit;

}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer .active {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: center;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer .freeze .freeze-text{
    position: relative;
    min-width: 35%;
    font-size: 1.4vmax;
    font-weight: bold;
    text-align: center;
}

.wrapper-quiz-question .wrap-the-quiz .container-quiz-question .quiz-timer .freeze .freeze-time{
    position: relative;
    min-width: 15%;
    font-size: 1.4vmax;
    font-weight: bold;
    text-align: center;

}