:root {
    --kekax-tooltip-text-color: rgb(255, 255, 255);
    --kekax-tooltip-background-color: rgba(0, 0, 0, 0.466);
    --kekax-tooltip-margin: 30px;
    --kekax-tooltip-arrow-size: 6px;
}
  
  /* Wrapping */
.wrapper-kekax-tooltip {
    position: relative;

}
  
  /* Absolute positioning */
.tooltip-object {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--kekax-tooltip-text-color);
    background: var(--kekax-tooltip-background-color);
    font-size: 12px;
    font-family: sans-serif;
    line-height: 0.8;
    z-index: 5000;
    white-space: nowrap;
}
  
  /* CSS border triangles */
.tooltip-object::before {
    content: " ";
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--kekax-tooltip-arrow-size);
    margin-left: calc(var(--kekax-tooltip-arrow-size) * -1);
    
}
  
  /* Absolute positioning */
.tooltip-object.top {
    top: calc(var(--kekax-tooltip-margin) * -1.15);

}
  /* CSS border triangles */
.tooltip-object.top::before {
    top: 100%;
    border-top-color: var(--kekax-tooltip-background-color);
}
  
  /* Absolute positioning */
.tooltip-object.right {
    left: calc(100% + var(--kekax-tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
  /* CSS border triangles */
.tooltip-object.right::before {
    left: calc(var(--kekax-tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--kekax-tooltip-background-color);
}
  
  /* Absolute positioning */
.tooltip-object.bottom {
    bottom: calc(var(--kekax-tooltip-margin) * -1);
}
  /* CSS border triangles */
.tooltip-object.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--kekax-tooltip-background-color);
}
  
  /* Absolute positioning */
.tooltip-object.left {
    left: auto;
    right: calc(100% + var(--kekax-tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
  /* CSS border triangles */
.tooltip-object.left::before {
    left: auto;
    right: calc(var(--kekax-tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--kekax-tooltip-background-color);
}