.wrapper-godCall-modal{
    display: none;
    z-index: 5000;

}

.wrapper-godCall-modal .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    
    background-color: rgba(32, 32, 32, 0.774);
    z-index: 5001;
}

.godCall-modal-open{
    display: block;
}

.wrapper-godCall-modal.godCall-modal-open .overlay{
    
}


@keyframes godCall-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-godCall-modal .content{
    position: absolute;
    left:7.5%;
    /*top: calc( (-1*var(--navMain-real-height)) + var(--godCallModal-topOffset) + 5vh);*/
    top: calc(var(--godCallModal-topOffset) + 5vh);
    z-index: 5002;
    box-sizing: border-box;
    width: 85%;
    display: none;
    border-radius: 0.5%;

    max-height: 90vh;
    min-height: 90vh;

    
    overflow: auto;
    user-select: none;


}

.godCall-modal-open .content{
    word-wrap: break-word;
    animation: godCall-modal-fadein 0.2s ease-in-out;
    display: block;

}

.wrapper-godCall-modal .close{
    position: absolute;
    width: 2.7%;
    /*top: calc( (-1*var(--navMain-real-height)) + 2vh);*/
    top: calc(var(--godCallModal-topOffset) + 2vh);
    right: 2vmax;
    z-index: 5003;
    cursor: pointer;
}

.wrapper-godCall-modal .close .close-mark{
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    height: 2.5vmax;
    min-width: 2.5vmax;

    background-image: url("../../../../assets/figma/x.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2vmax;
}


.wrapper-godCall-modal .content .body{
    position: relative;
    width: 100%;
    text-align: center;
    font-size:0.9vmax;

    max-height: 90vh;
    min-height: 90vh;

    -webkit-box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);
    -o-box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 500px rgba(255, 255, 255, 0.2);

}


.wrapper-godCall-modal .content .body .god-chat{
    position: relative;
    width: 100%;
    min-height: 80vh;
    padding: 1vmax;

}

.wrapper-godCall-modal .content .body .god-chat .chat-god-msg,
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg{
    position: relative;
    width: 80%;
    display: none;

    min-height: 10vmax;
    padding: 1.5vmax;
    margin: 0.5vmax auto;
}

.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg,
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;


}



.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg .pic,
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg .pic{
    position: relative;
    width: 13%;
    height: 5.8vmax;
}

.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg .pic .photo,
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg .pic .photo{

    position: relative;
    min-width: 5.8vmax;
    width: 70%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.308);
    border-radius: 50%;
    margin: 0 auto;
}

.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg .pic .photo{
    background-image: url("../../../../assets/goddess/beta3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12vmax;
}

.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg .pic .photo{
    background-image: url("../../../../assets/images/profileclick33.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6vmax;
}


.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg .msg,
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg .msg{
    position: relative;
    width: 87%;
    font-size: 1.2vmax;
    font-family: 'Segoe UI', Roboto, sans-serif;
    color: white;
    padding: 0.5vmax;
}
.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg .msg{
    text-align: left;
    -webkit-box-shadow: 0 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    -moz-box-shadow: 0 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    -o-box-shadow: 0 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    box-shadow: 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    background-color: rgba(122, 107, 28, 0.158);

    border-radius: 5px;

}
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg .msg{
    text-align: right;
    -webkit-box-shadow: 0 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    -moz-box-shadow: 0 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    -o-box-shadow: 0 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    box-shadow: 0 0 1.5px 2px rgba(189, 187, 187, 0.658);
    background-color: rgba(151, 160, 167, 0.247);

    border-radius: 5px;
}

.wrapper-godCall-modal .content .body .god-chat .chat-god-msg .chat-msg .msg .solution,
.wrapper-godCall-modal .content .body .god-chat .chat-user-msg .chat-msg .msg .solution{
    font-size: 1.25vmax;
    text-transform: uppercase;
    font-weight: bold;

}






.wrapper-godCall-modal .content .body .return{
    position: relative;
    width: 80%;
    margin: 0 auto;
    min-height: 10vh;
    text-align: center;
    font-size:1.2vmax;
    color: rgba(210, 205, 205, 0.493);
    text-transform: uppercase;

    font-family: 'Segoe UI', Roboto, sans-serif;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    justify-content: center;
    align-items: center;
    background-color: rgba(230, 230, 230, 0.075);


}


.wrapper-godCall-modal .content .body .god-chat .chat-msg-active
{
    animation: msg-appear-anime 2s;
    display: block;

}

@keyframes msg-appear-anime {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    
   
    

.wrapper-godCall-modal .content .body .return .text{
    position: relative;
    padding: 0 2%;
}

.wrapper-godCall-modal .content .body .return .time{
    position: relative;
}


