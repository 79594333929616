.wrapper-edit-news{
    display: none;

}

.wrapper-edit-news .overlay{
    position: fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.582);
    z-index: 1200;
}

.editNews-modal-open{
    display: block;
}

.editNews-modal-open .overlay{
    
}


@keyframes editNews-modal-fadein {
    from { opacity: 0; display:none; transform: scale(0.7); }
    to { opacity: 1; display:block; transform: scale(1);}
}

.wrapper-edit-news .content{
    position: absolute;
    top: calc( (-1*var(--navMain-real-height)) + var(--editModalNews-topOffset));
    margin-top: 0;

    background-color: #fff;
    padding: 1vmax;
    box-sizing: border-box;
    width: 60vw;
    display: none;
    z-index: 1201;

}

.wrapper-edit-news.editNews-modal-open .content{
    word-wrap: break-word;
    animation: editNews-modal-fadein 0.2s ease-in-out;
    display: block;
    

}

.wrapper-edit-news .content .header{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;


}

.wrapper-edit-news .content .header .modal-title{
    position: relative;
    width: 80%;
    font-size: 1.5vmax;;
    word-break: break-all;
}

.wrapper-edit-news .content .header .close{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

}

.wrapper-edit-news .content .header .close .close-btn{
    position: relative;
    outline: none;
    user-select: none;
    border: none;
    /*
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    */
    background-color: transparent;


    position: relative;
    background-image: url("../../../../assets/figma/x.svg");
    background-size: 1.3vmax;
    background-repeat: no-repeat;
    background-position: center;
    height: 2vmax;
    width: 2vmax;

}

.wrapper-edit-news .content .header .close .close-btn:hover{
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}


.wrapper-edit-news .content .body{
    position: relative;
    width: 100%;
    overflow-y: auto;
    margin: 2vmax 0;
    text-align: center;
    font-size:1.1vmax;
    min-height: 60vh;
    height: 60vh;
}

.wrapper-edit-news .content .body .container-stepper{
    position: relative;
    width: 100%;
}

.wrapper-edit-news .content .body .container-stepper-body{
    position: relative;
    width: 100%;


}



.wrapper-edit-news .content .footer{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}



.wrapper-edit-news .content .footer .next{
    position: relative;
    width: 30%;
    margin-left: 1%;

}

.wrapper-edit-news .content .footer .submit{
    position: relative;
    width: 30%;
    margin-left: 1%;

}



.wrapper-edit-news .content .footer .previous{
    position: relative;
    width: 30%;
    margin-left: 5%;

}

.wrapper-edit-news .content .footer .cancel{
    position: relative;
    width: 20%;

}



.wrapper-edit-news .content .footer .next .next-btn,
.wrapper-edit-news .content .footer .submit .submit-btn,
.wrapper-edit-news .content .footer .cancel .cancel-btn,
.wrapper-edit-news .content .footer .previous .previous-btn{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    border: none;
    -webkit-box-shadow:0 0 1px rgb(0, 0, 0);
    -moz-box-shadow:0 0 1px rgb(0, 0, 0);
    box-shadow:0 0 1px rgb(0, 0, 0);
    background-color: transparent;
    padding: 0.3vmax;
    font-size: 0.9vmax;
    z-index: 1

}

.wrapper-edit-news .content .footer .next .next-btn:hover,
.wrapper-edit-news .content .footer .submit .submit-btn:hover,
.wrapper-edit-news .content .footer .cancel .cancel-btn:hover,
.wrapper-edit-news .content .footer .previous .previous-btn:hover{
    -webkit-box-shadow:0 0 2px rgb(0, 0, 0);
    -moz-box-shadow:0 0 2px rgb(0, 0, 0);
    box-shadow:0 0px 2px rgb(0, 0, 0);
}


/*FORM ---------------------------------------------------------------------------------------------------------------*/

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news{
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: left;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main{
    position: relative;
    width: 100%;
    height: 100%;


}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .container-title,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .container-title span,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .container-title span{
    display: block;
    width: 100%;
    padding-left: 1vmax;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title{
    position: relative;
    font-size: 2vmax;
    width: 100%;
    background-color: rgba(171, 194, 153, 0.418);
    margin-bottom: 1.5vmax;;;;;


    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    margin-right: 5%;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .title{
    position: relative;
    margin-right: 3%;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .title span{
    display: block;
    padding-left: 1vmax;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options{
    position: relative;
    width: 20%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    align-items: center;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-addDown,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-addUp,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-rmv,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-info{
    position: relative;
    width: 5%;
    height: 1.7vmax;
    min-width: 1.7vmax;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;

    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);


}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options div{
    margin-right:10%;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-addDown .icon{
    background-image: url("../../../../assets/figma/addDown.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-addUp .icon{
    background-image: url("../../../../assets/figma/addUp.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-rmv .icon{
    background-image: url("../../../../assets/figma/delete.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-info .icon{
    background-image: url("../../../../assets/figma/info.svg");
    background-size: 1.1vmax;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-addDown:hover .icon,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-addUp:hover .icon,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-rmv:hover .icon,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .container-title .options .opt-info:hover .icon{

    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
}









.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row{
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    min-height: 3.5vmax;;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar{
    position: relative;
    height: 100%;
    width: 30%;
    margin-right:10%;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar .input-calendar-datepicker{
    position: relative;
    width: 100%;
    height: 100%;
    margin:0 !important;
    line-height: 0 !important;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar .input-calendar-datepicker .MuiInputBase-root{
    font-size: 0.9vmax;
    line-height: 0;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar .input-calendar-datepicker .MuiSvgIcon-root{
    font-size: 1vmax;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar .input-calendar-datepicker>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>*>*>* {
    user-select: none !important;
    outline: none !important;
}
.MuiPickersBasePicker-pickerView >*>*>* {
    user-select: none !important;
    outline: none !important;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar .input-calendar-datepicker>*>*>*:hover {
    background-color: transparent;
    transform: scale(1.1);
}



.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-tag{
    position: relative;
    width: 30%;
    margin-right:10%;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-tag .input-tag-select{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;


}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-tag .input-tag-select >*{
    outline: none;
}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-importance{
    position: relative;
    width: 20%;
    height: 100%;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-importance .input-importance-number{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-title{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-title .input-title-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
    padding-left:0.5vmax;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-description{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5vmax 0;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-description .input-description-text{
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
    margin-top: 0.5vmax;
}

.kekax-input-box{
    display: block;
    border-radius: 0.1vmax;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
}



.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-importance .label,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-tag .label,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-calendar .label,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-title .label,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .main-row .input-description .label,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .label{
    display: block;
    width: 100%;
    color:black;
    font-size: 0.9vmax;;
}






.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image{
    position: relative;
    width: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
    margin-bottom: 2vmax;;;;;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview {
    position: relative;
    width: 32%;
    height: 16vmax;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image {
    width: 100%;
    height: 100%;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options{
    position: absolute;
    right:0;
    top: 0;
    height: 100%;
    padding:0.5vmax;
}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-change-fab,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-delete-fab {
    position: relative;
    display: block;
    border-radius: 50%;
    outline: none;
    user-select: none;
    border: none;
    padding: 0;
    -webkit-box-shadow:0 0 4px rgb(0, 0, 0);
    -moz-box-shadow:0 0 4px rgb(0, 0, 0);
    box-shadow:0 0 4px rgb(0, 0, 0);
    background-color: white;
    width: 2.2vmax;
    height: 2.2vmax;
  }

  .wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-delete-fab{
    margin-top: 0.5vmax;
  }

  .wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-hidden-fab{
    display: none;
}

  
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-change-fab .change {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/camera.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }

  .wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-delete-fab .delete {
    position: relative;
    border-radius: 50%;
    outline: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: 1.5vmax !important;
    background: url("../../../../assets/figma/delete.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(72deg) brightness(106%) contrast(98%);
  }
  
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-change-fab:hover .change,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-preview .image-options .image-delete-fab:hover .delete {
    filter: invert(41%) sepia(57%) saturate(7003%) hue-rotate(216deg) brightness(101%) contrast(91%);
  }
  

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-content {
    position: relative;
    width: 68%;
    text-align: left;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-content span {
    display: block;
    font-size: 1.5vmax;
    margin-left: 5%;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-content .text-one {
    font-size: 1.5vmax;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-main .edit-news-image .edit-news-image-content .text-two {
    font-size: 0.9vmax;
    padding-left: 1vmax;
}




.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2vmax;
}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-text,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-label{
    position: relative;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;

    width: 100%;
    height: 100%;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-text .edit-news-article-text-input,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-label .side-label{
    position: relative;
    width: 50%;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-label .side-label span{
    font-size: 1vmax;;
    background-color: rgb(0, 0, 0);
    color:white;
    display: block;
    width: 100%;
    padding-left: 1vmax;
    text-transform: uppercase;
}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-text .edit-news-article-text-input .article-text{
    position: relative;
    width: 100%;
    outline: none;
    border: none;
    border-left: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 100%;
    border: 0.5px solid rgb(107, 107, 107);
    padding: 0.2vmax;
    padding-left: 0.5vmax;
    

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-text .edit-news-article-text-preview{
    position: relative;
    width: 50%;
    height: 100%;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-text .edit-news-article-text-preview .article-preview{
    position: relative;
    display: block;
    box-shadow: 0 0 20px #00000048;
    width: 100%;
    height: 100%;
    word-break: break-all;
    border-right: 0.5px solid rgb(107, 107, 107);
    border-top: 0.5px solid rgb(107, 107, 107);
    border-bottom: 0.5px solid rgb(107, 107, 107);
    min-height: 10vmax;

    padding: 0.2vmax;
    padding-left: 0.5vmax;


}





.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach{
    position: relative;
    width: 100%;
    margin-top: 1vmax;
    min-height: 8vmax;
    height: 100%;
    border: 0.1px solid rgba(211, 211, 211, 0.5);
    user-select: none;
    padding: 1vmax;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .absolute-message,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .absolute-message{
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    color: grey;
    font-size: 2vmax;
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news  .container-edit-news-article .droppin,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .droppin {
    border: 0.1px solid rgba(69, 109, 219, 0.5);
}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object {
    position: relative;
    width: 100%;
    color: black;
    font-size: 1vmax;

    margin: 0.5vmax 0;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    box-shadow: 0 0 10px #00000048;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-main{
    width: 80%;
    margin-right: 5%;
    word-wrap: break-word;
    cursor: pointer;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;


}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main:hover,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-main:hover {
    text-decoration: underline;
}



.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-preview{
    position: relative;

    width: 18%;
    overflow: hidden;
    padding: 1vmax;
    
}
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-preview img{
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin:auto;

}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-text{
    width: 77%;
    margin-left: 5%;
    padding-top: 0.2vmax;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-text span{
    display: block;
    font-size: 0.9vmax;
    margin-bottom: 0.5vmax;
}



.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-main .article-gallery-object-main-text .text-legend{
    width: 100%;
    outline: none;
    height: 3.5vmax;
    font-size: 0.8vmax;
    padding: 0.4vmax;
}


.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-menu,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-menu {
    position: relative;
    width: 15%;
    height: 100%;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;    

    justify-content: flex-end;
    padding: 0.4vmax;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-menu .article-gallery-object-menu-option,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-menu .news-attach-object-menu-option {
    position: relative;
    width: 40%;
    height: 100%;
    text-align: center;

}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-menu .article-gallery-object-menu-option .kxicon,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-menu .news-attach-object-menu-option .kxicon{
    cursor: pointer;
    background-image: url("../../../../assets/figma/delete.svg");
    position: relative;
    background-size: 1.8vmax;
    background-repeat: no-repeat;
    background-position: center;
    height:2vmax;
    width:100%;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-article .edit-news-article-gallery .article-gallery-object .article-gallery-object-menu .article-gallery-object-menu-option .kxicon:hover,
.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-menu .news-attach-object-menu-option .kxicon:hover {
    background-size: 2vmax;
}



.wrapper-edit-news .container-edit-news-attach{
    position: relative;
    width: 100%;
    height: 100%;
}

.wrapper-edit-news .content .body .container-stepper-body .container-form-edit-news .container-edit-news-attach .edit-news-attach .news-attach-object .news-attach-object-main .attach-name{
    width: 100%;
    padding: 0.5vmax;
}


.wrapper-edit-news .feedback-block .input-invalid-feedback-kekax{
    display: block;
    color:red;

}

.wrapper-edit-news .feedback-block .input-valid-feedback-kekax{
    display: block;
    color:green;

}

.wrapper-edit-news .feedback-block{
    position: relative;
    line-height: normal;
    min-height: 1vmax;
    font-size: 0.6vmax;
    padding-left:0.3vmax;
}


